import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import { 
    Drawer, 
    DrawerOverlay, 
    DrawerContent, 
    DrawerHeader, 
    DrawerBody, 
    DrawerCloseButton, 
    useDisclosure 
} from "@chakra-ui/react";
import Styles from "../SideBarMenu/sideBarMenu.module.css";
import StylesNew from "./styles.module.css";

const OrganisationNavigation = ({ themeList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [menuList, setMenuList] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const location = useLocation();

    // Sorting function
    const customSort = (a, b) => {
        const numA = parseInt(a.name.match(/\d+/), 10) || 0;
        const numB = parseInt(b.name.match(/\d+/), 10) || 0;
        return numA - numB || a.name.localeCompare(b.name);
    };

    // Update menuList when themeList changes
    useEffect(() => {
        if (themeList) {
            const uniqueThemes = Array.from(new Set(themeList.map(t => t.name)))
                .map(name => themeList.find(t => t.name === name))
                .sort(customSort);
            setMenuList(uniqueThemes);
        }
    }, [themeList]);

    return (
        <div>
            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                {menuList.map((theme) => (
                    <li 
                        key={theme.name} 
                        className={`nav-item css-0 ${selectedTheme === theme.name ? Styles.active : ""}`} 
                        onClick={() => { setSelectedTheme(theme); onOpen(); }}
                    >
                        <span className="nav-link sideBarMenu_mnLink__gLPL-">
                            {theme.name}
                        </span>
                    </li>
                ))}
            </ul>

            {/* Drawer Component (Rendered Once) */}
            <Drawer isOpen={isOpen} placement="left" onClose={() => { setSelectedTheme(null); onClose(); }} size="lg" closeOnOverlayClick={false}>
                <DrawerOverlay />
                <DrawerContent left={'267px'}>
                    <DrawerCloseButton />
                    <DrawerHeader>{selectedTheme?.name}</DrawerHeader>
                    <DrawerBody>
                        {selectedTheme?.pillars?.map((pillar) => (
                            <Box key={pillar.name} ml={4} mt={2}>
                                <Text fontSize="md" fontWeight="bold" color="blue.500">
                                    
                                    <Link 
                                        to={`/pillar/${pillar.module}/${encodeURIComponent(pillar.name)}`}
                                        onClick={onClose}
                                    >
                                        {pillar.name}
                                    </Link>
                                </Text>
                                <Box ml={4} color="blue.500">
                                    {
                                        pillar && pillar.actions.map(action=>
                                            <Text>
                                                <Link
                                                    to={`/pillar/${pillar.module}/${encodeURIComponent(pillar.name)}/${action.module}/${encodeURIComponent(action.name)}`}
                                                    onClick={onClose}
                                                >
                                                    ➡️ {action.name}
                                                </Link>
                                            </Text>
                                        )
                                    }
                                </Box>
                            </Box>
                        ))}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default OrganisationNavigation;
