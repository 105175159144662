import React, { useState } from 'react';
import {
    Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader,
    DrawerBody, DrawerFooter, DrawerCloseButton, useDisclosure,
    Box,
    FormControl,
    FormLabel,
    Input
} from "@chakra-ui/react";
import { DownloadIcon, EditIcon } from "@chakra-ui/icons";
import ReportContent from './ReportContent/ReportContent';
import Download from './Download/Download';
import API from '../../../../Services/API';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const ViewReport = (props) => {
    const token = window.localStorage.getItem("accessToken") || "";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [reportTitle, setReportTitle] = useState('')
    const [title, setTitle] = useState(props.title)
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [metricLength, setMetricLength] = useState(0)
    const [impactLength, setImpactLength] = useState(0)

    const getReportName = (title) => {
        setReportTitle(title)
    }

    const saveField = ()=>{
        setIsLoading(true)
        setMessage(null)
        const formData = new FormData();
        formData.append("id", props.id);
        formData.append("title", title);
        API.put(`/custom-country-report`, formData, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response=>{
            props.getUpdated()
            setMessage('Updated report title successfully')
            setIsLoading(false)
            onClose()
        }).catch(error=>{
            setMessage('Failed Update report title')
            setIsLoading(false)
            onClose()
        })
    }

    const getMetricLength = (length) =>{
        setMetricLength(length)
    }

    const getImpactLength = (length) =>{
        setImpactLength(length)

    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            {
                props.mode === 'edit' ?
                <EditIcon cursor={'pointer'} onClick={onOpen} />
                :
                <Button
                    bg="gray.800"
                    color="white"
                    rightIcon={<DownloadIcon boxSize={4} />}
                    _hover={{ bg: "gray.700" }}
                    onClick={onOpen}
                    fontSize={'12px'}
                >
                    View / Download
                </Button>                

            }
            <Drawer isOpen={isOpen} onClose={onClose} size="xl" placement="right">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader> {props.mode === 'edit' ? "Edit Report" : reportTitle}</DrawerHeader>
                    <DrawerBody>
                        {
                            props.mode === 'edit' ? 
                                <>
                                    <Box>
                                        <FormControl>
                                            <FormLabel htmlFor="reportTitle">Report Title</FormLabel>
                                            <Input id="reportTitle" name="reportTitle" type="text" placeholder="Enter report title" defaultValue={props.title} onChange={(e) => setTitle(e.target.value)}  />
                                            <Button onClick={saveField} float={'right'} mt={'5px'} colorScheme='blue'> Save </Button>

                                        </FormControl>
                                    </Box>
                                </> 
                            : <ReportContent id={props.id} getReportName={getReportName} getMetricLength={getMetricLength} getImpactLength={getImpactLength} />
                        }
                    </DrawerBody>
                    <DrawerFooter backgroundColor={'transparent'}>
                        {props.mode !== 'edit' ? <Download id={props.id} title={props.title} impactLength={impactLength} metricLength={metricLength}  />:null}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default ViewReport;
