import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css'; // Import styles
import Styles from '../ManageThemes.module.css';
import StylesCustom from './createtheme.module.css';
import { Box, Button, Text, Input, FormControl, FormLabel, useToast, Tooltip, IconButton, } from '@chakra-ui/react';
import API from '../../../../../Services/API'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget'
import { EditIcon } from '@chakra-ui/icons';
import Pillars from './Pillars/Pillars';

const CreateTheme = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [themeId, setThemeId] = useState(null);
    const [themeName, setThemeName] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [message, setMessage] = useState(null)
    const [selectedPillars, setSelectedPillars] = useState([])
    const [selectedActions, setSelectedActions] = useState([])
    const [selectedMetrics, setSelectedMetrics] = useState([])

    const fetchThemeDetails = async () => {
        if (props.componentValue === "edit" && props.id) {
            setLoading(true);
            try {
                const response = await API.get(`/themes?id=${props.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setThemeName(response.data[0].name);
            } catch (error) {
                toast({
                    title: "Error",
                    description: "Failed to load organization details.",
                    status: "error",
                    duration: 3000,
                    isClosable: true
                });
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (isPaneOpen) {
            fetchThemeDetails();
        }
    }, [isPaneOpen]);


    const handleCreateTheme = async () => {
        setMessage(null)
        if (!themeName.trim()) {
            setMessage('Theme name is required')
            return;
        }

        setLoading(true);

        try {
            if (props.componentValue === "edit") {
                const body = selectedMetrics.map(metric => ({
                    "organisation-theme": props.id,
                    metric
                }));
                
                await API.put(`/themes`, 
                    { id: props.id, name: themeName },
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );
                setMessage("Updated theme successfully")
                props.getUpdate();
                setIsPaneOpen(false);
            } else {
                await API.post('/themes', 
                    { name: themeName },
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );
            }
            setThemeName('');
            setMessage("Created theme successfully")
            props.getUpdate();
            setIsPaneOpen(false);
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    }

    const getPillarList = (pillars)=>{
        setSelectedPillars(pillars)
        console.log("pillars" , pillars)
    }
    const getActionsList = (actions)=>{
        setSelectedActions(actions)
        console.log("actions" , actions)
    }
    const getMetricsList = (metrics)=>{
        setSelectedMetrics(metrics)
        console.log("metrics" , metrics)
    }
    return (
        <>
            {message && <ErrorWidget message={message} />}

            {
                props.componentValue === "create" ? (
                    <Box float={'right'} className={Styles.headingParentRight}>
                        <Button
                            mb={'5px'}
                            backgroundColor={'#2c2c2c'}
                            color={'#FFFFFF'}
                            onClick={() => setIsPaneOpen(true)}
                            _hover={{ backgroundColor: '#2c2c2c', color: '#FFFFF' }}
                        >
                            Create Theme +
                        </Button>
                    </Box>
                ): props.componentValue === "edit" ? (
                    <Tooltip id={props.id} label="Edit" aria-label="Edit">
                        <IconButton
                            variant="solid"
                            aria-label="Edit"
                            fontSize="15px"
                            icon={<EditIcon />}
                            className={StylesCustom.edit}
                            id={props.id}
                            onClick={() => setIsPaneOpen(true)}
                        />
                    </Tooltip>
                ) : null
            }
            <SlidingPane
                isOpen={isPaneOpen}
                title={props.componentValue + " Theme"}
                from="right"
                width="100%"
                className={StylesCustom.slidePane + " slidePaneNew"}
                onRequestClose={() => setIsPaneOpen(false)}
            >
                <FormControl mb={'10px'}>
                    <FormLabel fontWeight={'600'}>Theme Name</FormLabel>
                    <Input
                        placeholder="Enter theme name"
                        value={themeName}
                        onChange={(e) => setThemeName(e.target.value)}
                    />
                </FormControl>
                {/* {
                    props.componentValue === "edit" ?
                        <>
                            <FormControl>
                                <FormLabel fontWeight={'600'}>Assign Pillar Metrics</FormLabel>
                                <Text>
                                    Select organization pillars to filter action teams and metrics.
                                    Selected metrics will be assigned to the theme.
                                </Text>
                            </FormControl>
                            <Pillars
                                getPillarList={getPillarList}
                                getActionsList={getActionsList}
                                getMetricsList={getMetricsList}
                            />
                        </>
                    : null
                } */}

                <Button mt={4} colorScheme="blue" onClick={handleCreateTheme}>
                    Save
                </Button>
                <Button mt={4} ml={2} colorScheme="gray" onClick={() => setIsPaneOpen(false)}>
                    Close
                </Button>
            </SlidingPane>
        </>
    );
};

export default CreateTheme;
