import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from '../viewpost.module.css'
import $, { data } from 'jquery'
import API from '../../../../../Services/API';
import Select from 'react-select';
import { Heading, Box, Image, Text, Input, Textarea, Divider, Button, Card, CardHeader, CardBody, CardFooter, Stack, ButtonGroup, Grid, GridItem, StackDivider  } from '@chakra-ui/react'
import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton,} from '@chakra-ui/react'
import {FormControl, FormLabel, FormErrorMessage, FormHelperText, Badge } from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import { EditContext } from '../../../../../Context/BreadcrumbsContext';

const Cities = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [pillar, setPillar] = useState()
    const [selectedOptions, setSelectedOptions] = useState()
    const [message, setMessage] = useState(null)
    const [newClass, setNewClass] = useState(null)
    const selectedRef=useRef(null)
    
    const {hasTagApplyPermission ,user} = useContext(EditContext);
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        console.log("identity",props?.blogRegionsIds)
        const queryParam = props?.blogRegionsIds;
       
        if(props?.blogRegionIds?.length===0&&props?.blogRegionsIds?.length===0&&props?.blogCountryIds?.length===0){
            setPillar([])
        selectedRef.current?.clearValue();
       
        return};
         API.get(
                  `/get-geographical-data?state=${queryParam?.join(",")}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
        .then((response) => {
            const sortedData = response && response?.data?.sort((a, b) => 
                a.city_name.localeCompare(b.city_name)
              );
              setPillar(sortedData && sortedData)
              
            }).catch(error=>{
            setIsLoading(false)
        })
    },[props?.blogRegionsIds])
    
    const options = pillar && pillar.map(item =>
        ({label: item.city_name, value: item.id})
    )

    const defaultSelectedOptions = props.selectedOptions && props.selectedOptions.map(item =>
        ({label: item.city_name, value: item.id, parent_id:item?.parent_id})
    )

    const selectOption = (event) =>{
        setSelectedOptions(event.map(option => option.value));
    }

   
    const addOptions = () =>{
        setMessage(null)
        setIsLoading(true)
        const formData = new FormData()
        formData.append('blog_id', parseInt(props.id))
        formData.append('blog_city', JSON.stringify(selectedOptions))
        // formData.append('approval_status', "pending")
        formData.append(
            "approval_status",
            (Array.isArray(selectedOptions) && selectedOptions?.length===0) ? "approved" : "pending"
          );
        API.put(`/blog`,formData , {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            API.get(`/blog?id=${props.id}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                props.getUpdated(response.data[0])
                setIsLoading(false)
                setNewClass('colorBlue')
                setMessage('Changes updated successfully')
                setIsLoading(false)
            })            
        }).catch(error=>{
            setMessage(error &&  error.response &&  error.response.data && error.response.data.msg);
            setNewClass('colorRed')
            setIsLoading(false)
        })
    }

    return (
        <>
            { 
                isLoading ? <LoaderSpinner />: null
            }
            {message && message ? <ErrorWidget message={message && message} /> : null}
            <Box>
                <Heading className={Styles.sideBarHeading}>
                    Cities
                </Heading>
                <Text p='0' className={Styles.sideBarText}>
                    {
                        props.editable ?
                            <>
                                <Select
                                    ref={selectedRef}
                                    placeholder="Select Countries"
                                    aria-label="Select Countries"
                                    options={options}
                                    isMulti
                                    defaultValue={defaultSelectedOptions}
                                    onChange={selectOption}
                                />
                                <Button  background={'#00aae0'} color={'#ffffff'} mt={'5px'} onClick={addOptions}> Save </Button>
                            </>
                        : <>
                            {
                                props.selectedOptions && props.selectedOptions.map(item => 
                                    <Tag
                                        borderRadius='full'
                                        variant='solid'
                                        background={'#d4d4d8'}
                                        color={"#000000"}
                                        mr={'5px'}
                                        mb={'5px'}
                                    >
                                        <TagLabel>{item.city_name}</TagLabel>
                                    </Tag>
                                )
                            }
                    </>
                    }
                    
                </Text>
            </Box>
        </>
    )
}

export default Cities