import React, { useEffect, useState } from 'react';
import { withRouter, useNavigate  } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppRoutes from './AppRoutes'
import './App.css';
import './App.scss';
import Layout from './Layout/Layout';
import { ChakraProvider } from '@chakra-ui/react'
import Login from './Pages/Login/Login';
import BeforeLogin from './Layout/BeforeLogin';
import AppRoutesBeforeLogin from './AppRoutesBeforeLogin';
import { EditProvider } from './Context/BreadcrumbsContext';

function App() {
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/'); // Redirect to login page
    }
  }, [token, navigate]);

  return (
    <div className="App">
      <EditProvider>
      <ChakraProvider>
        {
          !token ?
          <BeforeLogin>
            <AppRoutesBeforeLogin />
          </BeforeLogin>:
          <Layout>
            <AppRoutes />
          </Layout>
        }
      </ChakraProvider>
      </EditProvider>
    </div>
  );
}

export default App;
