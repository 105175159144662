import React, { useEffect, useState, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css'; // Import styles
import Styles from '../ManageThemes.module.css';
import StylesCustom from '../CreateTheme/createtheme.module.css';
import { Box, Button, Text, Input, FormControl, FormLabel, useToast, Tooltip, IconButton, } from '@chakra-ui/react';
import API from '../../../../../Services/API'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import { 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader, 
    AlertDialogBody, 
    AlertDialogFooter, 
    useDisclosure 
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const DeleteTheme = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const [message, setMessage] = useState(null);

    const handleDelete = async () => {
        setIsLoading(true);
        setMessage(null)
        try{
            const response = await API.delete('/themes', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            data: { id: props.id },
        });
            props.getUpdate()
            setIsLoading(false)
            onClose()
            setMessage("Theme entry removed successfully.")
        }catch (error) {
            setIsLoading(false)
            onClose()
            setMessage("Failed to delete theme")
        }
    }

    return (
        <>
            {isLoading && <LoaderSpinner /> }
            {message && <ErrorWidget message={message} /> }

            <Tooltip label="Delete" aria-label="Delete">
                <IconButton
                    variant="solid"
                    aria-label="Delete"
                    fontSize="15px"
                    icon={<DeleteIcon />}
                    className={StylesCustom.edit}
                    onClick={onOpen}
                />
            </Tooltip>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Deletion
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this theme? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter backgroundColor={'transparent'}>
                            <Button ref={cancelRef} onClick={onClose} backgroundColor={'transparent'}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default DeleteTheme;
