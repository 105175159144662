import React, { useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Text,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import API from "../../../../Services/API";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ErrorWidget from "../../../../Components/Widgets/ErrorWidget/ErrorWidget";

const Delete = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const cancelRef = React.useRef();
  const token = window.localStorage.getItem("accessToken");

  const deleteReport = async () => {
    setIsLoading(true);
    setMessage(null)
    try {
      await API.delete("/custom-country-report", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { id: props.id },
      });
      props.getUpdated();
      setMessage("Report deleted successfully.");
      setIsLoading(false);
    } catch (error) {
      setMessage("Failed to delete the report");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
        {isLoading && <LoaderSpinner /> }
        {message && <ErrorWidget message={message} /> }
      <DeleteIcon ml={"8px"} cursor={"pointer"} onClick={onOpen} />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this report? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter backgroundColor={'transparent'}>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={deleteReport}
                ml={3}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Delete;
