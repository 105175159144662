import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../Components/Widgets/BreadCrumbs/BreadCrumbs';
import { Link, useLocation } from 'react-router-dom';
import API from '../../Services/API';
import { Box, Button, Text, VStack, Icon, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, useDisclosure, DrawerFooter, Flex } from "@chakra-ui/react";
import {FormControl,FormLabel,Input,Switch,FormControlLabel,} from "@chakra-ui/react";
import { FaPlus, FaRegFileAlt } from "react-icons/fa";
import EirInnerComponent from './EirInnerComponent/EirInnerComponent';
import { AddIcon } from "@chakra-ui/icons";
import Pillars from './EirInnerComponent/Pillars/Pillars';
import ReportsList from './ReportsList/ReportsList';
import { format } from "date-fns";
import ErrorWidget from '../../Components/Widgets/ErrorWidget/ErrorWidget'
import LoaderSpinner from '../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const ExecutiveImpactReport = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const user = JSON.parse(window.localStorage.getItem("user"))
    const location = useLocation();
    const { hash, pathname, search } = location;
    const [basicUiMenuOpen, setBasicUiMenuOpen] = useState(false)
    const [pillars, setPillars] = useState(false)
    const [region, setRegion] = useState(false)
    const [portfolio, setPortfolio] = useState(false)
    const [help, setHelp] = useState(false)
    const [menuList, setMenuList] = useState()
    const [subMenuList, setSubMenuList] = useState()
    
    const [reportName, setReportName] = useState("")
    const [selectedGeographiesData, setSelectedGeographiesData] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [includeImpactStories, setIncludeImpactStories] = useState(false)

    const [selectedTags, setSelectedTags] = useState([])
    const [selectedPillars, setSelectedPillars] = useState([])
    const [selectedActions, setSelectedActions] = useState([])
    const [selectedMetrics, setSelectedMetrics] = useState([])
    const [updateTrigger, setUpdateTrigger] = useState(0);

    const [nameRequired, setNameRequired] = useState(null);
    const [nameRegionRequired, setRegionRequired] = useState(null);
    const [startDateRequired, setStartDateRequired] = useState(null);
    const [endDateRequired, setEndDateRequired] = useState(null);

    useEffect(() => {
        if (onClose) {
            setReportName("");
            setSelectedGeographiesData([]);
            setStartDate(null);
            setEndDate(null);
            setIncludeImpactStories(false);
            setSelectedTags([]);
            setSelectedPillars([]);
            setSelectedActions([]);
            setSelectedMetrics([]);
            setMessage(null);
            setNameRequired(null)
        }
    }, [isOpen]);

    const getSelectedGeographies = (geographiesData) =>{
        setSelectedGeographiesData(geographiesData)
    }
    const getStartDate=(startDate)=>{
        setStartDate(startDate)
    }
    const getEndDate=(endDate)=>{
        setEndDate(endDate)
    }
    const getIncludeImpactStories=(event)=>{
        setIncludeImpactStories(event)
    }

    const getPillarsList=(pillars)=>{
        setSelectedPillars(pillars)
    }
    const getActionsList=(actions)=>{
        setSelectedActions(actions)
    }

    const getMetricsList=(metrics)=>{
        setSelectedMetrics(metrics)
    }

    const getTagsList=(tags)=>{
        setSelectedTags(tags)
    }

    const createReport = () =>{
        setMessage(null)
        if (!reportName.trim()) {
            setNameRequired("Report title is required.");
            setIsLoading(false);
            return;
        }

        setIsLoading(true)
        setMessage(null)
        const regions = Array.from(new Set(selectedGeographiesData.map((item) => item.regionId)));
        const countries = Array.from(new Set(selectedGeographiesData.map((item) => item.countryId)));
        const states = Array.from(new Set(selectedGeographiesData.map((item) => item.stateId)));
        const cities = Array.from(new Set(selectedGeographiesData.map((item) => item.cityId)));

        if (regions.length === 0) {
            setRegionRequired("Geographic region must be selected.");
            setIsLoading(false);
            return;
        }

        if (!startDate || !endDate) {
            setStartDateRequired("Both start date and end date are required.");
            setIsLoading(false);
            return;
        }

        if (new Date(startDate) > new Date(endDate)) {
            setMessage("Start date cannot be later than end date.");
            setIsLoading(false);
            return;
        }
        


        const formData = new FormData();

        let city_report = [];
        let state_report = [];
        let country_report = [];
        let region_report = [];
        
        selectedGeographiesData.forEach(item => {
            if (item.cityId !== null) {
                city_report.push(item.cityId);
            } else if (item.stateId !== null) {
                state_report.push(item.stateId);
            } else if (item.countryId !== null) {
                country_report.push(item.countryId);
            } else if (item.regionId !== null) {
                region_report.push(item.regionId);
            }
        });

        if (city_report.length > 0) {
            formData.append("city_report", JSON.stringify(city_report));
        }
        if (state_report.length > 0) {
            formData.append("state_report", JSON.stringify(state_report));
        }
        if (country_report.length > 0) {
            formData.append("country_report", JSON.stringify(country_report));
        }
        if (region_report.length > 0) {
            formData.append("region_report", JSON.stringify(region_report));
        }

        if(selectedMetrics){
            formData.append("metric_report", JSON.stringify(selectedMetrics));
        }


        formData.append("title", reportName);
        formData.append("start_date", startDate);
        formData.append("end_date", endDate);
        formData.append("impact_story", includeImpactStories);

        if(selectedTags){
            formData.append("tag_report", JSON.stringify(selectedTags));
        }

        if(selectedPillars) {
            formData.append("pillar_report", JSON.stringify(selectedPillars))
        }

        if(selectedActions) {
            formData.append("action_report", JSON.stringify(selectedActions))
        }


        API.post(`/custom-country-report`,formData , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            onClose()
            setUpdateTrigger((prev) => prev + 1);
            setMessage('Report created successfully')
            setIsLoading(false)
        }).catch(error=>{
            onClose()
            setMessage('Failed to create report')
            setIsLoading(false)
        })
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Breadcrumb title={'Custom Reports'} />
            
            <Box p={6} pb={'0'}>
                <Text color="gray.600" mb="5px">
                    Create customized impact reports based on your organization's metrics and actions. Highlight global outcomes and include compelling impact stories in PPT, PDF, or XLS formats.
                </Text>
                {/* Create Button - Opens Drawer */}
                <Button 
                    leftIcon={<FaPlus />} 
                    colorScheme="blackAlpha" 
                    backgroundColor="#000000" 
                    fontSize="12px"
                    float="right"
                    onClick={onOpen}  // Open Drawer on Click
                >
                    Create a Custom Report
                </Button>
                <ReportsList updateTrigger={updateTrigger} />
                {/* Wider Sliding Drawer */}
                <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={false}>
                    <DrawerOverlay />
                    <DrawerContent maxW="50%">  {/* Adjust width here */}
                        <DrawerCloseButton />
                        <DrawerHeader backgroundColor={'#c9d2d3'} fontWeight={'normal'} padding={'10px 10px'}>Create a new custom report</DrawerHeader>
                        <DrawerBody>
                            <FormControl mb={4}>
                                <FormLabel>Please provide report title</FormLabel>
                                <Input
                                    placeholder="Please provide report title"
                                    defaultValue={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                />
                                <Text color={'red'}>{nameRequired && nameRequired}</Text>
                            </FormControl>
                            <Text color={'red'}>{nameRegionRequired && nameRegionRequired}</Text>
                            <EirInnerComponent 
                                menuList={menuList} 
                                getSelectedGeographies={getSelectedGeographies}
                                getStartDate={getStartDate}
                                getEndDate={getEndDate}
                                includeImpactStories={getIncludeImpactStories}
                            />
                            <Text color={'red'}>{startDateRequired && startDateRequired}</Text>
                            <Pillars
                                getTagsList={getTagsList}
                                getPillarsList={getPillarsList}
                                getActionsList={getActionsList}
                                getMetricsList={getMetricsList}
                            />
                        </DrawerBody>
                        <DrawerFooter backgroundColor={'transparent'} borderTop={'1px solid #E2E8F0'}>
                            <Flex justify="flex-end" w="100%" gap={2}>
                                <Button variant="outline" onClick={onClose}>Cancel</Button>
                                <Button 
                                    colorScheme="blackAlpha" 
                                    bg="black" 
                                    color="white" 
                                    rightIcon={<AddIcon />} onClick={createReport} 
                                    isDisabled={!(includeImpactStories || (selectedMetrics.length > 0))}
                                > Create custom report
                                </Button>
                            </Flex>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Box>
        </>
    );
  };
  
  export default ExecutiveImpactReport;
  