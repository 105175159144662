import React, { useState, useRef } from "react";
import { TagCloseButton, Button, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from "@chakra-ui/react";
import API from "../../../../../Services/API";
import LoaderSpinner from "../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ErrorWidget from "../../../../../Components/Widgets/ErrorWidget/ErrorWidget";

const DeleteMetric = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const cancelRef = useRef();

    const removeMetric = async (id) => {
        setIsLoading(true);
        try {
            const response = await API.delete("/organisation-theme-metric", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                data: { id: id }, // Send the ID in request body
            });
            setIsLoading(false);
            props.getUpdate();
            setMessage("Successfully removed metric from the theme");
        } catch (error) {
            setIsLoading(false);
            setMessage("Failed to delete the organization");
        }
        setIsOpen(false);
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <TagCloseButton onClick={() => setIsOpen(true)} />


            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={() => setIsOpen(false)}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Metric
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this metric? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter backgroundColor={'transparent'}>
                            <Button ref={cancelRef} onClick={() => setIsOpen(false)} backgroundColor={'transparent'}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={() => removeMetric(props.id)} ml={3} isLoading={isLoading}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default DeleteMetric;
