import React, { useEffect, useState } from "react";
import API from "../../../../../Services/API";
import { Box, Flex, Text } from "@chakra-ui/react";
import RegionMap from './RegionMap/RegionMap'

const Geographies = ({ id, getSelectedGeographies }) => {
    const [token] = useState(window.localStorage.getItem("accessToken"));
    const [report, setReport] = useState(null);
    const [selectedGeographicalData, setSelectedGeographicalData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await API.get(`/custom-country-report?report_id=${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const reportData = response.data?.[0] || {};
                setReport(reportData);
                setSelectedGeographicalData(reportData.geographical_data || []);
                console.log("reportData", reportData.geographical_data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (id) fetchData();
    }, [id, token]);

    useEffect(() => {
        getSelectedGeographies(selectedGeographicalData);
    }, [selectedGeographicalData, getSelectedGeographies]);


    const getNewGeographies = (newGeographies) => {
        setSelectedGeographicalData([...selectedGeographicalData, newGeographies])
        getSelectedGeographies(selectedGeographicalData);
    }

    return (
        <>
            <RegionMap getNewGeographies={getNewGeographies} />
            <Box mt={'5px'}>
                {selectedGeographicalData.map((geography, index) => (
                    <Flex
                        key={index}
                        bg="#cbcbcb"
                        p="5px"
                        borderRadius="md"
                        align="center"
                        justify="space-between"
                        mb={2}
                        color="#3e4b5b"
                    >
                        <Text flex="1" mb={0}>
                            <Text as="span" fontWeight="bold">{geography.region_name}</Text>
                            {[geography.country_name, geography.state_name, geography.city_name]
                                .filter(Boolean)
                                .length > 0 && ` / ${[geography.country_name, geography.state_name, geography.city_name].filter(Boolean).join(" / ")}`}
                        </Text>
                    </Flex>
                ))}
            </Box>
        </>
        
    );
};

export default Geographies;
