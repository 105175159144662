import React, { useEffect, useState, useCallback } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Styles from '../ManageOrganizations.module.css';
import StylesCustom from './createOrganization.module.css';
import { 
    Box, Button, Input, FormControl, FormLabel, useToast, Tooltip, IconButton, Spinner 
} from '@chakra-ui/react';
import API from '../../../../../Services/API';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { EditIcon } from '@chakra-ui/icons';

const CreateOrganization = (props) => {
    const [organizationName, setOrganizationName] = useState('');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const token = window.localStorage.getItem("accessToken");
    const toast = useToast();

    const fetchOrganizationDetails = useCallback(async () => {
        if (props.componentValue === "edit" && props.id) {
            setLoading(true);
            try {
                const response = await API.get(`/organisations?id=${props.id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (response.data.length > 0) {
                    setOrganizationName(response.data[0].name);
                }
            } catch (error) {
                console.error("Error fetching organization details:", error);
                setMessage("Failed to load organization details")
            } finally {
                setLoading(false);
            }
        }
    }, [props.componentValue, props.id, token, toast]);

    useEffect(() => {
        if (isPaneOpen) fetchOrganizationDetails();
    }, [isPaneOpen, fetchOrganizationDetails]);

    const handleCreateOrganization = async () => {
        if (!organizationName.trim()) {
            setMessage('Organization name is required');
            return;
        }

        setLoading(true);
        try {
            const requestData = { name: organizationName };
            const headers = { 'Authorization': `Bearer ${token}` };

            if (props.componentValue === "edit") {
                requestData.id = props.id;
                await API.put('/organisations', requestData, { headers });
                setMessage('Organization updated successfully!')
            } else {
                await API.post('/organisations', requestData, { headers });
                setMessage('Organization created successfully!')
            }
            setOrganizationName('');
            props.getUpdate();
            setIsPaneOpen(false);
        } catch (error) {
            console.error("Error creating/updating organization:", error);
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            {props.componentValue === "create" ? (
                <Box float="right" className={Styles.headingParentRight}>
                    <Button
                        mb="5px"
                        backgroundColor="#2c2c2c"
                        color="#FFFFFF"
                        onClick={() => setIsPaneOpen(true)}
                        _hover={{ backgroundColor: '#2c2c2c', color: '#FFFFFF' }}
                    >
                        Create Organization +
                    </Button>
                </Box>
            ) : (
                <Tooltip label="Edit" aria-label="Edit">
                    <IconButton
                        variant="solid"
                        aria-label="Edit"
                        fontSize="15px"
                        icon={<EditIcon />}
                        className={StylesCustom.edit}
                        onClick={() => setIsPaneOpen(true)}
                    />
                </Tooltip>
            )}

            <SlidingPane
                isOpen={isPaneOpen}
                title={props.componentValue === "create" ? "Create Organization" : "Edit Organization"}
                from="right"
                width="100%"
                className={StylesCustom.slidePane}
                onRequestClose={() => setIsPaneOpen(false)}
            >
                {loading ? (
                    <Spinner size="lg" />
                ) : (
                    <>
                        <FormControl>
                            <FormLabel fontWeight="600">Organization Name</FormLabel>
                            <Input
                                placeholder="Enter Organization Name"
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value)}
                            />
                        </FormControl>
                        <Button mt={4} colorScheme="blue" onClick={handleCreateOrganization}>
                            Save
                        </Button>
                        <Button mt={4} ml={2} colorScheme="gray" onClick={() => setIsPaneOpen(false)}>
                            Close
                        </Button>
                    </>
                )}
            </SlidingPane>
        </>
    );
};

export default CreateOrganization;
