// import React, { useEffect, useState } from 'react'
// import $ from 'jquery'
// import Styles from '../SideBarMenu/sideBarMenu.module.css'
// import API from '../../../../../Services/API';
// import { Collapse } from 'react-bootstrap';
// import { Link, useLocation } from 'react-router-dom';
// import CreateModule from './CreateModule/CreateModule';
// import SideBarMenu from '../SideBarMenu/SideBarMenu';
// import {
//     Box,
//     Accordion,
//     AccordionItem,
//     AccordionButton,
//     AccordionPanel,
//     AccordionIcon,
//     Text,
//     Heading
// } from '@chakra-ui/react'


// const RegionNavigation = () => {
//     const token = window.localStorage.getItem("accessToken")
//     const user = JSON.parse(window.localStorage.getItem("user"))
//     const location = useLocation();
//     const { hash, pathname, search } = location;
//     const [basicUiMenuOpen, setBasicUiMenuOpen] = useState(false)
//     const [pillars, setPillars] = useState(false)
//     const [region, setRegion] = useState(false)
//     const [portfolio, setPortfolio] = useState(false)
//     const [help, setHelp] = useState(false)
//     const [menuList, setMenuList] = useState()
//     const [subMenuList, setSubMenuList] = useState()

//     useEffect(() => {
//         API.get(`/project-modules`, {
//             headers: {
//               'Authorization': 'Bearer ' + token
//             }
//         }).then(response=>{
//             setMenuList(response.data)
//             API.get(`/project-modules`, {
//                 headers: {
//                   'Authorization': 'Bearer ' + token
//                 }
//             }).then(response=>{
//                 setSubMenuList(response.data)
//                 $('.subMenuNew:empty').remove()
//                 $('.subMenuNew').closest('li').addClass('acordian')
//             }).catch(error=>{
                
//             })
//         }).catch(error => {
//             if(error.response.data.code === 'token_not_valid'){
//                 window.localStorage.removeItem('accessToken')
//                 window.localStorage.removeItem('refreshToken')
//                 window.localStorage.removeItem('user')
//                 window.location.href = '/login'
//             }
//           });
//     },[]);

//     $('.subMenuNew:empty').remove()
//     $('.subMenuNew').closest('li').addClass('acordian')

//     const customSort = (a, b) => {
//         const nameA = a.name.toUpperCase();
//         const nameB = b.name.toUpperCase();
      
//         // Extract numbers from the names
//         const numA = parseInt(nameA.match(/\d+/), 10);
//         const numB = parseInt(nameB.match(/\d+/), 10);
      
//         // Compare numbers if both names have numbers
//         if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
//             return numA - numB;
//         }
      
//         // Compare strings
//         return nameA.localeCompare(nameB);
//     };

//     return (
//         <>
//             <Accordion allowToggle border={'none'}>
//                 {menuList && menuList.map((item) => {
//                     const subItems = menuList.filter(subItem => subItem.parent_id === item.id).sort(customSort);
//                     return item.category.toLowerCase() === 'region' && item.parent_id === null ? (
//                         subItems.length > 0 ? (
//                             <AccordionItem key={item.id} bg="transparent" border={'none'}>
//                                 <AccordionButton className={Styles.acrdnButton} pb={'0'}>
//                                     <Box as='span' flex='1' textAlign='left' className={Styles.span}>
//                                         <Link className={'overwrite nav-link'} to={`/${item.category}/${item.id}/${encodeURIComponent(item.name)}`}>
//                                             {item.name}
//                                         </Link>
//                                     </Box>
//                                     {/* Accordion Icon positioned to the right of the text */}
//                                     <AccordionIcon color={"#00aae0"} />
//                                 </AccordionButton>
//                                 <AccordionPanel pb={"0"} bg="transparent" pt={'0'}>
//                                     <ul className='subMenuNew'>
//                                         {subItems.map(subItem => (
//                                             <li key={subItem.id} className={` ${Styles.mnLi}`}>
//                                                 <Link className={`nav-link ${Styles.subNavLink}`} pl={'0'} to={`/${item.category}/${item.id}/${item.name}/country/${subItem.id}/${encodeURIComponent(subItem.name)}`}>
//                                                     {subItem.name}
//                                                 </Link>
//                                                 <Box className='clearfix'></Box>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </AccordionPanel>
//                             </AccordionItem>
//                         ) : (
//                             <Box as="li" key={item.id} className="nav-item">
//                                 <Link className={`nav-link ${Styles.mnLink}`} to={`/${item.category}/${item.id}/${encodeURIComponent(item.name)}`}>
//                                     {item.name}
//                                 </Link>
//                             </Box>
//                         )
//                     ) : null;
//                 })}
//             </Accordion>
//         </>
//     )
// }

// export default RegionNavigation

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text
} from '@chakra-ui/react';
import API from '../../../../../Services/API';
import Styles from '../SideBarMenu/sideBarMenu.module.css';

const RegionNavigation = () => {
  const token = window.localStorage.getItem("accessToken");
  const [regions, setRegions] = useState([]);

  // ✅ Fetch data from new endpoint
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await API.get('/nav-geographical-data', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setRegions(response.data || []);
      } catch (error) {
        if (error.response?.data?.code === 'token_not_valid') {
          window.localStorage.removeItem('accessToken');
          window.localStorage.removeItem('refreshToken');
          window.localStorage.removeItem('user');
          window.location.href = '/login';
        }
      }
    };

    fetchRegions();
  }, [token]);

  const customSort = (a, b) => {
            const nameA = a.region_name?.toUpperCase();
            const nameB = b.region_name?.toUpperCase();
          
            const numA = parseInt(nameA?.match(/\d+/), 10);
            const numB = parseInt(nameB?.match(/\d+/), 10);
          
            if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
                return numA - numB;
            }
          
            return nameA?.localeCompare(nameB);
        };

  return (
    <Accordion allowToggle border="none" >
      {regions.sort(customSort).map((region) => (
        <AccordionItem key={region.id} bg="transparent" border="none">
          {/* ✅ Region */}
          <AccordionButton className={Styles.acrdnButton} pb="0">
            <Box as="span" flex="1" textAlign="left" className={Styles.span}>
                {console.log("link",region,`/region/${region.id}/${encodeURIComponent(region.region_name)}`)}
              <Link
                className="nav-link"
                to={`/region/${region.id}/${encodeURIComponent(region.region_name)}`}
              >
                {region.region_name}
              </Link>
            </Box>
            <AccordionIcon color="#00aae0" />
          </AccordionButton>

          <AccordionPanel pb="0" bg="transparent" pt="0">
            <Accordion allowToggle>
              {/* ✅ Map Countries */}
              {region.countries?.sort(customSort).map((country) => (
                <AccordionItem key={country.id} bg="transparent" border="none">
                  {/* ✅ Country */}
                  <AccordionButton className={Styles.acrdnButton} pb="0">
                    <Box as="span" flex="1" textAlign="left" className={Styles.span}>
                      <Link
                        className="nav-link"
                        to={`/region/${region.id}/${encodeURIComponent(region?.region_name)}/country/${country.id}/${encodeURIComponent(country.country_name)}`}
                      >
                        {country.country_name}
                      </Link>
                    </Box>
                    <AccordionIcon color="#00aae0" />
                  </AccordionButton>

                  <AccordionPanel pb="0" bg="transparent" pt="0">
                    <Accordion allowToggle>
                      {/* ✅ Map States */}
                      {country.states?.sort(customSort).map((state) => (
                        <AccordionItem key={state.id} bg="transparent" border="none">
                          {/* ✅ State */}
                          <AccordionButton className={Styles.acrdnButton} pb="0">
                            <Box as="span" flex="1" textAlign="left" className={Styles.span}>
                              <Link
                                className="nav-link"
                                to={`/region/${region.id}/${encodeURIComponent(region?.region_name)}/country/${country.id}/${encodeURIComponent(country.country_name)}/state/${state.id}/${encodeURIComponent(state.state_name)}`}
                              >
                                {state.state_name}
                              </Link>
                            </Box>
                            <AccordionIcon color="#00aae0" />
                          </AccordionButton>

                          <AccordionPanel pb="0" bg="transparent" pt="0">
                            <ul >
                              {/* ✅ Map Cities */}
                              {state.cities?.sort(customSort).map((city) => (
                                  <AccordionButton className={Styles.acrdnButton} pb="0" >
                                   <Box as="span" flex="1" textAlign="left" className={Styles.span}>
                                  <Link
                                    // className="nav-link"
                                    to={`/region/${region.id}/${encodeURIComponent(region?.region_name)}/country/${country.id}/${encodeURIComponent(country.country_name)}/state/${state.id}/${encodeURIComponent(state.state_name)}/city/${city.id}/${encodeURIComponent(city.city_name)}`}
                                  >
                                    {city.city_name}
                                  </Link>
                            </Box>
                                </AccordionButton>
                              ))}
                            </ul>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default RegionNavigation;
