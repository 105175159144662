import React, { memo, useEffect, useRef, useState } from 'react';
import Styles from '../impactgallery.module.css';
import Select from 'react-select';
import API from '../../../../Services/API'; // Ensure your API service is correctly imported
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DataFilter = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false)
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedStates, setSelectedStates] = useState(null);
    const [selectedcities, setSelectedCities] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [countryList, setCountryList] = useState([])
    const [statesList, setstatesList] = useState([])
    const [cityList, setCityList] = useState([])
    const [filter, setFilter] = useState([])
    const countrySelectRef = useRef(null);
    const statesSelectRef = useRef(null);
    const citySelectRef = useRef(null);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '12px',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 120, // Ensure the dropdown is above other elements
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '12px',
            zIndex: 120,
        }),
    };

    const actionOptions = props.actionOptions?.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const regionOptions = props.regionOption?.map(item => ({
        value: item?.id,
        label: item?.region_name,
    })).sort((a, b) => a?.label?.localeCompare(b?.label)) || [];
    const countryOptions = countryList && countryList?.map(item => ({
        value: item?.id,
        label: item?.country_name,
    })).sort((a, b) => a?.label?.localeCompare(b?.label)) || [];
    const statesOptions = statesList && statesList?.map(item => ({
        value: item.id,
        label: item.state_name,
    })).sort((a, b) => a.label.localeCompare(b.label)) || [];
    const cityOptions = cityList && cityList?.map(item => ({
        value: item.id,
        label: item.city_name,
    })).sort((a, b) => a.label.localeCompare(b.label)) || [];

    const yearOptions = props.yearOptions?.map(item => ({
        value: item.year,
        label: item.year?.toString(),
    }));

    // useEffect(() => {
    //     if (selectedRegion) {
    //         setIsLoading(true);
    //         API.get(`/get-geographical-data?region=${selectedRegion}`, {
    //             headers: { 'Authorization': `Bearer ${token}` },
    //         })
    //             .then(response => {
    //                 const sortedData = response.data.sort((a, b) => a.country_name.localeCompare(b.country_name));
    //                 setSelectedCountry(null);
    //                 setSelectedStates(null);
    //                 setSelectedCities(null);
    //                 countrySelectRef.current?.clearValue();
    //                 statesSelectRef.current?.clearValue();
    //                 citySelectRef.current?.clearValue();
    //                 setCountryList(sortedData);
    //             })
    //         .catch(error => console.error(error))
    //         .finally(() => setIsLoading(false));
    //     }
    //    if(selectedCountry) setSelectedCountry(null);
    //    if(selectedStates) setSelectedStates(null);
    //    if(selectedcities) setSelectedCities(null);
    //    if(countrySelectRef.current) countrySelectRef.current?.clearValue();
    //    if(statesSelectRef.current) statesSelectRef.current?.clearValue();
    //    if(citySelectRef.current) citySelectRef.current?.clearValue();
    // }, [selectedRegion]); // Only fetch when `selectedRegion` changes

    useEffect(() => {
        const dataPoint = filter === "statesFilter" ? "state" : filter === "countryFilter" ? "country" : filter === "regionFilter" ? "region" : null
        const dataParams = filter === "statesFilter" ? selectedStates : filter === "countryFilter" ? selectedCountry : filter === "regionFilter" ? selectedRegion : null
        console.log("point", dataPoint, dataParams)

        if (dataPoint && dataParams) {
            setIsLoading(true);
            API.get(`/get-geographical-data?${dataPoint}=${dataParams}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            })
                .then(response => {
                    if (dataPoint === "region") {

                        const sortedData = response.data.sort((a, b) => a.country_name.localeCompare(b.country_name));
                        setCountryList(sortedData);
                        setstatesList([])
                        setCityList([])
                        if (selectedCountry) setSelectedCountry(null);
                        if (selectedStates) setSelectedStates(null);
                        if (selectedcities) setSelectedCities(null);
                        if (countrySelectRef.current) countrySelectRef.current?.clearValue();
                        if (statesSelectRef.current) statesSelectRef.current?.clearValue();
                        if (citySelectRef.current) citySelectRef.current?.clearValue();
                    }
                    if (dataPoint === "country") {

                        const sortedData = response.data.sort((a, b) => a.state_name.localeCompare(b.state_name));
                        setstatesList(sortedData);
                        setCityList([])
                        if (selectedStates) setSelectedStates(null);
                        if (selectedcities) setSelectedCities(null);
                        if (statesSelectRef.current) statesSelectRef.current?.clearValue();
                        if (citySelectRef.current) citySelectRef.current?.clearValue();
                    }
                    if (dataPoint === "state") {
                        if (selectedcities) setSelectedCities(null);
                        if (citySelectRef.current) citySelectRef.current?.clearValue();
                        const sortedData = response.data.sort((a, b) => a.city_name.localeCompare(b.city_name));
                        setCityList(sortedData);
                    }
                })
                .catch(error => console.error(error))
                .finally(() => setIsLoading(false));
        } else {
            if (dataPoint === "region") {
                setCountryList([])
                setstatesList([])
                setCityList([])
                if (selectedCountry) setSelectedCountry(null);
                if (selectedStates) setSelectedStates(null);
                if (selectedcities) setSelectedCities(null);
                if (countrySelectRef.current) countrySelectRef.current?.clearValue();
                if (statesSelectRef.current) statesSelectRef.current?.clearValue();
                if (citySelectRef.current) citySelectRef.current?.clearValue();
            }
            if (dataPoint === "country") {

                setCityList([])
                setstatesList([])
                if (selectedStates) setSelectedStates(null);
                if (selectedcities) setSelectedCities(null);
                if (statesSelectRef.current) statesSelectRef.current?.clearValue();
                if (citySelectRef.current) citySelectRef.current?.clearValue();
            }
            if (dataPoint === "state") {
                setCityList([])
                if (selectedcities) setSelectedCities(null);
                if (citySelectRef.current) citySelectRef.current?.clearValue();
            }
        }

    }, [selectedRegion, selectedCountry, selectedStates]); // Only fetch when `selectedCountry` changes

    // useEffect(() => {
    //     if (selectedStates) {
    //         setIsLoading(true);
    //         API.get(`/get-geographical-data?state=${selectedStates}`, {
    //             headers: { 'Authorization': `Bearer ${token}` },
    //         })
    //         .then(response => {
    //             const sortedData = response.data.sort((a, b) => a.city_name.localeCompare(b.city_name));
    //             setCityList(sortedData);
    //         })
    //         .catch(error => console.error(error))
    //         .finally(() => setIsLoading(false));
    //     }
    //     if(selectedcities) setSelectedCities(null);
    //     if(citySelectRef.current) citySelectRef.current?.clearValue();
    //  }, [selectedStates]); // Only fetch when `selectedStates` changes



    const handleChangeFilter = (selectedOption, actionMeta) => {
        setIsLoading(true);

        let updatedFilters = {
            action_id: selectedAction,
            region_id: selectedRegion,
            country_id: selectedCountry,
            states_id: selectedStates,
            city_id: selectedcities,
            year: selectedYear,
        };

        switch (actionMeta.name) {
            case 'actionFilter':
                updatedFilters.action_id = selectedOption ? selectedOption.value : null;
                setSelectedAction(updatedFilters.action_id);
                break;
            case 'regionFilter':
                updatedFilters.region_id = selectedOption ? selectedOption.value : null;
                setSelectedRegion(updatedFilters.region_id);
                setFilter("regionFilter")
                setSelectedCountry(null);
                setSelectedStates(null);
                setSelectedCities(null);
                break;
            case 'countryFilter':
                updatedFilters.country_id = selectedOption ? selectedOption.value : null;
                setSelectedCountry(updatedFilters.country_id);
                setFilter("countryFilter")
                setSelectedStates(null);
                setSelectedCities(null);
                break;
            case 'statesFilter':
                updatedFilters.states_id = selectedOption ? selectedOption.value : null;
                setSelectedStates(updatedFilters.states_id);
                setFilter("statesFilter")
                setSelectedCities(null);
                break;
            case 'cityFilter':
                updatedFilters.city_id = selectedOption ? selectedOption.value : null;
                setSelectedCities(updatedFilters.city_id);
                break;
            case 'yearFilter':
                updatedFilters.year = selectedOption ? selectedOption.value : null;
                setSelectedYear(updatedFilters.year);
                break;
            default:
                break;
        }

        // Fetch updated data only if at least one filter is selected
        if (
            updatedFilters.action_id !== null ||
            updatedFilters.region_id !== null ||
            updatedFilters.country_id !== null ||
            updatedFilters.states_id !== null ||
            updatedFilters.city_id !== null ||
            updatedFilters.year !== null
        ) {
            API.get(`/get-blog`, {
                params: updatedFilters,
                headers: { Authorization: `Bearer ${token}` },
            })
                .then(response => props.getUpdatedList(response.data))
                .catch(error => console.error(error))
                .finally(() => setIsLoading(false));
        } else if (
            updatedFilters.action_id == null &&
            updatedFilters.region_id == null &&
            updatedFilters.country_id == null &&
            updatedFilters.states_id == null &&
            updatedFilters.city_id == null &&
            updatedFilters.year == null
        ) {
            // If all filters are cleared, fetch default blog data
            API.get(`/blog`, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then(response => props.getUpdatedList(response.data))
                .catch(error => console.error(error))
                .finally(() => setIsLoading(false));
        }
    };


    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Select
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'actionFilter' })}
                options={actionOptions}
                placeholder="Select Action"
                isClearable
            />
            <Select
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'regionFilter' })}
                options={regionOptions}
                placeholder="Select Region"
                isClearable
            />
            <Select
                ref={countrySelectRef}
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'countryFilter' })}
                options={countryOptions}
                placeholder="Select Country"
                isClearable
            />
            <Select
                ref={statesSelectRef}
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'statesFilter' })}
                options={statesOptions}
                placeholder="Select State"
                isClearable
            />
            <Select
                ref={citySelectRef}
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'cityFilter' })}
                options={cityOptions}
                placeholder="Select city"
                isClearable
            />
            <Select
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'yearFilter' })}
                options={yearOptions}
                placeholder="Select Year"
                isClearable
            />
        </>
    );
};

export default memo(DataFilter);
