import React, { useState, useEffect } from "react";
import StylesCustom from '../CreateOrganization/createOrganization.module.css'
import axios from "axios";
import Select from "react-select";
import { 
  Button, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  useDisclosure, 
  Spinner,
  Box, 
  Tooltip,
  IconButton
} from "@chakra-ui/react";
import Styles from "./addtheme.module.css";
import API from "../../../../../Services/API";
import LoaderSpinner from "../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ErrorWidget from "../../../../../Components/Widgets/ErrorWidget/ErrorWidget";
import { DeleteIcon, SunIcon, MoonIcon, SettingsIcon } from '@chakra-ui/icons';

const AddTheme = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [themeOptions, setThemeOptions] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [selectedThemeId, setSelectedThemeId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

  // Fetch themes from API when modal opens
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      API.get("/themes",{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then((response) => {
        const options = response.data.map((theme) => ({
            value: theme.id,
            label: theme.name
        }));
        setThemeOptions(options);
        setLoading(false);
    }).catch((error) => {
        console.error("Error fetching themes:", error);
        setLoading(false);
    });
    }
  }, [isOpen]);

  const handleThemeChange = (selectedOption) => {
    setSelectedTheme(selectedOption);
    setSelectedThemeId(selectedOption.value)
    console.log("Selected Theme:", selectedOption);
  };

  const onSave =()=>{
    setLoading(true);
    const body = {
        "organisation": props.id,
        "theme": selectedThemeId
    }
    API.post(`/organisations-themes`, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response=>{
        props.getUpdate()
        setLoading(false);
        onClose()
        setMessage("Added theme successfully")
    }).catch(error=>{
        setMessage("Failed to add theme")
        setLoading(false);
    })
  }

  return (
    <>
        {loading && <LoaderSpinner />}
        {message && <ErrorWidget message={message} />}
        {/* <Button colorScheme="blue" onClick={onOpen} m={'5px'} className={Styles.selectButton}>Select Theme</Button> */}
        <Tooltip label={`Add theme to ${props.name}`} aria-label={`Add theme to ${props.name}`}>
            <IconButton
                variant="solid"
                aria-label={`Add theme to ${props.name}`}
                fontSize="15px"
                icon={<SettingsIcon />}  // Change the icon here if needed
                className={StylesCustom.edit}
                onClick={onOpen}
                ml={'-20px'}
            />
        </Tooltip>


        <Box className={Styles.container}>
            {/* Button to Open Modal */}
            
            <div className="clearfix"></div>

            {/* Modal Dialog */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Select Theme</ModalHeader>
                <ModalBody>
                    {loading ? (
                    <Spinner size="lg" />
                    ) : (
                    <Select 
                        options={themeOptions} 
                        value={selectedTheme} 
                        onChange={handleThemeChange} 
                        placeholder="Choose a theme..."
                    />
                    )}
                </ModalBody>
                <ModalFooter backgroundColor={'transparent'}>
                    <Button onClick={onClose}>Close</Button>
                    <Button colorScheme="blue" onClick={onSave} ml={'10px'}>Save</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    </>
    
  );
};

export default AddTheme;
