import React, { useState, useEffect } from "react";
import API from "../../../../../../Services/API";
import Styles from "./regionmap.module.css";
import { useParams } from "react-router-dom";
import {Box,Button,Card,CardBody,Tooltip,FormControl,} from "@chakra-ui/react";
import {ComposableMap,Geographies,Geography,ZoomableGroup,} from "react-simple-maps";
import worldData from "./world.geojson";
import Select from "react-select";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { FaGlobe } from "react-icons/fa";
import FullScreenLoader from "../../../../../../Components/ProjectModule/GeographicImpact/GeographicImpact";

const RegionMap = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const { id } = useParams();
  const defaultScale = 130;
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [regionData, setRegionData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleZoomIn = () => setZoom(zoom * 1.5);
  const handleZoomOut = () => zoom >= 1.5 && setZoom(zoom / 1.5);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await API.get("/region", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setRegionData(response.data || []);
      } catch (error) {
        console.error("Error fetching region data:", error);
      }
    };

    fetchRegions();
  }, [token]);

  const fetchGeographicalData = async (queryParams, setData) => {
    try {
      const response = await API.get(`/get-geographical-data?${queryParams}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    }
  };

  const handleChangeRegion = (selectedOption) => {
    setSelectedRegion(selectedOption);
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    fetchGeographicalData(`region=${selectedOption?.value}`, setCountryData);
    setStateData([]);
    setCityData([]);
  };

  const handleChangeCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(null);
    setSelectedCity(null);
    fetchGeographicalData(`country=${selectedOption?.value}`, setStateData);
    setCityData([]);
  };

  const handleChangeState = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
    fetchGeographicalData(`state=${selectedOption?.value}`, setCityData);
  };

  const handleChangeCity = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handleSelectLocation = () => {
    const location = {
      "region_id": selectedRegion?.value || null,
      "region_name": selectedRegion?.label || "",
      "country_id": selectedCountry?.value || null,
      "country_name": selectedCountry?.label || "",
      "state_id": selectedState?.value || null,
      "state_name": selectedState?.label || "",
      "city_id": selectedCity?.value || null,
      "city_name": selectedCity?.label || "",
    }
    console.log("Selected Location:", location);
    props.getNewGeographies(location);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Card backgroundColor="rgb(201 210 211)" p="0px">
        <CardBody p="0px">
          <Box className={Styles.zoomControls}>
            <button className={Styles.zoomIn} onClick={handleZoomIn}>
              <AddIcon w={3} h={3} />
            </button>
            <button className={Styles.zoomOut} onClick={handleZoomOut}>
              <MinusIcon w={3} h={3} />
            </button>
          </Box>
          <Box className={Styles.filterDropDown}>
            <FormControl mb={"10px"}>
              <Select
                //options={regionData.map((r) => ({ value: r.id, label: r.region_name }))}
                options={regionData.map((r) => ({ value: r.id, label: r.region_name })).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={handleChangeRegion}
                value={selectedRegion}
                placeholder="Select Region"
              />
            </FormControl>
            <FormControl mb={"10px"}>
              <Select
                options={countryData.map((c) => ({ value: c.id, label: c.country_name })).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={handleChangeCountry}
                value={selectedCountry}
                placeholder="Select Country"
                isDisabled={!selectedRegion}
              />
            </FormControl>
            <FormControl mb={"10px"}>
              <Select
                options={stateData.map((s) => ({ value: s.id, label: s.state_name })).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={handleChangeState}
                value={selectedState}
                placeholder="Select State"
                isDisabled={!selectedCountry}
              />
            </FormControl>
            <FormControl mb={"10px"}>
              <Select
                options={cityData.map((c) => ({ value: c.id, label: c.city_name })).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={handleChangeCity}
                value={selectedCity}
                placeholder="Select City"
                isDisabled={!selectedState}
              />
            </FormControl>

            <Button mt={2} width="100%" backgroundColor="black" color="white" leftIcon={<FaGlobe />} onClick={handleSelectLocation}>
              Select This Location
            </Button>
          </Box>

          <Box className={Styles.mapContainer} style={{ overflow: "hidden" }}>
            <ComposableMap style={{ width: "100%", height: "auto" }} projection="geoMercator" projectionConfig={{ scale: defaultScale * zoom, center: [10, 10] }}>
              <ZoomableGroup>
                <Geographies geography={worldData}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const isRegionSelected = selectedRegion?.label && geo.properties.REGION === selectedRegion.label;
                      const isCountrySelected = selectedCountry?.label && geo.properties.ADMIN === selectedCountry.label;
                      const isStateSelected = selectedState?.label && geo.properties.ADMIN === selectedState.label;
                      const isCitySelected = selectedCity?.label && geo.properties.ADMIN === selectedCity.label;

                      const isSelected = isRegionSelected || isCountrySelected || isStateSelected || isCitySelected;

                      return (
                        <Tooltip key={geo.rsmKey} label={geo.properties.ADMIN || geo.properties.REGION}>
                          <Geography
                            geography={geo}
                            fill={isSelected ? "#00a0da" : "#ffffff"}
                            stroke={isSelected ? "#00a0da" : "#f7f7f7"}
                            strokeWidth={isSelected ? 2 : 0.5}
                          />
                        </Tooltip>
                      );
                    })
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default RegionMap;
