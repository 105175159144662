import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import Styles from '../aspirations.module.css';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useDisclosure, Button, Tooltip, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, SimpleGrid } from '@chakra-ui/react';
import { EditIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import API from '../../../../../../Services/API';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Widgets/ErrorWidget/ErrorWidget';

const EditMetricData = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const { metricId } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const [allow, setAllow] = useState(false);
    const [selectedMetric, setSelectedMetric] = useState(props.metricName);
    const [selectedPeriod, setSelectedPeriod] = useState(props.period);
    const [selectedPeriodName, setSelectedPeriodName] = useState(props.periodName);
    const [selectedFyq, setSelectedFyq] = useState(props.fyq);
    const [selectedFyqName, setSelectedFyqName] = useState(props.fyqName);
    const [selectedStatus, setSelectedStatus] = useState(props.status);
    const [selectedApproval, setSelectedApproval] = useState(props.approvalStatus);
    const [selectedRegion, setSelectedRegion] = useState(props.region);
    const [selectedCountry, setSelectedCountry] = useState(props.country);
    const [selectedState, setSelectedState] = useState(props.state);
    const [selectedCity, setSelectedCity] = useState(props.city);
    const [qtrListUp, setQtrListUp] = useState([]);
    const [strategy, setStrategy] = useState(props.strategy);
    const [strategyName, setStrategyName] = useState(props.strategyName);
    const [message, setMessage] = useState(null)
    const [user, setUser] = useState({})
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setcities] = useState([]);
    const [defaultValues, setDefaultValues] = useState({
        periodName: props.periodName,
        metricName: props.metricName,
        period: props.period,
        fyq: props.fyq,
        status: props.status,
        approvalStatus: props.approvalStatus,
        geography: props.geography,
        region: props.region,
        country: props.country,
        state: props.state,
        city: props.city
    });


    const handleSave = async () => {
        setMessage(null);
        const updatedValue = document.getElementById("updateValue")?.value || props.value;
        const hasChanges =
            parseInt(updatedValue) !== parseInt(props.value) ||
            selectedPeriod !== props.period ||
            selectedFyq !== props.fyq ||
            selectedStatus !== props.status ||
            selectedRegion !== props.region ||
            selectedCountry !== props.country ||
            selectedState !== props.state ||
            selectedCity !== props.city ||
            strategy !== props.strategy;
        if (!hasChanges) {
            setMessage("No changes are made");
            return;
        }

        try {
            setIsLoading(true);
            const body = {
                id: parseInt(props.id),
                geography: selectedCountry ? null : null,
                region: selectedRegion ? selectedRegion : null,
                country: selectedCountry ? selectedCountry : null,
                state: selectedState ? selectedState : null,
                city: selectedCity ? selectedCity : null,
                name: selectedMetric,
                value: parseInt(updatedValue),
                ...(selectedPeriod && {
                    fiscal_year: parseInt(selectedPeriod),
                    fiscal_year_name: selectedPeriodName
                }),
                ...(selectedFyq && {
                    fiscal_quarter: parseInt(selectedFyq),
                    fiscal_quarter_name: selectedFyqName
                }),
                ...(selectedStatus && { metric_status: selectedStatus }),
                ...(strategy && { strategy }),
                ...(strategyName && { strategy_name: strategyName }),
                approval_status: user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner' ? "approved" : "pending",
                position: user.superuser ? 'superuser'
                    : user.action_office_user ? 'action_office_user'
                        : user.comms_user ? 'comms_user'
                            : user.elt_user ? 'elt_user'
                                : user.position === 'pillar_owner' ? 'pillar_owner'
                                    : user.position === 'pillar_contributor' ? 'pillar_contributor'
                                        : user.position === 'pillar_member' ? 'pillar_member'
                                            : user.position === 'action_owner' ? 'action_owner'
                                                : user.position === 'action_contributor' ? 'action_contributor'
                                                    : user.position === 'action_member' ? 'action_member' : '',
                position_of_user: (user.position === 'pillar_owner' || user.position === 'action_owner') ? 'owner'
                    : (user.position === 'action_contributor' || user.position === 'pillar_contributor') ? 'contributor' : ''
            };
            await API.put(`/metric-data`, body, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner') {
                setMessage('Thank you! Please refresh the screen to view the updated data.');
            } else {
                setMessage('Thank you! Your data has been successfully submitted for approval.');
            }
            const response = await API.get(`/metric-data?metric_id=${metricId}`);
            props.getUpdatedMetricData(response.data);
            onClose();
            setAllow(false)
        } catch (error) {
            setMessage(error.response?.data?.message || "An error occurred during the update");
        } finally {
            setIsLoading(false);
        }
    };
    // Prepare fiscal year options
    const fiscalYearDetail = props.fiscalYearDetail?.map(item => ({
        value: item.id,
        label: item.name
    })).sort((a, b) => parseInt(b.label.replace('FY', '')) - parseInt(a.label.replace('FY', '')));

    // Prepare fiscal quarter options
    const fiscalYearQtrDetail = props.fiscalYearQtrDetail?.map(item => ({
        value: item.id,
        label: item.name
    }));

    const regionCode = props.region
    const countryCode = props.country
    const stateCode = props.state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = [
                    defaultValues?.region || regionCode ? API.get(`/get-geographical-data?region=${defaultValues?.region || regionCode}`, { headers: { Authorization: `Bearer ${token}` } }) : null,
                    defaultValues?.country || countryCode ? API.get(`/get-geographical-data?country=${defaultValues?.country || countryCode}`, { headers: { Authorization: `Bearer ${token}` } }) : null,
                    defaultValues?.state || stateCode ? API.get(`/get-geographical-data?state=${defaultValues?.state || stateCode}`, { headers: { Authorization: `Bearer ${token}` } }) : null
                ].filter(Boolean); // Remove null values
    
                const [countries, states, cities] = await Promise.all(requests);
                
                if (countries) setCountries(countries.data);
                if (states) setStates(states.data);
                if (cities) setcities(cities.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [token, allow, isOpen]);
    

    // Prepare country options
    const modiCountry = countries ? countries : props.countries


    const regionsList = props?.regions && props?.regions?.map(region => ({
        value: region?.id,
        label: region?.region_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));
    const countriesList = countries && countries?.map(region => ({
        value: region?.id,
        label: region?.country_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));
    const statesList = states && states?.map(region => ({
        value: region?.id,
        label: region?.state_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));
    const citiesList = cities && cities?.map(region => ({
        value: region?.id,
        label: region?.city_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));

    const strategiesList = props.strategies?.map(item => ({
        value: item.id,
        label: item.name
    }))

    // Update quarters dynamically when Fiscal Year changes
    const updateQuarterList = (periodName) => {
        const selectedOptionNew = `FY${periodName.slice(-2)}`;

        const relatedQuarters = fiscalYearQtrDetail?.filter(item => item.label.includes(selectedOptionNew))
            .map(item => {
                const quarterLabel = item.label.split(' ')[1];
                const quarter = quarterLabel ? parseInt(quarterLabel.replace('Q', ''), 10) : null;
                return {
                    ...item,
                    quarter,
                };
            })
            .filter(item => item.quarter !== null)  // Remove items where quarter is invalid
            .sort((a, b) => b.quarter - a.quarter);

        if (JSON.stringify(relatedQuarters) !== JSON.stringify(qtrListUp)) {
            setQtrListUp(relatedQuarters || []);
        }
    };

    const countryRef = useRef(null)
    const stateRef = useRef(null)
    const cityRef = useRef(null)

    // Reset to default values when modal is opened
    const handleOpen = () => {
        setAllow(true)
        setSelectedPeriodName(defaultValues.periodName);
        setSelectedFyqName(defaultValues.fyqName)
        setSelectedMetric(defaultValues.metricName);
        setSelectedPeriod(defaultValues.period);
        setSelectedFyq(defaultValues.fyq);
        setSelectedStatus(defaultValues.status);
        setSelectedApproval(defaultValues.approvalStatus);
        setSelectedCountry(defaultValues.geography);
        setSelectedRegion(defaultValues.region);
        setSelectedCountry(defaultValues.country);
        setSelectedState(defaultValues.state);
        setSelectedCity(defaultValues.city);
        updateQuarterList(defaultValues.periodName);

        // Call the API when the modal opens
        API.get(`/get-specific-modules?metric_id=${metricId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                setUser(response.data); // Set the response data into state
            })
            .catch(error => {
                setIsLoading(false); // Handle error, maybe set a message
            });

        onOpen();
    };

    // Handle period (FY) change
    const changePeriod = (event) => {
        setSelectedPeriod(event.value); // Update Fiscal Year
        setSelectedPeriodName(event.label);
        setSelectedFyq(null); // Reset Fiscal Quarter
        updateQuarterList(event.label); // Update quarters based on the new Fiscal Year
    };

    // Handle fiscal quarter (FQ) change
    const changeFyq = (event) => {
        setSelectedFyq(event.value);
        setSelectedFyqName(event.label)
    };

    const statusOptions = [
        { value: 'on-track', label: 'On-track' },
        { value: 'delayed', label: 'Delayed' },
        { value: 'complete', label: 'Complete' },
    ];
    const changeStatus = (event) => {
        setSelectedStatus(event.value);
    };
    console.log("props", props?.country, props?.state, props?.region, props?.city, isOpen)
    const changeCountry = (event) => {
        if (event === null || event === undefined) {
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setStates([])
            setcities([])
            setSelectedCountry(null)
            setSelectedState(null)
            setSelectedCity(null)
            return
        }
        if (event?.value) {
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setStates([])
            setcities([])
            setSelectedState(null)
            setSelectedCity(null)
            setIsLoading(true)
            setSelectedCountry(event?.value);
            API.get(`/get-geographical-data?country=${event?.value}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                setStates(res.data)
                setIsLoading(false)
            })
        }
    };
    const changeRegion = (event) => {
        if (event === null || event === undefined) {
            if (countryRef.current) countryRef.current.clearValue();
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setCountries([])
            setStates([])
            setcities([])
            setSelectedRegion(null)
            setSelectedCountry(null)
            setSelectedState(null)
            setSelectedCity(null)
            return
        }
        if (event?.value) {
            if (countryRef.current) countryRef.current.clearValue();
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setCountries([])
            setStates([])
            setcities([])
            setSelectedCountry(null)
            setSelectedState(null)
            setSelectedCity(null)
          
            console.log("event", event)
            setIsLoading(true)
            setSelectedRegion(event?.value);
            API.get(`/get-geographical-data?region=${event?.value}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
            setCountries(res.data)
            setIsLoading(false)
        })
    }
    };
    const changeState = (event) => {
        if (event === null || event === undefined) {
            if (cityRef.current) cityRef.current.clearValue();
            setSelectedState(null)
            setSelectedCity(null)
            setcities([])
            return
        }
        if (event?.value) {
            if (cityRef.current) cityRef.current.clearValue();
            setSelectedCity(null)
            setcities([])
            setIsLoading(true)
            setSelectedState(event?.value);
            API.get(`/get-geographical-data?state=${event?.value}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                setcities(res.data)
                setIsLoading(false)
            })
        };
    }
    const changeCity = (event) => {
        if (event === null || event === undefined) {
            setSelectedCity(null);
            return;
        }
        setSelectedCity(event?.value);
    };
console.log("payload",selectedRegion,selectedCountry,selectedState,selectedCity)
    const changeStrategy = (event) => {
        setStrategy(event?.value);
        setStrategyName(event?.label)
    };
    const sortedFiscalYearList = fiscalYearDetail && fiscalYearDetail.sort((a, b) => {
        const getFiscalYear = (label) => {
            const match = label.match(/FY(\d+)/);
            return match ? parseInt(match[1], 10) : -1;
        };
        const getQuarter = (label) => {
            const match = label.match(/Q(\d+)/);
            return match ? parseInt(match[1], 10) : 0; // Default 0 for no quarter
        };
        const fiscalYearDiff = getFiscalYear(b.label) - getFiscalYear(a.label);
        if (fiscalYearDiff !== 0) return fiscalYearDiff;
        return getQuarter(b.label) - getQuarter(a.label);
    });

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
            {
                message ? <ErrorWidget message={message} /> : null
            }
            <Tooltip id={props.id} label="Edit Metric" aria-label="Edit Metric">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    fontSize="15px"
                    icon={<EditIcon />}
                    className={Styles.edit}
                    id={props.id}
                    onClick={handleOpen}
                    minW={'25px'}
                    minH={'25px'}
                    width={'25px'}
                    height={'25px'}
                    mr={'5px'}
                />
            </Tooltip>
            <Modal isOpen={isOpen} onClose={()=>{onClose()
                setAllow(false)
            }}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Edit Metric Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={[1, 2]} spacing={4}>
                            <FormControl id="metric-name">
                                <FormLabel fontWeight={'bold'}>Metric Name</FormLabel>
                                <Input
                                    value={selectedMetric}
                                    isDisabled
                                    aria-label="Metric Name"
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Value</FormLabel>
                                <Input id="updateValue" defaultValue={props.value} />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight={'bold'}>Period (FY)</FormLabel>
                                <Select
                                    options={fiscalYearDetail}
                                    defaultValue={fiscalYearDetail && fiscalYearDetail.find(option => option.label === selectedPeriodName)}
                                    onChange={changePeriod}
                                    aria-label="Period Fiscal Year"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight={'bold'}>Period (FQ)</FormLabel>
                                <Select
                                    options={qtrListUp}
                                    value={qtrListUp?.find(option => option.value === selectedFyq) || null}
                                    onChange={changeFyq}
                                    aria-label="Period Fiscal Quarter"
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Status</FormLabel>
                                <Select
                                    options={statusOptions}
                                    onChange={changeStatus}
                                    defaultValue={statusOptions && statusOptions.find(option => option.value === selectedStatus)}
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Region</FormLabel>
                                <Select
                                    options={regionsList}
                                    onChange={changeRegion}
                                    placeholder="Select region.."
                                    defaultValue={
                                        regionsList.find(option => option.value === props.region)
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Country</FormLabel>
                                <Select
                                    ref={countryRef}
                                    options={countriesList}
                                    placeholder="Select Country.."
                                    onChange={changeCountry}
                                    defaultValue={
                                        countriesList.find(option => option.value === props.country)
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>State</FormLabel>
                                <Select
                                    ref={stateRef}
                                    options={statesList}
                                    placeholder="Select State.."

                                    onChange={changeState}
                                    defaultValue={
                                        statesList.find(option => option.value === props.state )
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>City</FormLabel>
                                <Select
                                    ref={cityRef}
                                    placeholder="Select City.."
                                    options={citiesList}
                                    onChange={changeCity}
                                    defaultValue={
                                        citiesList.find(option => option.value === props.city )
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Strategy</FormLabel>
                                <Select
                                    options={strategiesList}
                                    onChange={changeStrategy}
                                    defaultValue={strategiesList && strategiesList.find(option => option.label === strategyName)}
                                    isDisabled
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button variant="ghost" onClick={()=>{onClose()
                            setAllow(false)
                        }}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={handleSave} isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditMetricData;
