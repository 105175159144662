import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Style1 from './viewImpact.module.css'
import Select from 'react-select';
import $ from 'jquery'
import { useParams } from 'react-router-dom'
import Styles from '../impactmetrics.module.css'
import API from '../../../../../../Services/API'
import { Heading, SimpleGrid, Box, Stack, StackDivider, Card, CardBody, Image, Text, Input, Button, FormControl, FormLabel } from '@chakra-ui/react'
import { EditContext } from "../../../../../../Context/BreadcrumbsContext";
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import { set } from 'date-fns';

const ViewMetric = () => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [metric, setMetric] = useState();
  const [fiscalYearList, setFiscalYearList] = useState([])
  const [fiscalQtrList, setFiscalQtrList] = useState()
  const [metricData, setMetricData] = useState()
  const [isMetricDataAvail, setisMetricDataAvail] = useState(false)
  const { edit, setEdit } = useContext(EditContext);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('#ffffff');
  const [regions, setRegions] = useState(false)
  const [countries, setCountries] = useState(false)
  const [states, setstates] = useState(false)
  const [cities, setCities] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [validateMessage, setValidateMessage] = useState(null)
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
  const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
  const [getQtrList, setGetQtrList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const countrySelectRef = useRef(null);
  const statesSelectRef = useRef(null);
  const citySelectRef = useRef(null);


  const [options] = useState([
    { value: '#E2E2E2', label: 'Light Gray' },
    { value: '#CDEBF9', label: 'Sky Blue Tint' },
    { value: '#0D274D', label: 'Midnight Blue' },
    { value: '#00BCEB', label: 'Sky Blue' },
    { value: '#0051AF', label: 'Medium Blue' },
    { value: '#E3241B', label: 'Red' },
    { value: '#FBAB2C', label: 'Orange' },
    { value: '#7FBF4B', label: 'Green' },
    { value: '#414344', label: 'Dark Gray' },
  ]);

  function bannerUpload(e) {
    setFile(e.target.files[0]);
  }

  const fetchCountryList = async (id) => {
    try {
      setIsLoading(true);
      const response = await API.get(`/get-geographical-data?region=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response?.data) {
        const sortedData = response.data.sort((a, b) =>
          a.country_name.localeCompare(b.country_name)
        );
        setCountries(sortedData);
      }
    } catch (error) {
      console.error("❌ Failed to fetch country data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStateList = async (id) => {
    try {
      setIsLoading(true);
      const response = await API.get(`/get-geographical-data?country=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response?.data) {
        const sortedData = response.data.sort((a, b) =>
          a.state_name.localeCompare(b.state_name)
        );
        setstates(sortedData);
      }
    } catch (error) {
      console.error("❌ Failed to fetch state data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCityList = async (id) => {
    try {
      setIsLoading(true);
      const response = await API.get(`/get-geographical-data?state=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response?.data) {
        const sortedData = response.data.sort((a, b) =>
          a.city_name.localeCompare(b.city_name)
        );
        setCities(sortedData);
      }
    } catch (error) {
      console.error("❌ Failed to fetch city data:", error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    setIsLoading(true);

    API.get('/fiscal-year-detail', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const yearList = response.data[0].fiscalyear.map((item) => ({ value: item.id, label: item.name }));
        const quarterList = response.data[1].fiscalquarter.map((item) => ({ value: item.id, label: item.name }));
        setFiscalYearList(yearList);
        setFiscalQtrList(quarterList);
      })
      .catch((error) => {
        console.error('Failed to fetch fiscal data:', error);
      }).finally(() => setIsLoading(false));

    const fetchRegionList = () =>
      API.get(`/region/`, {
        headers: { 'Authorization': 'Bearer ' + token }
      }).then(response => setRegions(response.data));

    const fetchMetric = () =>
      API.get(`/get-metric?metric_id=${id}`)
        .then(response => {
          setMetric(response.data[0]);
          setColor(response.data[0]?.color_picker);
          setSelectedTags(response.data[0].metric_tag || []);
        });
    const fetchMetricData = () =>
      API.get(`/metric-data?metric_id=${id}`)
        .then(response => {
          setMetricData(response.data[0]);
          const fiscalYearName = response.data[0]?.fiscal_year_name;
          const fiscalYearCode = `FY${fiscalYearName.slice(-2)}`;
          if (response.data[0]?.region) fetchCountryList(response.data[0]?.region);
          if (response.data[0]?.country) fetchStateList(response.data[0]?.country);

          if (response.data[0]?.state) fetchCityList(response.data[0]?.state);
         
          return fetchFiscalYearDetails(fiscalYearName, response.data[0]?.fiscal_quarter_name, fiscalYearCode);
        });

          
    const fetchFiscalYearDetails = (fiscalYearName, fiscalQuarterName, fiscalYearCode) =>
      API.get(`/fiscal-year-detail`, {
        headers: { 'Authorization': 'Bearer ' + token }
      }).then(response => {
        const yearList = response.data[0].fiscalyear.map(item => ({ value: item.id, label: item.name }));
        const quarterList = response.data[1].fiscalquarter.map(item => ({ value: item.id, label: item.name }));
        setFiscalYearList(yearList);
        setFiscalQtrList(quarterList);

        const defaultYear = yearList.find(year => year.label === fiscalYearName);
        const defaultQuarter = quarterList.find(qtr => qtr.label === fiscalQuarterName);
        setSelectedFiscalYear(defaultYear || null);
        setSelectedFiscalQtr(defaultQuarter || null);

        const relatedQuarters = quarterList
          .filter(item => item.label.includes(fiscalYearCode))
          .sort((a, b) => {
            const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
            const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
            return quarterB - quarterA;
          });

        setGetQtrList(relatedQuarters);
      });

    const fetchMetricDataEntries = () =>
      API.get(`/metric-data-entries?id=${id}`)
        .then(response => setisMetricDataAvail(response.data.status));

    Promise.all([fetchRegionList(), fetchMetric(), fetchMetricData(), fetchMetricDataEntries()])
      .finally(() => setIsLoading(false))
      .catch(error => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });

    const fetchTagOptions = async () => {
      try {
        const response = await API.get('/tag-detail/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTagOptions(
          response.data.results.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))
        );
      } catch (error) {
        console.error('Error fetching tag options:', error);
      }
    };

    fetchTagOptions();
  }, [id, token]);

  const updateMetricData = (method) => {
    const body = {
      "metric": id,
      "id": metricData?.id,
      "name": $("#impactName").val(),
      "approval_status": "approved",
      "metric_status": "on-track",
      "fiscal_year": parseInt(selectedFiscalYear && selectedFiscalYear.value),
      "fiscal_quarter": parseInt(selectedFiscalQtr && selectedFiscalQtr.value),
      "region": parseInt(selectedRegion && selectedRegion.value),
      "country": parseInt(selectedCountry && selectedCountry.value),
      "state": parseInt(selectedState && selectedState.value),
      "city": parseInt(selectedCity && selectedCity.value),
    }
    if (method) {
      API.put(`/metric-data`, body, {
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .then(response => {
          API.get(`/metric-data?metric_id=${id}`)
            .then((response) => {
              setMetricData(response.data[0])
            }).catch(error => {

            })
        })
        .catch(err => {
        });
    } else {
      API.post(`/metric-data`, body, {
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .then(response => {
          API.get(`/metric-data?metric_id=${id}`)
            .then((response) => {
              setMetricData(response.data[0])
            }).catch(error => {
            })
        }).catch(err => {

        })
    }
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const updateMetric = () => {
    if (!selectedFiscalYear) {
      setSuccessMessage("Please select a fiscal year.");
      setIsLoading(false);
      return;
    }
    if (metricData?.metric_name !== $('#impactName').val() ||
      metric?.key_fact !== $('#keyfact').val() ||
      (metricData?.fiscal_year !== (selectedFiscalYear && selectedFiscalYear?.value)) ||
      (metricData?.fiscal_quarter !== (selectedFiscalQtr && selectedFiscalQtr?.value)) ||
      (metricData?.geography !== (selectedRegion && selectedRegion?.value)) ||
      metric?.color_picker !== color ||
      file === null
    ) {
      setIsLoading(true)
      setSuccessMessage(null)
      if (isMetricDataAvail) {
        updateMetricData(true)
      } else {
        updateMetricData(false)
      }
      const tagIds = selectedTags.map((tag) => tag.id);
      const formData = new FormData()
      if (file != null) {
        formData.append('image', file)
      }
      formData.append('name', $('#impactName').val())
      formData.append('key_fact', $('#keyfact').val())
      formData.append('color_picker', color)
      formData.append('id', id)
      formData.append('metric_tag', JSON.stringify(tagIds));
      API.put(`/metric`, formData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        API.get(`/get-metric?metric_id=${id}`)
          .then((response) => {
            setMetric(response.data[0])
            setColor(response.data[0]?.color_picker);
            setIsLoading(false)
            setSuccessMessage('Changes updated successfully')
          }).catch(error => {
            setIsLoading(false)
          })
      }).catch(error => {
        setIsLoading(false)
      })
    } else {

    }
  }

  const handleFiscalYearChange = (selectedOption) => {
    setSelectedFiscalYear(selectedOption);
    setSelectedFiscalQtr(null);
    if (selectedOption) { // Check if selectedOption is not null
      const SelectedOptionNew = `FY${selectedOption.label.slice(-2)}`;
      let relatedQuarters = fiscalQtrList.filter(item => item.label.includes(SelectedOptionNew));
      relatedQuarters = relatedQuarters.sort((a, b) => {
        const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
        const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
        return quarterB - quarterA;
      });

      setGetQtrList(relatedQuarters);

      if (selectedOption.label === metricData?.fiscal_year_name) {
        const defaultQtr = fiscalQtrList.find(qtr => qtr.label === metricData?.fiscal_quarter_name);
        setSelectedFiscalQtr(defaultQtr || null);
      }
    } else {
      setGetQtrList([]);
    }
  };

  const handleFiscalQtrChange = (selectedOption) => {
    setSelectedFiscalQtr(selectedOption);
  };


  const sortedFiscalYearList = fiscalYearList.sort((a, b) => {
    const getFiscalYear = (label) => {
      const match = label.match(/FY(\d+)/);
      return match ? parseInt(match[1], 10) : -1;
    };
    const getQuarter = (label) => {
      const match = label.match(/Q(\d+)/);
      return match ? parseInt(match[1], 10) : 0; // Default 0 for no quarter
    };
    const fiscalYearDiff = getFiscalYear(b.label) - getFiscalYear(a.label);
    if (fiscalYearDiff !== 0) return fiscalYearDiff;
    return getQuarter(b.label) - getQuarter(a.label);
  });

  const regionOptions = regions && regions.map(country => ({
    value: country?.id,       // Use `id` or any unique identifier as the value
    label: country?.region_name      // Use `name` as the label for displaying country names
  }));
  const defaultregion = regionOptions && regionOptions.find(
    option => option.label === metricData?.region_name
  );
  const countryOptions = countries && countries.map(country => ({
    value: country?.id,       // Use `id` or any unique identifier as the value
    label: country?.country_name      // Use `name` as the label for displaying country names
  }));
  const defaultCountry = countryOptions && countryOptions.find(
    option => option.label === metricData?.country_name
  );
  const stateOptions = states && states.map(country => ({
    value: country?.id,       // Use `id` or any unique identifier as the value
    label: country?.state_name      // Use `name` as the label for displaying country names
  }));
  const defaultstate = stateOptions && stateOptions.find(
    option => option.label === metricData?.state_name
  );
  const cityOptions = cities && cities.map(country => ({
    value: country?.id,       // Use `id` or any unique identifier as the value
    label: country?.city_name      // Use `name` as the label for displaying country names
  }));
  const defaultCity = cityOptions && cityOptions.find(
    option => option.label === metricData?.city_name
  );

  const handleSelectChange = (selectedOption, filter) => {
    console.log("option", selectedOption, filter)

    if (filter === "region") {
      if (selectedOption === null) {
        if (countrySelectRef.current) countrySelectRef.current?.clearValue();
        if (statesSelectRef.current) statesSelectRef.current?.clearValue();
        if (citySelectRef.current) citySelectRef.current?.clearValue();
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
        setCountries([]); // Clear country list
        setstates([]); // Clear state list    
        setCities([]); // Clear city list      

        return
      }
      setSelectedRegion(selectedOption);
      fetchCountryList(selectedOption.value);

      setCountries([]); // Clear country list
      setstates([]); // Clear state list    
      setCities([]); // Clear city list      
      if (countrySelectRef.current) countrySelectRef.current?.clearValue();
      if (statesSelectRef.current) statesSelectRef.current?.clearValue();
      if (citySelectRef.current) citySelectRef.current?.clearValue();
    }
    if (filter === "country") {
      if (selectedOption === null) {
        if (statesSelectRef.current) statesSelectRef.current?.clearValue();
        if (citySelectRef.current) citySelectRef.current?.clearValue();
        setSelectedState(null)
        setSelectedCity(null)
        setstates([]); // Clear state list    
        setCities([]); // Clear city list      

        return
      }
      setSelectedCountry(selectedOption);
      fetchStateList(selectedOption.value);

      setstates([]); // Clear state list    
      setCities([]); // Clear city list     
      if (statesSelectRef.current) statesSelectRef.current?.clearValue();
      if (citySelectRef.current) citySelectRef.current?.clearValue();
    }
    if (filter === "state") {
      if (selectedOption === null) {
        setSelectedCity(null)
        setCities([]);
        if (citySelectRef.current) citySelectRef.current?.clearValue();
        return
      }
      setSelectedState(selectedOption);
      fetchCityList(selectedOption.value);
      setSelectedCity(null)
      setCities([]);

      if (citySelectRef.current) citySelectRef.current?.clearValue();
    }
    if (filter === "city") {
      setSelectedCity(selectedOption);
    }
  };

  const colorOptionLabel = (props) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: props.value,
            marginRight: 10,
          }}
        />
        {props.value} ({props.label})
      </div>
    );
  };

  const selectedTagValues = selectedTags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  const handleChange = (selected) => {
    const updatedSelectedTags = selected
      ? selected.map((item) => ({
        id: item.value,
        name: item.label,
      }))
      : [];
    setSelectedTags(updatedSelectedTags);
  };

  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}


      {
        successMessage !== null ? <ErrorWidget message={successMessage} /> : null
      }
      {
        errorMessage !== null ? <ErrorWidget message={errorMessage} /> : null
      }
      {
        validateMessage ? <ErrorWidget message={validateMessage} /> : null
      }

      {
        edit ?
          <Box>
            <FormControl>
              <FormLabel fontWeight={'bold'}> Key Fact </FormLabel>
              <FormControl>
                <Input defaultValue={metric && metric.key_fact} id='keyfact' />
              </FormControl>
            </FormControl>
          </Box>
          :
          <>
            <FormLabel fontWeight={'bold'}> Key Fact </FormLabel>
            <Heading mb={'20px'} className={Style1.Heading}>  {metric && metric.key_fact} </Heading>
          </>
      }
      <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'} mb={'20px'}>
        <Box>
          <Card>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading className={Style1.HeadingSub} textTransform='uppercase'>
                    Title
                  </Heading>
                  {
                    edit ?
                      <Box>
                        <FormControl>
                          <Input defaultValue={metric && metric.name} id='impactName' />
                        </FormControl>
                      </Box>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {capitalizeFirstLetter(metric && metric.name)}
                      </Text>
                  }
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Fiscal Year
                  </Heading>
                  {
                    edit ?
                      <FormControl>
                        <Select
                          id={'metricData_targetfiscalyear'}
                          options={sortedFiscalYearList}
                          value={selectedFiscalYear}
                          onChange={handleFiscalYearChange}
                          placeholder="Select fiscal year"
                        />
                      </FormControl>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {metricData?.fiscal_year_name}
                      </Text>
                  }

                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Fiscal Quarter
                  </Heading>
                  {
                    edit ?
                      <FormControl>
                        <Select
                          id={'metricData_targetfiscalquarter'}
                          options={getQtrList}
                          value={selectedFiscalQtr}
                          onChange={handleFiscalQtrChange}
                          placeholder={selectedFiscalYear ? "Select fiscal quarter" : "Select fiscal year first"}
                          isDisabled={!selectedFiscalYear} // Disable if no fiscal year is selected
                          isClearable // Enable clearable option
                        />
                      </FormControl>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {metricData?.fiscal_quarter_name}
                      </Text>
                  }
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Region
                  </Heading>
                  {
                    edit ?
                      <FormControl>
                        <Select

                          options={regionOptions}
                          onChange={(option) => handleSelectChange(option, "region")}
                          placeholder="Select a region"
                          isClearable
                          defaultValue={defaultregion}
                        />
                      </FormControl>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {metricData?.region_name}
                      </Text>
                  }
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Country
                  </Heading>
                  {
                    edit ?
                      <FormControl>
                        <Select
                          ref={countrySelectRef}
                          options={countryOptions}
                          onChange={(option) => handleSelectChange(option, "country")}
                          placeholder="Select a country"
                          isClearable
                          defaultValue={defaultCountry}
                        />
                      </FormControl>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {metricData?.country_name}
                      </Text>
                  }
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    State
                  </Heading>
                  {
                    edit ?
                      <FormControl>
                        <Select
                          ref={statesSelectRef}
                          options={stateOptions}
                          onChange={(option) => handleSelectChange(option, "state")}
                          placeholder="Select a state"
                          isClearable
                          defaultValue={defaultstate}
                        />
                      </FormControl>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {metricData?.state_name}
                      </Text>
                  }
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    City
                  </Heading>
                  {
                    edit ?
                      <FormControl>
                        <Select
                          ref={citySelectRef}
                          options={cityOptions}
                          onChange={(option) => handleSelectChange(option, "city")}
                          placeholder="Select a city"
                          isClearable
                          defaultValue={defaultCity}
                        />
                      </FormControl>
                      :
                      <Text pt='2' fontSize='sm' mb={'0'}>
                        {metricData?.city_name}
                      </Text>
                  }
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Box>
        <Box>
          <Card>
            <CardBody>
              <Box>
                <FormLabel fontWeight="bold">Select Tags</FormLabel>
                <Select
                  isMulti
                  options={tagOptions}
                  value={selectedTagValues}
                  onChange={handleChange}
                  isDisabled={!edit}
                />
              </Box>
              <FormLabel fontWeight={'bold'} mt={'10px'}>Background Colour</FormLabel>
              {
                edit ?
                  <FormControl>
                    <Select
                      options={options}
                      value={options.find(option => option.value === color)}
                      onChange={(e) => setColor(e.value)}
                      formatOptionLabel={colorOptionLabel} // Custom rendering
                    />

                    <Box w={'50px'} height={'50px'} backgroundColor={color} boxShadow={'1px 1px 11px #e5e5e5'} mb={'5px'}>  </Box>
                  </FormControl>
                  :
                  <Card>
                    <CardBody>
                      <Box w={'100%'} height={'200px'} backgroundColor={color}>  </Box>
                    </CardBody>
                  </Card>
              }

              {
                metric && metric.image ? <Image src={metric && metric.image} height={'179px'} alt='Card Image' /> : null
              }

              {edit ?
                <FormControl>
                  <FormLabel fontWeight={'bold'}>Card Image</FormLabel>
                  <Input type="file" onChange={bannerUpload} padding={"4px"} height={'179px'} />
                </FormControl> : null
              }
            </CardBody>
          </Card>
        </Box>
        {edit ? <Button className='buttonPrimary' onClick={updateMetric}> Save </Button> : null}
      </SimpleGrid>
    </>
  )
}

export default ViewMetric