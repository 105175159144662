import React, { useEffect, useState } from "react";
import Styles from "./ReportContent.module.css";
import { Box, Grid, Heading, Image, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import API from "../../../../../Services/API";
import FooterImg from '../../../../../../assets/images/cisco-white.png';
import NumberFormat from "../../../../../Components/Modules/NumberFormat/NumberFormat";
import parse from "html-react-parser";

const ReportContent = ({ id, getReportName, getMetricLength, getImpactLength }) => {
    const token = window.localStorage.getItem("accessToken") || "";
    const [report, setReport] = useState({});
    const [metrics, setMetrics] = useState([]);
    const [impactStories, setImpactStories] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        if (!id) return;
        API.get(`/custom-country-report?report_id=${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
            if (response.data.length > 0) {
                const data = response.data[0];

                setReport(data);
                setMetrics(data.metrics_data_tab || []);
                setImpactStories(data.impact_stories_tab || []);
                setStartDate(data.start_date || '');
                setEndDate(data.end_date || '');

                if (typeof getReportName === "function") getReportName(data.title || "");
                if (typeof getMetricLength === "function") getMetricLength((data.metrics_data_tab || []).length);
                if (typeof getImpactLength === "function") getImpactLength((data.impact_stories_tab || []).length);
            }
        })
        .catch((error) => console.error("Error fetching report:", error));
    }, [id, token]);

    // ✅ Function to group metrics properly by Region → Country → State → City
    const groupByRegionCountryStateCity = (data = []) => {
        return data.reduce((acc, item) => {
            const region = item.region || "";
            const country = item.country || "";
            const state = item.state || "";
            const city = item.city || "";
            const focusArea = item.focus_areas || "General";
    
            if (!acc[region]) acc[region] = {};
            if (!acc[region][country]) acc[region][country] = {};
            if (!acc[region][country][state]) acc[region][country][state] = {};
            if (!acc[region][country][state][city]) acc[region][country][state][city] = {};
            if (!acc[region][country][state][city][focusArea]) acc[region][country][state][city][focusArea] = [];
    
            acc[region][country][state][city][focusArea].push(item);
            return acc;
        }, {});
    };

    const groupedData = groupByRegionCountryStateCity(metrics);

    return (
        <Box id="pdfContainer">
            <Box id="pdf-content-0">
                <Box className={Styles.slideOne}>
                    <Box className={Styles.slideOneTitle} textAlign={'center'}>
                        <Text>SIIO Action Office Custom Report</Text>
                        <Text>{report.title || ""}</Text>
                        {startDate} To {endDate}
                    </Box>
                    <Box className={Styles.footer}>
                        <Image src={FooterImg} width="60px" float="left" />
                        <Text as="span" className={Styles.copy}>
                            © 2025 Cisco and/or its affiliates. All rights reserved. Cisco confidential.
                        </Text>
                    </Box>
                </Box>
            </Box>

            <Box id="pdf-content-1">
                <Box className={Styles.slideTwo}>
                    <Heading fontSize="18px" mb={4} className={Styles.slideTwoTitle}> Metrics Data </Heading>
                    {metrics.length === 0 && (
                        <Box className="errorMessage">
                            The assigned metrics do not have approved metric data in the selected date range.
                        </Box>
                    )}
                    {Object.entries(groupedData).map(([region, countries]) => (
                        Object.entries(countries).map(([country, states]) => (
                            Object.entries(states).map(([state, cities]) => (
                                Object.entries(cities).map(([city, focusAreas]) => (
                                    <Box key={`${region}-${country}-${state}-${city}`} mb={6}>
                                        {/* 🔹 Concatenated Heading */}
                                        <Text fontSize="18px" fontWeight="bold" mb={4}>
                                            {region} {country ? `: ${country}` : '' } {state ? `: ${state}` : '' }{city ? `: ${city}` : '' }
                                        </Text>

                                        {Object.entries(focusAreas).map(([focusArea, metrics]) => (
                                            <Box key={focusArea} mb={6}>
                                                {/* Focus Area Title */}
                                                <Text fontSize="16px" fontWeight="semibold" color="blue.600" mt={2}>
                                                    {metrics.length > 0 && `${metrics[0]?.pillars || ""}`} : {focusArea}
                                                </Text>

                                                {/* Metrics Grid */}
                                                <Grid templateColumns="repeat(4, 1fr)" gap={6} mt={4}>
                                                    {metrics && metrics.map((metric) => (
                                                        <Box key={metric.id} className={Styles.metricBox} textTransform={'capitalize'}>
                                                            <Stat mb={'0'}>
                                                                <StatNumber>
                                                                    {metric.data_unit === 'money' ? " $ " : metric.data_type === 'number' ? " # " : null}
                                                                    <NumberFormat number={metric.data_value} />
                                                                    {metric.data_unit === 'percent' ? " %" : null}
                                                                </StatNumber>
                                                                <StatLabel>
                                                                    <Text fontWeight={'bold'} mb={'0'}>{metric.metric_name}</Text>
                                                                </StatLabel>
                                                            </Stat>
                                                        </Box>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Box>
                                ))
                            ))
                        ))
                    ))}

                </Box>
            </Box>

            <Box id="pdf-content-2">
                <Heading fontSize="18px" mb={4} className={Styles.slideTwoTitle}> Impact Stories </Heading>
                {impactStories.length === 0 && (
                    <Box className="errorMessage" minH={'100px'}>
                        No impact stories found for this report.
                    </Box>
                )}
                {impactStories.length > 0 && (
                    <>
                        {impactStories.map((item, key) => (
                            <Box key={key} border="1px solid #cbd5e0" p="10px" borderRadius="8px" mb="10px" textTransform={'capitalize'}>
                                <Text><Text as="span" fontWeight="bold">Region:</Text> {item.region || ""}</Text>
                                <Text><Text as="span" fontWeight="bold">Country:</Text> {item.country || ""}</Text>
                                <Text><Text as="span" fontWeight="bold">State:</Text> {item.state || ""}</Text>
                                <Text><Text as="span" fontWeight="bold">City:</Text> {item.city || ""}</Text>
                                <Text><Text as="span" fontWeight="bold">Blog Title:</Text> {item.impact_story_title || ""}</Text>
                                <Text><Text as="span" fontWeight="bold">Description:</Text> {parse(item.description)|| ""}</Text>
                                <Text><Text as="span" fontWeight="bold">Author Name:</Text> {item.author || ""}</Text>
                            </Box>
                        ))}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ReportContent;
