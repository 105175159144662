import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import Styles from './createaspiration.module.css';
import API from '../../../../../../Services/API';
import {
    Box, Input, Text, FormControl, FormLabel, Button, SimpleGrid, Switch, FormErrorMessage
} from '@chakra-ui/react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Widgets/ErrorWidget/ErrorWidget';

const CreateMetricData = ({ getMetricData, title, strategies }) => {
    const token = window.localStorage.getItem("accessToken");
    const { metricId } = useParams();

    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const [strategy, setStrategy] = useState([])
    const [strategyName, setStrategyName] = useState(null);

    const [geography, setGeography] = useState(null);
    const [geographyName, setGeographyName] = useState(null);
    const [country, setCountry] = useState(null);
    const [countryName, setCountryName] = useState(null);
    const [state, setState] = useState(null);
    const [stateName, setStateName] = useState(null);
    const [city, setCity] = useState(null);
    const [cityName, setCityName] = useState(null);

    // Form States
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setstates] = useState([]);
    const [cities, setCities] = useState([]);
    const [formValues, setFormValues] = useState({
        name: title || '',
        value: '',
        fiscalYear: null,
        fiscalQuarter: null,
        region: null,
        metricStatus: null,
        allowExport: false
    });
    const [validationError, setValidationError] = useState({});

    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [getQtrList, setGetQtrList] = useState([]);
    const [user, setUser] = useState({})

    // Update formValues.name if title prop changes
    useEffect(() => {
        setFormValues(prevValues => ({
            ...prevValues,
            name: title
        }));
        API.get(`/get-specific-modules?metric_id=${metricId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setUser(response.data);
        }).catch(error => {
            setIsLoading(false);
        });
    }, [title]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const yearResponse = await API.get(`/fiscal-year-detail`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setFiscalYearList(yearResponse.data[0].fiscalyear.map(item => ({ value: item.id, label: item.name })));
                setFiscalQtrList(yearResponse.data[1].fiscalquarter.map(item => ({ value: item.id, label: item.name })));

                const regionResponse = await API.get(`/region`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setRegions(regionResponse?.data.map(country => ({ value: country?.id, label: country?.region_name })));
                // const countryResponse = await API.get(`/country`, {
                //     headers: { 'Authorization': `Bearer ${token}` }
                // });
                // setCountries(countryResponse?.data.map(country => ({ value: country?.id, label: country?.country_name })));
                // const stateResponse = await API.get(`/state`, {
                //     headers: { 'Authorization': `Bearer ${token}` }
                // });
                // setstates(stateResponse?.data.map(country => ({ value: country?.id, label: country?.state_name })));
                // const cityResponse = await API.get(`/city`, {
                //     headers: { 'Authorization': `Bearer ${token}` }
                // });
                // setCities(cityResponse?.data.map(country => ({ value: country?.id, label: country?.city_name })));
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [token]);

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // if (!formValues.name) {
        //     errors.name = 'Name is required';
        //     isValid = false;
        // }
        if (!formValues.value) {
            errors.value = 'Value is required';
            isValid = false;
        }
        if (!selectedFiscalYear) {
            errors.fiscalYear = 'Fiscal Year is required';
            isValid = false;
        }
        if (!formValues.metricStatus) {
            errors.metricStatus = 'Metric status is required';
            isValid = false;
        }
        setValidationError(errors);
        return isValid;
    };

    const handleSubmit = async () => {

        if (!validateForm()) return;
        setMessage(null)
        setIsLoading(true);
        try {
            const body = {
                metric: metricId,
                name: formValues.name,
                value: parseInt(formValues.value),
                metric_status: formValues.metricStatus,
                geography: "",
                geography_name:"",
                region:geography,
                country:country,
                state:state,
                city:city,
                fiscal_year: selectedFiscalYear && selectedFiscalYear.value,
                fiscal_quarter: selectedFiscalQtr && selectedFiscalQtr.value,
                approval_status: user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'  ? "approved" : "pending",
                allow_export_externally: formValues.allowExport,
                position: user.superuser ? 'superuser'
                        : user.action_office_user ? 'action_office_user' 
                        : user.comms_user ? 'comms_user' 
                        : user.elt_user ? 'elt_user' 
                        : user.position === 'pillar_owner' ? 'pillar_owner' 
                        : user.position === 'pillar_contributor' ? 'pillar_contributor' 
                        : user.position === 'pillar_member' ? 'pillar_member' 
                        : user.position === 'action_owner' ? 'action_owner' 
                        : user.position === 'action_contributor' ? 'action_contributor' 
                        : user.position === 'action_member' ? 'action_member' : '',
                position_of_user: (user.position === 'pillar_owner' || user.position === 'action_owner') ? 'owner'
                                : (user.position === 'action_contributor' || user.position === 'pillar_contributor') ? 'contributor' : ''
            }

            const response = await API.post(`/metric-data`, body, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if(user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner'){
                setMessage('Thank you! Please refresh the screen to view the updated data.');
            }else{
                setMessage('Thank you! Your data has been successfully submitted for approval.');
            }
    
            getMetricData(); // Call function to refresh the data
    
            // Reset form states
            setFormValues({
                value: '',
                fiscalYear: null,
                fiscalQuarter: null,
                region: null,
                metricStatus: null,
                allowExport: false
            });
            setSelectedFiscalYear(null);
            setSelectedFiscalQtr(null);
            setValidationError({}); // Clear validation errors
    
            setIsPaneOpen(false); // Close the sliding pane
        } catch (error) {
            console.error("Error submitting data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (field) => (value) => {
        setFormValues(prevState => ({
            ...prevState,
            [field]: value,
            fiscalQuarter: field === 'fiscalYear' ? null : prevState.fiscalQuarter,
        }));
    };

    const filteredFiscalQtrList = fiscalQtrList.filter(item => 
        item.label.includes(`FY${formValues.fiscalYear?.label?.slice(-2)}`)
    );

    const metricStatusOptions = [
        { value: 'on-track', label: 'On-track' },
        { value: 'delayed', label: 'Delayed' },
        { value: 'complete', label: 'Complete' },
    ];

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption);
        setSelectedFiscalQtr(null);
        if (selectedOption) { // Check if selectedOption is not null
            const SelectedOptionNew = `FY${selectedOption.label.slice(-2)}`;
            let relatedQuarters = fiscalQtrList.filter(item => item.label.includes(SelectedOptionNew));
            relatedQuarters = relatedQuarters.sort((a, b) => {
                const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
                const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
                return quarterB - quarterA;
            });
    
            setGetQtrList(relatedQuarters);
        } else {
            setGetQtrList([]);
        }
    };

    const handleFiscalQtrChange = (selectedOption) => {
        setSelectedFiscalQtr(selectedOption);
    };

    const sortedRegions = regions.sort((a, b) => {
        const nameA = a?.name || a?.label;
        const nameB = b?.name || b?.label;
        return nameA?.localeCompare(nameB);
    });
    const sortedCountries = countries.sort((a, b) => {
        const nameA = a?.name || a?.label;
        const nameB = b?.name || b?.label;
        return nameA?.localeCompare(nameB);
    });
    const sortedStates = states.sort((a, b) => {
        const nameA = a?.name || a?.label;
        const nameB = b?.name || b?.label;
        return nameA?.localeCompare(nameB);
    });
    const sortedCities = cities.sort((a, b) => {
        const nameA = a?.name || a?.label;
        const nameB = b?.name || b?.label;
        return nameA?.localeCompare(nameB);
    });

    const sortedFiscalYearList = fiscalYearList && fiscalYearList.sort((a, b) => {
        const getFiscalYear = (label) => {
            const match = label.match(/FY(\d+)/);
            return match ? parseInt(match[1], 10) : -1;
        };
        const getQuarter = (label) => {
            const match = label.match(/Q(\d+)/);
            return match ? parseInt(match[1], 10) : 0; // Default 0 for no quarter
        };
        const fiscalYearDiff = getFiscalYear(b.label) - getFiscalYear(a.label);
        if (fiscalYearDiff !== 0) return fiscalYearDiff;
        return getQuarter(b.label) - getQuarter(a.label);
    });

    // const sortedMetricData = props.metricData ? [...props.metricData].sort((a, b) => b.id - a.id) : [];
    const strategiesList = strategies?.map(item => ({
        value: item.id,
        label: item.name
    }))

    const changeStrategy = (event) => {
        setStrategy(event?.value);
        setStrategyName(event?.label)
    };

    const countryRef = useRef(null)
    const stateRef = useRef(null)
    const cityRef = useRef(null)


    const changecountry = async (selectedOption) => {
        try {
            setCities([])
            setstates([])
            if(selectedOption===null|| selectedOption===undefined){
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setCities([])
            setstates([])
            setCity(null)
            setState(null)
            setCountry(null)
                return
            }
            if(selectedOption?.value){
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setCity(null)
            setState(null)
            setCities([])
            setstates([])
            console.log("state", selectedOption)
            setCountry(selectedOption.value)
            setCountryName(selectedOption.label)
            const stateResponse = await API.get(`/get-geographical-data?country=${selectedOption.value}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setstates(stateResponse?.data.map(country => ({ value: country?.id, label: country?.state_name })));
        }
        } catch (err) {

            console.log("err", err)
        }
    }
    const changeregion = async (selectedOption) => {
        try {
            setCities([])
            setCountries([])
            setstates([])
            if(selectedOption===null|| selectedOption===undefined){
            if (countryRef.current) countryRef.current.clearValue();
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setGeography(null)
            setCountry(null)
            setCity(null)
            setState(null)
            setCities([])
            setCountries([])
            setstates([])
                return
            }
            if(selectedOption?.value){
            if (countryRef.current) countryRef.current.clearValue();
            if (stateRef.current) stateRef.current.clearValue();
            if (cityRef.current) cityRef.current.clearValue();
            setCountry(null)
            setCity(null)
            setState(null)
            setCities([])
            setCountries([])
            setstates([])
            console.log("country", selectedOption)
            setGeography(selectedOption.value)
            setGeographyName(selectedOption.label)
            const countryResponse = await API.get(`/get-geographical-data?region=${selectedOption.value}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setCountries(countryResponse?.data.map(country => ({ value: country?.id, label: country?.country_name })));
        }

        } catch (err) {
            console.log("err", err)
        }
    }
    const changeState = async (selectedOption) => {
        setCities([])
        try {
            if(selectedOption===null|| selectedOption===undefined){
            if (cityRef.current) cityRef.current.clearValue();
            setCities([])
            setCity(null)
            setState(null)
                return
            }
            if(selectedOption?.value){
                if (cityRef.current) cityRef.current.clearValue();
                setCities([])
                setCity(null)
                
                console.log("city", selectedOption)
                setState(selectedOption?.value)
                setStateName(selectedOption.label)
                const cityResponse = await API.get(`/get-geographical-data?state=${selectedOption.value}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setCities(cityResponse?.data.map(country => ({ value: country?.id, label: country?.city_name })));
                
            }

        } catch (err) {

            console.log("err", err)
        }
    }
    const changeCity = (selectedOption) => {
        if(selectedOption===null|| selectedOption===undefined){
            return
        }
        setCity(selectedOption &&selectedOption?.value)
        setCityName(selectedOption &&selectedOption.label)
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            
            {message && <ErrorWidget message={message} />}
            <Button float="right" className={Styles.addAspiration} onClick={() => setIsPaneOpen(true)}> 
                Create Metric Data 
            </Button>
            <SlidingPane
                className={Styles.slidingPane}
                isOpen={isPaneOpen}
                title="Create Metric Data"
                width="50%"
                onRequestClose={() => setIsPaneOpen(false)}
            >
                <SimpleGrid columns={[1, 2]} spacing="20px" mt="10px">
                    <Box>
                        <FormControl isInvalid={!!validationError.name}>
                            <FormLabel fontWeight="bold">Name</FormLabel>
                            <Input 
                                type="text" 
                                value={title}
                                onChange={(e) => handleChange('name')(e.target.value)}
                                mb="15px"
                                disabled
                            />
                            <FormErrorMessage>{validationError.name}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!validationError.value}>
                            <FormLabel fontWeight="bold">Value</FormLabel>
                            <Input 
                                type="number" 
                                onChange={(e) => handleChange('value')(e.target.value)}
                                mb="15px"
                            />
                            <FormErrorMessage>{validationError.value}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!validationError.metricStatus}>
                            <FormLabel fontWeight="bold">Metric Status</FormLabel>
                            <Select 
                                options={metricStatusOptions}
                                onChange={(selectedOption) => handleChange('metricStatus')(selectedOption?.value)}
                                placeholder="Select metric status"
                                isClearable
                            />
                            <FormErrorMessage>{validationError.metricStatus}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Regions</FormLabel>
                            <Select 
                                options={sortedRegions}
                                //onChange={(selectedOption) => handleChange('region')(selectedOption?.value)}
                                onChange={changeregion}
                                placeholder="Select region"
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Country</FormLabel>
                            <Select 
                                ref={countryRef}
                                options={sortedCountries}
                            //onChange={(selectedOption) => handleChange('region')(selectedOption?.value)}
                                onChange={changecountry}
                                placeholder="Select country"
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">State</FormLabel>
                            <Select 
                                ref={stateRef}
                                options={sortedStates}
                                //onChange={(selectedOption) => handleChange('region')(selectedOption?.value)}
                                onChange={changeState}
                                placeholder="Select state"
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">City</FormLabel>
                            <Select 
                                ref={cityRef}
                                options={sortedCities}
                                //onChange={(selectedOption) => handleChange('region')(selectedOption?.value)}
                                onChange={changeCity}
                                placeholder="Select city"
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!validationError.fiscalYear}>
                            <FormLabel fontWeight="bold">Fiscal Year</FormLabel>
                            <Select
                                id={'metricData_targetfiscalyear'}
                                options={sortedFiscalYearList}
                                onChange={handleFiscalYearChange}
                                placeholder="Select fiscal year"
                                isClearable
                            />
                            <FormErrorMessage>{validationError.fiscalYear}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Fiscal Quarter</FormLabel>
                            <Select
                                id={'metricData_targetfiscalquarter'}
                                options={selectedFiscalYear ? getQtrList : []}
                                value={selectedFiscalQtr}
                                onChange={handleFiscalQtrChange}
                                placeholder={selectedFiscalYear ? "Select fiscal quarter" : "Select fiscal year first"}
                                isDisabled={!selectedFiscalYear}
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    
                    <Box>
                        <FormControl display="flex" alignItems="center" mt="0px">
                            <FormLabel htmlFor="share_data_externally" mb="0" fontWeight="bold">
                                Share Data Externally
                            </FormLabel>
                            <Switch 
                                id="share_data_externally" 
                                isChecked={formValues.allowExport} 
                                onChange={() => handleChange('allowExport')(!formValues.allowExport)} 
                            />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <Box mb="20px">
                    <Button colorScheme="blue" onClick={handleSubmit} mt="20px" float="right">
                        Add Metric Data
                    </Button>
                </Box>
            </SlidingPane>
        </>
    );
};

export default CreateMetricData;
