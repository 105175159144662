import React, { useEffect, useState } from 'react';
import API from '../../../../../Services/API';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import StylesCustom from './edittheme.module.css'
import { Box, Button, Input, FormControl, FormLabel, useToast, Tooltip, IconButton, Spinner, Text } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Pillars from './Pillars/Pillars';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const EditTheme = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null)
    const [selectedPillars, setSelectedPillars] = useState([])
    const [selectedActions, setSelectedActions] = useState([])
    const [selectedMetrics, setSelectedMetrics] = useState([])

    const getPillarList = (pillars)=>{
        setSelectedPillars(pillars)
        console.log("pillars" , pillars)
    }
    const getActionsList = (actions)=>{
        setSelectedActions(actions)
        console.log("actions" , actions)
    }
    const getMetricsList = (metrics)=>{
        setSelectedMetrics(metrics)
        console.log("metrics" , metrics)
    }

    const handleUpdateTheme = async () => {
        setMessage(null)
        setLoading(true);
        try{
            const body = selectedMetrics.map(metric => ({
                "organisation-theme": props.id,
                metric
            }));
            await API.post('/organisation-theme-metric', body,  { 
                headers: { 'Authorization': `Bearer ${token}` } 
            });
            props.getUpdate()
            setMessage('Added theme successfully.');
        }catch (error) {
            console.error("Error:", error);
            
            let errorMsg = "An error occurred. Please try again.";
        
            if (error.response) {
                console.error("Response Data:", error.response.data);
        
                const responseData = error.response.data;
        
                if (responseData?.error) {
                    // Extracting and formatting errors into a readable list
                    const errorList = responseData.error.map((errObj, index) => {
                        const fieldErrors = errObj.error
                            .map(fieldErrorObj =>
                                Object.entries(fieldErrorObj)
                                    .map(([key, messages]) => `• ${key}: ${messages.join(", ")}`)
                                    .join("\n")
                            )
                            .join("\n");
        
                        return fieldErrors;
                    });
        
                    errorMsg = errorList.join("\n");
                } else {
                    errorMsg = `Error ${error.response.status}: ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMsg = "No response from the server. Please check your connection.";
            } else {
                errorMsg = error.message;
            }
        
            setMessage(errorMsg);
        }finally {
            setLoading(false);
            setIsPaneOpen(false)
        }
    }

    useEffect(() => {
        if (isPaneOpen) {
            API.get(`/organisation-theme-metric?id=${props.id}`,{
                headers: { 'Authorization': `Bearer ${token}` } 
            }).then(response=>{
                console.log(response.data)
            }).catch(error=>{

            })
        }
    }, [isPaneOpen]);

    return (
        <>
            {loading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Tooltip label="Edit" aria-label="Edit">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    fontSize="15px"
                    icon={<EditIcon />}
                    className={StylesCustom.edit}
                    onClick={() => setIsPaneOpen(true)}
                />
            </Tooltip>
            <SlidingPane
                isOpen={isPaneOpen}
                title={props.componentValue + " Theme"}
                from="right"
                width="100%"
                className={StylesCustom.slidePane + " slidePaneNew"}
                onRequestClose={() => setIsPaneOpen(false)}
            >
                {/* <FormControl mb={'10px'}>
                    <FormLabel fontWeight={'600'}>Theme Name</FormLabel>
                    <Input
                        placeholder="Enter theme name"
                        //value={themeName}
                        //onChange={(e) => setThemeName(e.target.value)}
                    />
                </FormControl> */}

                <FormControl>
                    <FormLabel fontWeight={'600'}>Assign Pillar Metrics</FormLabel>
                    <Text>
                        Select organization pillars to filter action teams and metrics.
                        Selected metrics will be assigned to the theme.
                    </Text>
                </FormControl>
                <Pillars
                    getPillarList={getPillarList}
                    getActionsList={getActionsList}
                    getMetricsList={getMetricsList}
                />

                <Button mt={4} colorScheme="blue" onClick={handleUpdateTheme}>
                    Save
                </Button>
                <Button mt={4} ml={2} colorScheme="gray" onClick={() => setIsPaneOpen(false)}>
                    Close
                </Button>
            </SlidingPane>
        </>
    )
}

export default EditTheme