import React, { useEffect, useState } from 'react';
import API from '../../../../Services/API';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';

const Pillars = (props) => {
    const token = window.localStorage.getItem("accessToken");

    const [tags, setTags] = useState([]);
    const [pillars, setPillars] = useState([]);
    const [actions, setActions] = useState([]);
    const [metrics, setMetrics] = useState([]);

    // Fetch Tags and Pillars on Mount
    useEffect(() => {
        API.get(`/tag-detail/`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => setTags(response.data.results))
            .catch(error => console.error("Error fetching tags:", error));

        API.get(`/sio-pillar`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => setPillars(response.data))
            .catch(error => console.error("Error fetching pillars:", error));
    }, []);

    // Toggle Selection Handlers
    const toggleSelection = (id, setState) => {
        setState(prev => prev.map(item => 
            item.id === id ? { ...item, selected: !item.selected } : item
        ));
    };

    // Fetch Actions when Pillars change
    useEffect(() => {
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        if (selectedPillars.length === 0) {
            setActions([]);
            return;
        }

        API.get(`/multi-action-pillar?pillar_id=${JSON.stringify(selectedPillars)}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => setActions(response.data.map(a => ({ ...a, selected: false }))))
            .catch(error => console.error("Error fetching actions:", error));
    }, [pillars]);

    // Fetch Metrics based on Pillars, Tags, and Actions
    useEffect(() => {
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        const selectedActions = actions.filter(a => a.selected).map(a => a.id);
        const selectedTags = tags.filter(t => t.selected).map(t => t.id);

        let queryParams = [];

        if(selectedPillars.length && selectedActions.length && selectedTags.length){
            queryParams.push(`action_id=${selectedActions.join(",")}`);
            queryParams.push(`tags_id=${selectedTags.join(",")}`);
        }else if(selectedPillars.length && selectedTags.length){
            queryParams.push(`pillar_id=${selectedPillars.join(",")}`);
            queryParams.push(`tags_id=${selectedTags.join(",")}`);
        }else if(selectedActions.length){
            queryParams.push(`action_id=${selectedActions.join(",")}`);
        }else if(!selectedActions.length && selectedPillars.length){
            queryParams.push(`pillar_id=${selectedPillars.join(",")}`);
        }
        if (queryParams.length === 0) {
            setMetrics([]);
            return;
        }
        const url = `/metric-list-report?${queryParams.join("&")}`;
        API.get(url, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => setMetrics(response.data.map(m => ({ ...m, selected: false }))))
            .catch(error => console.error("Error fetching metrics:", error));
    }, [pillars, actions, tags]);

    // Pass selected metrics to parent component
    useEffect(() => {
        const selectedTags = tags.filter(t => t.selected).map(t => t.id);
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        const selectedActions = actions.filter(a => a.selected).map(a => a.id);
        const selectedMetrics = metrics.filter(m => m.selected).map(m => m.id);

        props.getTagsList(selectedTags)
        props.getPillarsList(selectedPillars)
        props.getActionsList(selectedActions)
        props.getMetricsList(selectedMetrics);
    }, [metrics]);

    return (
        <>
            {/* Tags Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Tags</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform={'capitalize'}>
                    {tags.sort((a, b) => a.name.localeCompare(b.name)).map(tag => (
                        <div key={tag.id} onClick={() => toggleSelection(tag.id, setTags)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {tag.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{tag.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Pillars Section */}
            <FormControl mt="15px" mb="15px">
                <FormLabel fontWeight="600">Pillars</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform={'capitalize'}>
                    {pillars.sort((a, b) => a.name.localeCompare(b.name)).map(pillar => (
                        <div key={pillar.id} onClick={() => toggleSelection(pillar.id, setPillars)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {pillar.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{pillar.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Actions Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Action Teams</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform={'capitalize'}>
                    {actions.sort((a, b) => a.name.localeCompare(b.name)).map(action => (
                        <div key={action.id} onClick={() => toggleSelection(action.id, setActions)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {action.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{action.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Metrics Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Metrics</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform={'capitalize'}>
                    {metrics.sort((a, b) => a.name.localeCompare(b.name)).map(metric => (
                        <div key={metric.id} onClick={() => toggleSelection(metric.id, setMetrics)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {metric.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{metric.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>
        </>
    );
};

export default Pillars;
