import React, { useState, useEffect } from 'react';
import Styles from './managepillars.module.css';
import API from '../../../../Services/API';
import { Card, Heading, Box, Button, Image, Text, HStack, Tag, TagLabel, Tooltip, IconButton } from '@chakra-ui/react';
import emptyIcon from '../../../../../assets/images/emptyIcon.jpg';
import CreatePillar from './CreatePillar/CreatePillar';
import { EditIcon } from '@chakra-ui/icons';
import EditPillar from './EditPillar/EditPillar';
import DeletePillar from './DeletePillar/DeletePillar';

const ManagePillars = () => {
    const token = window.localStorage.getItem("accessToken");
    const [pillars, setPillars] = useState([]);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        getUpdate();
    }, []);

    const getUpdate = async () => {
        try {
            const pillarResponse = await API.get('/sio-pillar', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            setPillars(pillarResponse.data);

            const actionsResponse = await API.get('/get-pillar-action', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            setActions(actionsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <>
            <Box width="100%" borderBottom="1px solid #dadada" height="45px" position="relative">
                <Box float="left" className={Styles.headingParent}>
                    <Heading as="h3" className={Styles.heading}>Pillars</Heading>
                </Box>
                <CreatePillar getUpdate={getUpdate} componentValue="create" />
                <Box className="clearfix"></Box>
            </Box>
            
            <Box mt="5px">
                {
                    pillars.length === 0 ? 
                        <Box className={Styles.emptyCOntainer}>
                            <Image src={emptyIcon} m="0 auto" maxW="50px" />
                            <Text textAlign="center" fontSize="12px" mb="0px">No pillars currently</Text>
                            <Text textAlign="center" fontSize="12px">
                                <a href="#n" style={{ color: '#1890ff' }}>Click here</a> to create a pillar
                            </Text>
                        </Box>
                    : (
                        pillars.map(pillar => (
                            <Box key={pillar.id} className={Styles.entryContainer}>
                                <Heading as="h3">
                                    {pillar.name}
                                    <EditPillar name={pillar.name} id={pillar.id} module={pillar.module} getUpdate={getUpdate} componentValue="edit" />
                                    <DeletePillar name={pillar.name} id={pillar.id} module={pillar.module} getUpdate={getUpdate} componentValue="edit" />
                                </Heading>
                                <Box className={Styles.entryInnerContainer}>
                                    {actions.filter(action => action.parent_id === pillar.id).length === 0 ? (
                                        <Box className={Styles.emptyCOntainer}>
                                            <Image src={emptyIcon} m="0 auto" maxW="50px" />
                                            <Text textAlign="center" fontSize="12px" mb="0px">No actions added currently</Text>
                                            <Text textAlign="center" fontSize="12px">Please click the create pillar button to create/assign a action to the current pillar</Text>
                                        </Box>
                                    ) : (
                                        <HStack spacing={4} m={'10px'} display={'block'}>
                                            {
                                                actions.filter(action => action.parent_id === pillar.id).map(action => (
                                                    <Tag key={action.id} variant="solid" colorScheme="gray" maxW="max-content" mb={'5px'} mr={'5px'}>
                                                        <TagLabel whiteSpace="normal" overflow="visible" textOverflow="clip">
                                                            {action.name}
                                                        </TagLabel>
                                                    </Tag>
                                                ))
                                            }
                                        </HStack>
                                        // <ul>
                                        //     {actions.filter(action => action.parent_id === pillar.id).map(action => (
                                        //         <li key={action.id}>{action.name}</li>
                                        //     ))}
                                        // </ul>
                                    )
                                }
                            </Box>
                        </Box>
                    ))
                )}
            </Box>
        </>
    );
};

export default ManagePillars;
