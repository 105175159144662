import React, { useEffect, useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { Box, Button, Text, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, useDisclosure, FormControl, FormLabel, Input, Flex, DrawerFooter, Select, SimpleGrid, Switch } from "@chakra-ui/react";
import API from '../../../../Services/API';
import Geographies from './Geographies/Geographies';
import Pillars from './Pillars/Pillars';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const EditReport = (props) => {
    const token = window.localStorage.getItem("accessToken") || "";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [reportData, setReportData] = useState(null);
    const [title, setTitle] = useState("");
    const [regions, setRegions] = useState([]);
    const [pillars, setPillars] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedPillars, setSelectedPillars] = useState([]);
    const [selectedActions, setSelectedActions] = useState([]);
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [selectedGeographies, setSelectedGeographies] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [includeImpactStories, setIncludeImpactStories] = useState(false);
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        if (isOpen) {
            fetchReportData();
        }
    }, [isOpen]);

    const fetchReportData = async () => {
        try {
            const response = await API.get(`/custom-country-report?report_id=${props.id}`, { 
                headers: { Authorization: `Bearer ${token}` } 
            });
            setReportData(response.data[0]);
            setTitle(response.data[0].title);
            setSelectedPillars(response.data[0].pillars);
            console.log(response.data[0])
            setStartDate(response.data[0].start_date)
            setEndDate(response.data[0].end_date)
            setIncludeImpactStories(response.data[0].impact_story)
        } catch (error) {
            console.error("Error fetching report data:", error);
        }
    };

    const getTags = (tags) => {
        setSelectedTags(tags)
    }
    const getPillars = (pillars) => {
        setSelectedPillars(pillars);        
    }
    const getActions = (actions) => {
        setSelectedActions(actions);        
    }
    const getMetrics = (metrics) => {
        setSelectedMetrics(metrics); 
    }

    const getSelectedGeographies = (geographies) => {
        setSelectedGeographies(geographies); 
    }

    const saveReport = () =>{
        const regions = [...new Set(selectedGeographies.map((item) => item.region_id).filter(Boolean))];
        const countries = [...new Set(selectedGeographies.map((item) => item.country_id).filter(Boolean))];
        const states = [...new Set(selectedGeographies.map((item) => item.state_id).filter(Boolean))];
        const cities = [...new Set(selectedGeographies.map((item) => item.city_id).filter(Boolean))];

        
        const formData = new FormData();
        formData.append("id", props.id);
        formData.append("title", title);
        if (cities.length > 0) formData.append("city_report", JSON.stringify(cities));
        if (states.length > 0) formData.append("state_report", JSON.stringify(states));
        if (countries.length > 0) formData.append("country_report", JSON.stringify(countries));
        if (regions.length > 0) formData.append("region_report", JSON.stringify(regions));
        formData.append("start_date", startDate);
        formData.append("end_date", endDate);
        formData.append("impact_story", includeImpactStories);
        if (selectedTags && selectedTags.length > 0) formData.append("tag_report", JSON.stringify(selectedTags));
        if (selectedPillars && selectedPillars.length > 0) formData.append("pillar_report", JSON.stringify(selectedPillars));
        if (selectedActions && selectedActions.length > 0) formData.append("action_report", JSON.stringify(selectedActions));
        if (selectedMetrics && selectedMetrics.length > 0) formData.append("metric_report", JSON.stringify(selectedMetrics));

        API.put(`/custom-country-report`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            props.getUpdated();
            setMessage('Updated report title successfully');
            setIsLoading(false);
            onClose();
        })
        .catch(error => {
            console.error(error); // Log the error for debugging
            setMessage('Failed to create report');
            setIsLoading(false);
            onClose();
        });
        
        
        

        
        // console.log("title : ", title)
        // if (cities.length > 0) console.log("city_report : ", JSON.stringify(cities))
        // if (states.length > 0) console.log("state_report : ", JSON.stringify(states))
        // if (countries.length > 0) console.log("country_report : ", JSON.stringify(countries))
        // if (regions.length > 0) console.log("region_report : ", JSON.stringify(regions))
        // console.log("startDate : ", startDate)
        // console.log("endDate : ", endDate)
        // console.log("includeImpactStories : ", includeImpactStories)
        // if (selectedTags) console.log("tag_report : ", JSON.stringify(selectedTags))
        // if (selectedPillars && selectedPillars.length > 0) console.log("pillar_report : ", JSON.stringify(selectedPillars))
        // if (selectedActions && selectedActions.length > 0) console.log("action_report : ", JSON.stringify(selectedActions))
        // if (selectedMetrics && selectedMetrics.length > 0) console.log("metric_report : ", JSON.stringify(selectedMetrics))
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}

            <EditIcon cursor="pointer" onClick={onOpen} />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={false}>
                <DrawerOverlay />
                <DrawerContent maxW="50%">
                    <DrawerCloseButton />
                    <DrawerHeader bg="gray.200" fontWeight="normal" p="10px">
                        Edit {title}
                    </DrawerHeader>
                    <DrawerBody>
                        <FormControl mb="5px">
                            <FormLabel htmlFor="reportTitle">Report Title</FormLabel>
                            <Input 
                                id="reportTitle" 
                                name="reportTitle" 
                                type="text" 
                                placeholder="Enter report title" 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                            />
                        </FormControl>
                        <Geographies 
                            id={props.id}
                            getSelectedGeographies={getSelectedGeographies}
                        />
                        <FormControl mt="10px">
                            <FormLabel fontWeight="600">Select report start and end date</FormLabel>
                            <SimpleGrid columns={[2, null, 3]} spacing={2}>
                                <Box border="1px solid #ddd" borderRadius="4px" p="8px">
                                    <FormControl>
                                        <FormLabel fontWeight="600">Report Start Date</FormLabel>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date ? format(date, "MM-dd-yyyy") : "")}
                                            dateFormat="MM-dd-yyyy"
                                            placeholderText="MM-DD-YYYY"
                                            customInput={<Input />}
                                        />
                                    </FormControl>
                                </Box>
            
                                <Box border="1px solid #ddd" borderRadius="4px" p="8px">
                                    <FormControl>
                                        <FormLabel fontWeight="600">Report End Date</FormLabel>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date ? format(date, "MM-dd-yyyy") : "")}
                                            dateFormat="MM-dd-yyyy"
                                            placeholderText="MM-DD-YYYY"
                                            customInput={<Input />}
                                        />
                                    </FormControl>
                                </Box>
            
                                <Box border="1px solid #ddd" borderRadius="4px" p="8px">
                                    <FormControl display="flex" alignItems="center">
                                        <FormLabel mb="0" fontWeight="600">Include Impact Stories:</FormLabel>
                                        <Switch
                                            isChecked={includeImpactStories}
                                            onChange={() => includeImpactStories(!includeImpactStories)}
                                        />
                                    </FormControl>
                                </Box>
                            </SimpleGrid>
                        </FormControl>
                        <Pillars
                            id={props.id}
                            getTags={getTags}
                            getPillars={getPillars}
                            getActions={getActions}
                            getMetrics={getMetrics}
                            selectedGeographies={selectedGeographies}
                        />
                    </DrawerBody>
                    <DrawerFooter backgroundColor={'transparent'} borderTop={'1px solid #E2E8F0'}>
                        <Flex justify="flex-end" w="100%" gap={2}>
                            <Button variant="outline" onClick={onClose}>Cancel</Button>
                            <Button colorScheme="blackAlpha" bg="black" color="white" onClick={saveReport}> Save the report </Button>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default EditReport;
