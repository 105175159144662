import React, { useState } from 'react'
import ErrorWidget from '../../../../Widgets/ErrorWidget/ErrorWidget'
import Styles from './createModule.module.css'
import $ from 'jquery'
import API from '../../../../../Services/API'
import { Button, ButtonGroup, Select, IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Text,
    Box
} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import LoaderSpinner from '../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'


const CreateModule = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const menuList = JSON.parse(window.localStorage.getItem("menuList"))
    const [isLoading, setIsLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [category, setCategory] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [isGlobalView, setIsGlobalView ] = useState(false)
    const [moduleName, setModuleName] = useState(null)
    const [message, setMessage] = useState()
    const [errorMessage, setErrorMessage] = useState(null)

    
    const getCategory = (event) =>{
        setCategory(event.target.value)
    }

    const getSubCategory = (event) => {
        setSubCategory(event.target.value)
    }

    const newModule = () => {
        setMessage(true)
        setErrorMessage(null)
        setIsLoading(true)
        const body = {
            "name": $("#moduleName").val(),
            "category": 'pillar',
            "parent_id": subCategory,
            "unique_name": (($("#moduleName").val()).match(/\b(\w)/g)).join(''),
        }

        API.post(`/project-modules`, body, {headers: {'Authorization': 'Bearer ' + token}})
        .then((response) => {
            API.get(`/project-modules`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                if(props.componentType === "organization"){
                    props.getUpdate()
                    $('#moduleName').val('')
                    $('#rootMenu').val('')
                    setIsLoading(false)
                    setMessage(true)
                    onClose()
                }else{
                    props.updateMenu(response.data)
                    const timeoutId = setTimeout(() => {
                        setMessage(false)
                    }, 5000);
                    $('#moduleName').val('')
                    $('#rootMenu').val('')
                    setIsLoading(false)
                    setMessage(true)
                    onClose()
                    window.location.reload()
                    return () => clearTimeout(timeoutId);
                }
                
                
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            onClose()
            setErrorMessage(error.response.data.error)
            setIsLoading(false)
        })
    }

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {errorMessage && errorMessage !== null ? <ErrorWidget  message={errorMessage}  /> : null}
            {props.componentType === "organization" ? (
                    <Box float={'right'} className={Styles.headingParentRight}>
                        <Button
                            mb={'5px'}
                            backgroundColor={'#2c2c2c'}
                            color={'#FFFFFF'}
                            onClick={onOpen}
                            _hover={{ backgroundColor: '#2c2c2c', color: '#FFFFF' }}
                        >
                            Create Pillar +
                        </Button>
                    </Box>
                ) : 
                <Box>
                    <Button className='defaultButton' onClick={onOpen}> 
                        <AddIcon mr={'5px'} onClick={onOpen}  /> Add new 
                    </Button>
                </Box>
            }

            
                {/* <ButtonGroup size='sm' isAttached variant='outline'  onClick={onOpen}>
                    <Button onClick={onOpen} className={Styles.createButton}>Add new</Button>
                    <IconButton aria-label='Add to friends' icon={<AddIcon />} onClick={onOpen} />
                </ButtonGroup> */}
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalHeader>Add new SIIO / action</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {
                            message ? 
                                <Text className={Styles.successmessage}>
                                    The new SIIO/action has been added now.
                                </Text>
                            : null
                        }
                        <FormControl mb={'10px'}>
                            <FormLabel>SIO / Action Name</FormLabel>
                            <Text fontSize='xs'>Select the pillar if you have added a new action</Text>
                            <Input type='text' id={'moduleName'} />
                        </FormControl>
                        <FormControl mb={'10px'}>
                            <FormLabel>Select pillar (Optional)</FormLabel>
                            <Select id={'rootMenu'} size='md' placeholder='please select sub category from list' onChange={getSubCategory}>
                                {
                                    menuList && menuList.map((item, index) =>
                                        ((item.category === 'pillar') && (item.parent_id === null)) ?
                                            <option value={item.id}> {item.name} </option> 
                                        : null
                                    )
                                }
                            </Select>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={Styles.confirmButton} onClick={newModule}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateModule