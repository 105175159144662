import React, { useState, useRef } from 'react';
import PptxGenJS from "pptxgenjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {Button,useDisclosure,Text} from "@chakra-ui/react";
import jsPDF from 'jspdf';
import pptxgen from 'pptxgenjs';
import html2canvas from 'html2canvas';
import { DownloadIcon } from "@chakra-ui/icons";
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import API from '../../../../../Services/API';
import {Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalFooter,ModalCloseButton,} from "@chakra-ui/react";


const Download = ({id, title, metricLength, impactLength}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const containerRef = useRef(null);
    const token = window.localStorage.getItem("accessToken") || "";
    const [isLoading, setIsloading] = useState(false)

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const formatHeader = (str) => {
        return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    };


    const printPDF = async () => {
        setIsloading(true);
    
        try {
            const pdf = new jsPDF('l', 'mm', [297, 210]); // A4 landscape
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const marginX = 10;
            const marginY = 10;
            const contentMaxHeight = pageHeight - 2 * marginY;
    
            const sectionIds = ['pdf-content-0', 'pdf-content-1', 'pdf-content-2'];
    
            const preloadImages = async (element) => {
                const images = element.querySelectorAll('img');
                return Promise.all(Array.from(images).map(img =>
                    new Promise((resolve) => {
                        if (img.complete) resolve();
                        else img.onload = img.onerror = () => resolve();
                    })
                ));
            };
    
            let firstPage = true; // Track first page to avoid unnecessary `pdf.addPage()`
    
            for (let i = 0; i < sectionIds.length; i++) {
                const element = document.getElementById(sectionIds[i]);
                if (!element) continue;
    
                await preloadImages(element);
    
                const canvas = await html2canvas(element, { scale: 2, useCORS: true, backgroundColor: '#ffffff' });
                const imgData = canvas.toDataURL('image/png');
    
                let imgWidth = canvas.width;
                let imgHeight = canvas.height;
                let ratio = (pageWidth - 2 * marginX) / imgWidth;
    
                let scaledWidth = imgWidth * ratio;
                let scaledHeight = imgHeight * ratio;
    
                let currentHeight = 0;
    
                while (currentHeight < scaledHeight) {
                    let remainingHeight = scaledHeight - currentHeight;
                    let croppedHeight = Math.min(remainingHeight, contentMaxHeight);
                    let croppedRatio = croppedHeight / scaledHeight;
    
                    let croppedCanvas = document.createElement("canvas");
                    croppedCanvas.width = imgWidth;
                    croppedCanvas.height = imgHeight * croppedRatio;
    
                    let ctx = croppedCanvas.getContext("2d");
                    ctx.drawImage(canvas, 0, currentHeight / ratio, imgWidth, croppedCanvas.height, 0, 0, imgWidth, croppedCanvas.height);
    
                    let croppedImageData = croppedCanvas.toDataURL("image/png");
    
                    // Add new page only if it's NOT the first page
                    if (!firstPage) pdf.addPage();
                    firstPage = false; // After first page is added, set to false
    
                    pdf.addImage(croppedImageData, "PNG", marginX, marginY, scaledWidth, croppedHeight);
    
                    currentHeight += croppedHeight;
                }
            }
    
            pdf.save(`${title}.pdf`);
        } catch (error) {
            console.error('Error generating PDF:', error);
            alert("Failed to generate PDF. Check console for details.");
        } finally {
            setIsloading(false);
        }
    };

    const downloadPPT = async () => {
        setIsloading(true);
        try {
            const pptx = new pptxgen();
            const elementIds = ['pdf-content-0', 'pdf-content-1', 'pdf-content-2'];
            const slideWidthInches = 10;
            const slideHeightInches = 5.63;
            const pixelsPerInch = 96; // Standard screen resolution
            const slideHeightPixels = slideHeightInches * pixelsPerInch;
            const overlapPixels = 20; // Overlap to prevent cutoff
    
            for (const id of elementIds) {
                const element = document.getElementById(id);
                if (!element) {
                    console.warn(`Element with id "${id}" not found.`);
                    continue;
                }
    
                let yOffset = 0;
                const totalHeight = element.scrollHeight;
    
                while (yOffset < totalHeight) {
                    try {
                        // Create a new container to capture only the visible portion
                        const wrapper = document.createElement('div');
                        wrapper.style.width = `${element.scrollWidth}px`;
                        wrapper.style.height = `${slideHeightPixels}px`;
                        wrapper.style.overflow = 'hidden';
                        wrapper.style.position = 'absolute';
                        wrapper.style.top = '0px';
                        wrapper.style.left = '-9999px';
                        document.body.appendChild(wrapper);
    
                        // Clone and adjust the element for capture
                        const clonedElement = element.cloneNode(true);
                        clonedElement.style.position = 'relative';
                        clonedElement.style.top = `-${yOffset}px`; // Scroll effect simulation
                        wrapper.appendChild(clonedElement);
    
                        // Capture the visible portion
                        const canvas = await html2canvas(wrapper, {
                            scale: 2,
                            useCORS: true
                        });
    
                        document.body.removeChild(wrapper); // Clean up
    
                        const imgData = canvas.toDataURL('image/png');
                        const slide = pptx.addSlide();
    
                        // Calculate image dimensions to fit in the slide
                        const aspectRatio = canvas.width / canvas.height;
                        let imgWidth = slideWidthInches;
                        let imgHeight = imgWidth / aspectRatio;
    
                        if (imgHeight > slideHeightInches) {
                            imgHeight = slideHeightInches;
                            imgWidth = imgHeight * aspectRatio;
                        }
    
                        slide.addImage({
                            data: imgData,
                            x: (slideWidthInches - imgWidth) / 2,
                            y: (slideHeightInches - imgHeight) / 2,
                            w: imgWidth,
                            h: imgHeight
                        });
    
                        // Move to the next portion with slight overlap
                        yOffset += slideHeightPixels - overlapPixels;
                    } catch (captureError) {
                        console.error(`Error capturing element with id "${id}":`, captureError);
                    }
                }
            }
    
            await pptx.writeFile(`${title}.pptx`);
            setIsloading(false);
        } catch (error) {
            console.error('Error creating presentation:', error);
            setIsloading(false);
        }
    };

    const flattenArrayFields = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
            newObj[key] = Array.isArray(obj[key]) ? obj[key].join(", ") : obj[key]; // Convert arrays to comma-separated strings
        });
        return newObj;
    };
    
    const DownloadXls = async () => {
        try {
            // Fetch data from API
            const response = await API.get(`/custom-country-report?report_id=${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            console.log("API Response:", response.data);
    
            if (!response.data) {
                console.error("No data found in API response:", response.data);
                return;
            }
    
            // Extract and process data
            const metricsData = response.data[0]?.metrics_data_tab || [];
            const impactStories = response.data[0]?.impact_stories_tab || [];
    
            // Function to format headers
            const formatHeader = (header) => header.replace(/_/g, " ").toUpperCase();
    
            // --- Process Metrics Data ---
            let wsMetrics;
            let metricsHeaders = metricsData.length
                ? Object.keys(metricsData[0]).map(formatHeader)
                : ["No Data Available"];
    
            if (metricsData.length) {
                const formattedMetrics = metricsData.map((item) => flattenArrayFields(item));
                wsMetrics = XLSX.utils.json_to_sheet(formattedMetrics);
            } else {
                wsMetrics = XLSX.utils.aoa_to_sheet([metricsHeaders]);
            }
            XLSX.utils.sheet_add_aoa(wsMetrics, [metricsHeaders], { origin: "A1" });
    
            // --- Process Impact Stories ---
            let wsImpact;
            let impactHeaders = impactStories.length
                ? Object.keys(impactStories[0]).map(formatHeader)
                : ["No Data Available"];
    
            if (impactStories.length) {
                const formattedImpactStories = impactStories.map((item) => flattenArrayFields(item));
                wsImpact = XLSX.utils.json_to_sheet(formattedImpactStories);
            } else {
                wsImpact = XLSX.utils.aoa_to_sheet([impactHeaders]);
            }
            XLSX.utils.sheet_add_aoa(wsImpact, [impactHeaders], { origin: "A1" });
    
            // Create a new workbook
            const wb = XLSX.utils.book_new();
    
            // Append sheets with headers (even if empty)
            XLSX.utils.book_append_sheet(wb, wsMetrics, "Metrics Data");
            XLSX.utils.book_append_sheet(wb, wsImpact, "Impact Stories");
    
            // Write Excel file and trigger download
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    
            const excelFile = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
    
            saveAs(excelFile, `${title}.xlsx`);
        } catch (error) {
            console.error("Error downloading Excel file:", error.response?.data || error.message);
        }
    };
    

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Button
                bg="gray.800"
                color="white"
                rightIcon={<DownloadIcon boxSize={4} />}
                _hover={{ bg: "gray.700" }}
                onClick={(metricLength > 0 || impactLength > 0) ? printPDF : onOpen}
                mr={'5px'}
            >
                PDF
            </Button>
            <Button
                bg="gray.800"
                color="white"
                rightIcon={<DownloadIcon boxSize={4} />}
                _hover={{ bg: "gray.700" }}
                onClick={(metricLength > 0 || impactLength > 0) ? downloadPPT : onOpen}
                mr={'5px'}
            >
                PPT
            </Button>
            <Button
                bg="gray.800"
                color="white"
                rightIcon={<DownloadIcon boxSize={4} />}
                _hover={{ bg: "gray.700" }}
                onClick={(metricLength > 0 || impactLength > 0) ? DownloadXls : onOpen}
            >
                XLS
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay zIndex={'99999'} />
                <ModalContent>
                    {/* <ModalHeader>Popup Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>The report does not contain any approved metric data within the selected date range. </Text>
                        <Text>Would you still like to download it?</Text>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button
                            bg="gray.800"
                            color="white"
                            rightIcon={<DownloadIcon boxSize={4} />}
                            _hover={{ bg: "gray.700" }}
                            onClick={printPDF}
                            mr={'5px'}
                        >
                            PDF
                        </Button>
                        <Button
                            bg="gray.800"
                            color="white"
                            rightIcon={<DownloadIcon boxSize={4} />}
                            _hover={{ bg: "gray.700" }}
                            onClick={downloadPPT}
                            mr={'5px'}
                        >
                            PPT
                        </Button>
                        <Button
                            bg="gray.800"
                            color="white"
                            rightIcon={<DownloadIcon boxSize={4} />}
                            _hover={{ bg: "gray.700" }}
                            onClick={DownloadXls}
                        >
                            XLS
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Download