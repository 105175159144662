import React, { useState } from 'react';
import Styles from '../managepillars.module.css';
import API from '../../../../../Services/API';
import { 
    IconButton, Tooltip, Modal, ModalOverlay, 
    ModalContent, ModalHeader, ModalBody, 
    ModalCloseButton, Button, Input, useDisclosure 
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const EditPillar = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading]  = useState(false)
    const [message, setMessage] = useState(null);
    const [pillarName, setPillarName] = useState(props.name);


    const updatePillar = () =>{
        if(pillarName === props.name){
            setMessage('No changes are made.')
            return
        }
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', parseInt(props.id))
        formData.append('name', pillarName)
        API.put(`/pillar`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            const formData1 =  new FormData()
            formData1.append('id', props.module)
            formData1.append('name', pillarName)
            API.put(`/project-modules`, formData1, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                props.getUpdate()
                onClose()
                setMessage('Updated pillar name successfully, Please reload page to see changes in sidebar')
                setIsLoading(false)
            }).catch(error=>{
                setMessage('Failed save pillar name')
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
            setMessage('Failed save pillar name')
        })
        
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Tooltip label="Edit" aria-label="Edit">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    fontSize="15px"
                    icon={<EditIcon />}
                    className={Styles.edit}
                    onClick={onOpen}
                />
            </Tooltip>

            {/* Edit Modal */}
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Pillar</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder="Enter new pillar name"
                            defaultValue={pillarName}
                            onChange={(e) => setPillarName(e.target.value)}
                        />
                        <Button mt={4} colorScheme="blue" onClick={updatePillar}>
                            Save Changes
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditPillar;
