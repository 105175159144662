import React, { useState } from 'react';
import Styles from '../managepillars.module.css';
import API from '../../../../../Services/API';
import { 
    IconButton, Tooltip, Modal, ModalOverlay, 
    ModalContent, ModalHeader, ModalBody, 
    ModalCloseButton, Button, Input, useDisclosure, 
    Text,
    Box
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DeletePillar = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading]  = useState(false)
    const [message, setMessage] = useState(null);
    const [pillarName, setPillarName] = useState(props.name);

    const deletePillar = () =>{
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', parseInt(props.id))
        formData.append('visible', false)
        API.put(`/pillar`, formData, 
            {
                headers: {'Authorization': 'Bearer ' + token}
            }
        ).then(response =>{
            onClose()
            props.getUpdate()
            setIsLoading(false)
            setMessage('Deleted pillar successfully')
        }).catch(error=>{
            setIsLoading(false)
            setMessage('Failed to delete pillar')
        })
    }
    
    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Tooltip label="Delete" aria-label="Delete">
                <IconButton
                    variant="solid"
                    aria-label="Delete"
                    fontSize="15px"
                    icon={<DeleteIcon />}
                    className={`${Styles.edit} ${Styles.leftMinus10}`}
                    onClick={onOpen} // Open modal on click
                />
            </Tooltip>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Deletion</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Are you sure you want to delete this organization? This action cannot be undone.
                        </Text>
                        <Button mt={4} colorScheme="blue" onClick={deletePillar} float={'right'}>
                            Delete
                        </Button>
                        <Box className='clearfix'></Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeletePillar