import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, FormControl, Switch } from "@chakra-ui/react";
import Permissons from "../../Modules/Permissions/Permissons";
import Styles from "./Breadcrumbsnav.module.css";
import { EditContext } from "../../../Context/BreadcrumbsContext";
import API from "../../../Services/API";
import Search from '../../Modules/Search/Search'

function BreadCrumbsNav(props) {
  const token = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [pageHeading, setPageHeading] = useState();
  const {edit, setEdit, setBlogPermission,hasAllPermission,setProjectModuleArray, setTagPermission, setCommentPermission, setTagEditPermission } = useContext(EditContext);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [permissionType, setPermissionType] = useState(() => {
    // Initialize state from localStorage
    const storedPermission = localStorage.getItem("permissionType");
    return storedPermission || ""; // Default to empty string if not found
  });

  const [newPath, setNewPath] = useState(null)
  const [impctglry, setImpctglry] = useState(null)

  useEffect(() => {
    const decodedPathname = decodeURIComponent(location.pathname);
    const segments = decodedPathname.split('/'); // Split the path by '/'
    const windowLocation = window.location.pathname;
    const metricPath = windowLocation.split('/').slice(0, 2).join('/');
    const ImpactMetricSegments = windowLocation.split('/');
    const getImpactMetricPath = `/${ImpactMetricSegments[2]}`
    const getAboutSionPath = windowLocation.split('/')[2]
    
    setNewPath(metricPath)
    if (segments.length > 4) {
      setImpctglry(segments[4])
    }
    
    API.get(`/project-modules`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      setProjectModuleArray(response && response?.data)
      const windowLocation = window.location.pathname;
      const metricPath = windowLocation.split('/').slice(0, 2).join('/');
      const faqPath = windowLocation.split('/').slice(0, 3).join('/');
      const match = windowLocation.match(/^\/(?:mytasks\/)?metric\/(\d+)/);

      if(windowLocation === "/" || windowLocation === "/dashboard"){
        const homeData = response.data.find((module) => module.unique_name === 'home');
        API.get(`/get-specific-modules?module_id=${homeData?.id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else if(match){
        const windowLocation = window.location.pathname;
        const pathParts = windowLocation.split('/');
        //const firstExtractedId = pathParts[2];

        const firstExtractedId = match[1];


        API.get(`/get-specific-modules?metric_id=${firstExtractedId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else if(faqPath === '/help/faq'){
        const help = windowLocation.split('/')
        const helpId = help[3];
        API.get(`/get-specific-modules?module_id=${253}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
      
        });
      }else if(metricPath === '/portfolio'){
        const portfolio = windowLocation.split('/')
        const portfolioId = portfolio[3];
        API.get(`/get-specific-modules?module_id=${portfolioId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          const comms_user = response?.data?.comms_user;
          const elt_user = response.data.elt_user;
          const super_user = response?.data?.superuser;
          const action_office_user = response?.data?.action_office_user;
          const pillar_owner = response?.data?.position === "pillar_owner";
          const action_owner = response?.data?.position === "action_owner";
          const hasBlogPermisstion =
          comms_user  || action_office_user  || super_user;
          const hasTagPermisstion = comms_user || action_office_user || super_user;
          const hasCommentPermisstion =action_office_user ||super_user ;
          const hasTagApplyagPermission= comms_user|| action_office_user|| super_user
          
          setTagEditPermission(hasTagApplyagPermission);
          setCommentPermission(hasCommentPermisstion);
          setBlogPermission(hasBlogPermisstion);
          setTagPermission(hasTagPermisstion);
          setPermissionType(permission);
        }).catch(error=>{
          
        });
      }else if(getAboutSionPath === 'asio'){
        const asioLocation = windowLocation.split('/')
        const asioLocationId = asioLocation[3];
        API.get(`/get-specific-modules?module_id=${asioLocationId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        }).catch(error=>{
          
        });
      }else{
        const pathParts = windowLocation.split('/');
        const firstExtractedId = pathParts[2];
        const secondExtractedId = pathParts[4];
        const pillarId = parseInt(firstExtractedId)
        const actionId = parseInt(secondExtractedId)
        if((getImpactMetricPath !== '/impactmetrics') ){
          if(pillarId){
            API.get(`/get-specific-modules?module_id=${actionId ? actionId : pillarId}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then(response => {
              const permission = response?.data?.permission;
              setPermissionType(permission);
            }).catch(error=>{
              
            })
          }else if(actionId){
            API.get(`/get-specific-modules?module_id=${actionId ? actionId : pillarId}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then(response => {
              const permission = response?.data?.permission;
              setPermissionType(permission);
            }).catch(error=>{
              
            })
          }
        }else if(getImpactMetricPath === '/impactmetrics'){
          API.get(`/get-specific-modules?metric_id=${pathParts && pathParts[3]}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response => {
            const permission = response?.data?.permission;
            setPermissionType(permission);
          }).catch(error=>{
            
          })
        }
        
      }
    }).catch(error=>{
      
    })
  }, [location.pathname]);
  
  const editPage = (event) => {
    setEdit(event.target.checked)
  };
  
  const containsOnlyNumbers = (str) => {
    return /^\d+$/.test(str);
  };
  
  return (
    <>
      <nav aria-label="breadcrumb" style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        <ol className="breadcrumb" style={{ margin: "0", maxWidth: "65%", width: "710px", overflow: "hidden", }}>
          <li className="breadcrumb-item" style={{background:"transparent"}}>
            <a href="/" style={{ color: "rgb(109 109 110)", backgroundColor: "rgb(231 251 255)" }}>
              Home
            </a>
          </li>
          {pathnames.map((path, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;

            return path !== "action" &&
              path !== "metric" &&
              path !== "article" &&
              path !== "asio" &&
              path !== "pillar" &&
              path !== "initiative" &&
              path !== "qtr" &&
              path !== "yeargoal" &&
              path !== "fy" &&
              path !== "tg" &&
              path !== "viewpost" &&
              path !== "portfolio" &&
              path !== "ig" &&
              path !== "region" &&
              path !== "country" &&
              path !== "team" &&
              path !== "faq" &&
              path !== "view" &&
              path !== "impactmetrics" &&
              path !== "tguides" &&
              containsOnlyNumbers(decodeURIComponent(path)) === false ? (
              <li
                key={index}
                className={`breadcrumb-item ${isLast ? "active" : ""}`}
              >
                {isLast ? (
                  <span className={Styles.cap}>
                    {
                      decodeURIComponent(path) === "mytasks" ? "My Tasks" : 
                      decodeURIComponent(path) === "searchresults" ? "search Results" :
                      decodeURIComponent(path)
                    }
                  </span>
                ) : (
                  <Link onClick={()=>navigate(-1)}>{decodeURIComponent(path)}</Link>
                )}
              </li>
            ) : null;
          })}
        </ol>
        <Box display={'flex'}>
        <ol style={{ margin: 0 }}>
          <li style={{ listStyle: "none" }}>
          <Box float={'left'} mr={'20px'}>
            <Search />
          </Box>
          </li>
        </ol>
        <ol style={{ margin: 0 }}>
          <li style={{ listStyle: "none" }}>
          <Box float={'left'}>
            <FormControl display="flex" alignItems="center" className="editMain permissionUpdate" style={{ margin: 0 }}>
              {
                (impctglry === 'Impact gallery') || 
                (decodeURIComponent(location.pathname.split('/').pop()) === 'profile') ||
                ((decodeURIComponent(location.pathname.split('/').pop()) === 'Impact Gallery') &&
                (permissionType && permissionType === 'viewer') )||
                ((location.pathname.includes("portfolio") && location.pathname.includes("viewpost") && location.pathname.includes("Impact%20Gallery")) &&
                (permissionType && permissionType === 'viewer') )||
                (permissionType && permissionType !== 'viewer') || (hasAllPermission) ? 
                (
                  <Switch
                    id="edit-toggle"
                    size="md"
                    isChecked={edit}
                    onChange={editPage}
                    aria-label={edit ? "Disable edit mode" : "Enable edit mode"}
                  />
                ) : null 
              }
            </FormControl>
          </Box>
          
          </li>
          
        </ol>
                    </Box>
      </nav>
    </>
  );
}

export default BreadCrumbsNav;
