import React, { useState, useEffect, useContext } from 'react';
import Styles from './organization.module.css'
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Button, Image, Stack, CardFooter} from '@chakra-ui/react'
import ManageThemes from './ManageThemes/ManageThemes'
import ManageOrganizations from './ManageOrganizations/ManageOrganizations';
import ManagePillars from './ManagePillars/ManagePillars';

const Organization = () => {
    const [activeTab, setActiveTab] = useState('manageorganizations');
    const renderTabContent = () => {
        switch (activeTab) {
            case 'manageorganizations': return <ManageOrganizations />;
            case 'managethemes': return <ManageThemes />;
            case 'managepillars': return <ManagePillars />;
            default : return null;
        }
    };

    return (
        <>
            <Card mt={'0px'}>
                <CardHeader pb={'0px'}>
                    <Heading size='md' fontSize={"18px"} fontWeight={"500"} mb={'10px'}>Organization Manager</Heading>
                </CardHeader>
                <CardBody pt={'0px'}>
                    <Card direction={{ base: 'column', sm: 'row' }} overflow='hidden' variant='outline'>
                        <Box className={Styles.tabGroup} maxW={{ base: '100%', sm: '200px' }}>
                            <Button onClick={() => setActiveTab('manageorganizations')} className={activeTab === 'manageorganizations' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Manage Organizations</Button>
                            <Button onClick={() => setActiveTab('managethemes')} className={activeTab === 'managethemes' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Manage Themes</Button>
                            <Button onClick={() => setActiveTab('managepillars')} className={activeTab === 'managepillars' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Manage Pillars</Button>
                        </Box>
                        <Stack width={'100%'}>
                            <CardBody p={'0px'}>
                                <Box className={Styles.tabContent}>
                                    <>
                                        {renderTabContent()}
                                    </>
                                </Box>
                            </CardBody>
                        </Stack>
                    </Card>
                </CardBody>
            </Card>
        </>
    )
}

export default Organization