import React, { useState, useEffect, useContext } from 'react';
import Styles from './ManageThemes.module.css'
import API from '../../../../Services/API';
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Button, Image, SimpleGrid} from '@chakra-ui/react'
import emptyIcon from '../../../../../assets/images/emptyIcon.jpg'
import CreateTheme from './CreateTheme/CreateTheme';
import DeleteTheme from './DeleteTheme/DeleteTheme';

const ManageThemes = () => {
    const token = window.localStorage.getItem("accessToken")
    const [themes, setThemes] = useState([])
    const [themseLength, setThemesLength] = useState(1)

    useEffect(()=>{
            getUpdate()
    },[])

    const getUpdate = () =>{
        API.get('/themes',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(response=>{
            API.get('/get-organisations-data',{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then(metrics=>{
                console.log("metrics,,,P", metrics)
            }).catch(error=>{
            
            })
            setThemes(response.data)
            setThemesLength(response.data.length)
        }).catch(error=>{
            
        })
    }

    return (
        <>
            <Box width={'100%'} borderBottom={'1px solid #dadada'} height={'45px'} position={'relative'}>
                <Box float={'left'} className={Styles.headingParent}>
                    <Heading as={'h3'} className={Styles.heading}> Themes </Heading>
                </Box>
                <CreateTheme getUpdate={getUpdate} componentValue={"create"} />
                <Box className='clearfix'></Box>
            </Box>
            <Box mt={'5px'}>
                {
                    themseLength === 0 && <Box className={Styles.emptyCOntainer}>
                        <Image src={emptyIcon} m={'0 auto'} />
                        <Text textAlign={'center'} fontSize={'12px'} mb={'0px'}> No Organizations currently </Text>
                        <Text textAlign={'center'} fontSize={'12px'}> <a href='#n' style={{color:'#1890ff'}} > Click here</a> to create a organization </Text>
                    </Box>
                }
                {/* {
                    themes.map(theme =>
                        <Box className={Styles.entryContainer}>
                            <Heading as={'h3'}>
                                {theme.name}
                                <CreateTheme id={theme.id} getUpdate={getUpdate} componentValue={"edit"} />
                            </Heading>
                            <Box className={Styles.entryInnerContainer}>

                            </Box>
                        </Box>
                    )
                } */}

                <SimpleGrid columns={[2, null, 3]} spacing='10px' mb={'10px'}>
                    {
                        themes.map(theme=>
                            <Box className={Styles.Tag}>
                                <Box className={Styles.tagInnerText}>
                                    <Box float={'left'} textTransform={'capitalize'}>
                                        {theme.name}
                                    </Box>
                                    <Box float={'right'}>
                                        <CreateTheme id={theme.id} getUpdate={getUpdate} componentValue={"edit"} />
                                        <DeleteTheme id={theme.id} getUpdate={getUpdate} />
                                    </Box>
                                    <Box className='clearfix'></Box>
                                </Box>
                            </Box>
                        )
                    }
                </SimpleGrid>
            </Box>
        </>
        
    )
}

export default ManageThemes