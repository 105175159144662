import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box, Button, SimpleGrid, Card, CardBody, Text, Stack,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Input, useDisclosure,
  IconButton, Textarea,
  FormControl,
  FormErrorMessage,
  Image,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';
import EmptyImg from "../../../../../assets/images/No_image_available.svg.png";
import API from '../../../../Services/API';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Select from "react-select";
import JoditEditor from 'jodit-react';
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import $ from 'jquery'
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
function Country() {
  const toast = useToast();
  const editor = useRef(null);
  const token = window.localStorage.getItem("accessToken");
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [nameCode, setNameCode] = useState("");
  const [nameIso3Code, setNameIso3Code] = useState("");
  const [regionId, setRegionId] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(null)
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose
  } = useDisclosure();

  const [deletingId, setDeletingId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [bannerPreview, setBannerPreview] = useState("");
  const { edit, hasAllPermission } = useContext(EditContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log("object", process.env.REACT_APP_API_BASE_URL + "region")
  // ✅ Get All Regions and Countries
  const getAllData = async () => {
    try {
      setLoading(true)
      const regionResponse = await API.get('/region/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setRegions(regionResponse.data.map(region => ({
        value: region.id,
        label: region.region_name
      })));

      const countryResponse = await API.get("/country/", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setCountries(countryResponse.data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  // ✅ Filter Countries Based on Region Selection
  useEffect(() => {
    if (regionId) {
      const filtered = countries.filter(country => country.region === regionId);
      setFilteredCountries(filtered.map(country => ({
        value: country.id,
        label: country.country_name
      })));
    } else {
      setFilteredCountries([]);
    }
  }, [regionId, countries]);


  const validate = () => {
    console.log("code", typeof nameCode === "number", typeof nameCode)
    const newErrors = {};
    if (!name) newErrors.name = "Country name is required";
    if (!regionId) newErrors.regionId = "Region name is required";
    if (!nameCode) newErrors.nameCode = "Country code is required";
    if (nameCode && nameCode.length > 2) newErrors.nameCode = "Country code must contain 2 character";
    if (nameCode && typeof nameCode === "number") newErrors.nameCode = "Country code must contain 2 character";
    if (!nameIso3Code) newErrors.nameIso3Code = "Country iso3 code is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // ✅ Create or Update Item
  const saveItem = async () => {
    setMessage(null)
    if (!validate()) return;

    const formData = new FormData();
    formData.append("country_name", name);
    formData.append("country_cd", nameCode);
    formData.append("iso3_code", nameIso3Code);
    formData.append("region", regionId);
    formData.append('description', $("#description").val())
    formData.append("visible", true);
    if (banner && banner?.type?.startsWith('image/')) {
      formData.append("banner", banner);
    }
    if (editingItem) {
      formData.append("id", editingItem.id);
    }

    try {
      if (editingItem) {
        // Update
        await API.put(`/country/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        setMessage("Country updated successfully.")
      } else {
        // Create
        await API.post('/country/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        setMessage("Country created successfully.")

      }
      // resetForm();
      setBannerPreview(null)
      onClose();
      getAllData();
    } catch (error) {
      console.error(`Error ${editingItem ? "updating" : "creating"} item:`, error);
      if (error?.response?.data && error?.response?.data?.country_name != undefined) {
        setMessage(`${error?.response?.data?.country_name[0]}`)
      } else if (error?.response?.data && error?.response?.data?.country_cd != undefined) {
        setMessage(`${error?.response?.data?.country_cd[0]}`)
      } else if (error?.response?.data && error?.response?.data?.iso3_code != undefined) {
        setMessage(`${error?.response?.data?.iso3_code[0]}`)
      } else {

        setMessage(`Failed to ${editingItem ? "update" : "create"} the country.`)
      }
      setLoading(false);

    }
  };



  const handleDelete = async (id) => {
    setMessage(null)
    setIsDeleting(true);
    setLoading(true);
    try {
      await API.delete(`/country/`, {
        data: { id: deletingId },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setMessage('Country deleted successfully.')

      setIsDeleting(false);
      getAllData();
    } catch (error) {
      setMessage('Failed to delete country.')

    }
    setLoading(false);
    setIsDeleting(false);
    onDeleteClose();
  };


  // ✅ Handle Editing
  const handleEdit = (item) => {
    setMessage(null)
    setName(item?.country_name);
    setNameCode(item?.country_cd);
    setNameIso3Code(item?.iso3_code)
    setRegionId(item?.region);
    setDescription(item?.description || "");
    setBanner(null);
    setEditingItem(item);
    onOpen();
  };

  // ✅ Reset Form
  const resetForm = () => {
    setMessage(null)
    setNameCode("");
    setNameIso3Code("");
    setName("");
    setErrors({});
    setRegionId("");
    setDescription("");
    setBanner(null);
    setEditingItem(null);
  };

  const customSelectStyles = (errors) => ({
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px', // Match Chakra input border radius
      padding: '2px 10px', // Match Chakra input padding
      borderColor: errors
        ? 'red'
        : state.isFocused
          ? '#3182CE' // Chakra focus color
          : '#E2E8F0', // Chakra border color
      borderWidth: '1px',
      backgroundColor: 'white',
      boxShadow: state.isFocused
        ? '0 0 0 1px #3182CE' // Focus ring like Chakra
        : errors
          ? '0 0 0 1px red'
          : 'none',
      '&:hover': {
        borderColor: errors ? 'red' : '#CBD5E0', // Chakra hover color
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10, // Ensure dropdown appears above other components
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', // Soft shadow like Chakra popover
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0', // Chakra placeholder color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1A202C', // Chakra text color
    }),
    input: (provided) => ({
      ...provided,
      color: '#1A202C', // Input text color
    }),
  });

  return (
    <>
      {message && <ErrorWidget message={message} />}
      <Box position="relative" p={4}>
        <Button
          position="absolute"
          top={0}
          right={5}
          color="white"
          bg="blue.500"
          onClick={() => {
            resetForm();
            onOpen();
          }}
        >
          Add Country
        </Button>
        {loading ? (
          <LoaderSpinner />
        ) : (
          <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5} mt={10}>
            {countries.map((country) => (
              <Card key={`country-${country.id}`} size="sm">
                <CardBody>
                  <Stack direction="row" justify="space-between" align="flex-start">
                    <Box display={'flex'} flexDirection={"row"} gap={3}>
                      <Image src={country.banner || EmptyImg} boxSize="60px" objectFit="cover" />
                      <Box>
                        <Text fontSize="medium" color="gray.600" mb={1} >
                          {regions.find(region => region.value === country.region)?.label}
                        </Text>
                        <Text fontSize="small" fontWeight="">
                          {country.country_name}
                        </Text>
                      </Box>
                    </Box>
                    {(edit && hasAllPermission) && <Box gap={4} flexDirection={'column'} display={'flex'}>

                      <IconButton
                        variant="solid"
                        aria-label="Edit"
                        size={"small"}
                        fontSize="14px"
                        icon={<EditIcon />}
                        onClick={() => handleEdit(country)}
                      />
                      <IconButton
                        variant="solid"
                        aria-label="Edit"
                        size={"small"}
                        fontSize="14px"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          setDeletingId(country.id);
                          onDeleteOpen();
                        }}
                      />
                    </Box>}
                  </Stack>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        )}
        {/* Create/Edit Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={"medium"}>
              {editingItem ? `Edit Country` : "Create Country"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Select Region */}
              <FormControl mb={3} isInvalid={errors.regionId}>
                <Select
                  placeholder="Select Region"
                  options={regions}
                  value={regions.find(region => region.value === regionId)}
                  onChange={(e) => setRegionId(e.value)}
                  mb={3}
                  styles={customSelectStyles(errors.regionId)}
                />
                <FormErrorMessage>{errors.regionId}</FormErrorMessage>
              </FormControl>

              {/* Country Name */}
              <FormControl mb={3} isInvalid={errors.name}>
                <Input
                  placeholder="Country Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  mb={3}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>

              </FormControl>
              {console.log("errors", errors)}
              {/* Country Name */}
              <FormControl mb={3} isInvalid={errors.nameCode}>

                <Input
                  type=''
                  placeholder="Country code"
                  value={nameCode}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^A-Za-z]/g, ""); // Remove non-alphabet characters
                    setNameCode(newValue)
                  }}
                  mb={3}
                />
                <FormErrorMessage>{errors.nameCode}</FormErrorMessage>
              </FormControl>
              {/* Country Name */}
              <FormControl mb={3} isInvalid={errors.nameIso3Code}>

                <Input
                  placeholder="Country iso3 code"
                  value={nameIso3Code}
                  onChange={(e) => setNameIso3Code(e.target.value)}
                  mb={3}
                />
                <FormErrorMessage>{errors.nameIso3Code}</FormErrorMessage>
              </FormControl>

              {/* Banner Upload */}
              <Input
                type="file"
                onChange={(e) => {
                  setBanner(e.target.files[0])
                  setBannerPreview(URL.createObjectURL(e.target.files[0]));
                }}
                mb={3}
              />
              {bannerPreview && (
                <Image src={bannerPreview} boxSize="100%" objectFit="cover" mb={3} />
              )}
              {/* Description */}
              <JoditEditor
                id={"description"}
                ref={editor}
                config={{
                  readonly: false,
                  tabIndex: "0"
                }}
                value={description}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => { }}
              />


            </ModalBody>
            <ModalFooter background={"transparent"}>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={saveItem}>
                {editingItem ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={isDeleteOpen}
          leastDestructiveRef={undefined}
          onClose={onDeleteClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Deletion
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete this country?
              </AlertDialogBody>
              <AlertDialogFooter background={"transparent"}>
                <Button onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </>
  );
}

export default Country;
