import React, { useEffect, useState } from 'react';
import API from '../../../../../Services/API';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { Button } from 'bootstrap';

const Pillars = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [report, setReport] = useState(null);
    const [pillars, setPillars] = useState([]);
    const [actions, setActions] = useState([]);
    const [tags, setTags] = useState([]);
    const [metrics, setMetrics] = useState([]);


    // Fetch data in a single useEffect hook to optimize the process
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetching report, pillars, and tags concurrently using Promise.all
                const [reportResponse, pillarResponse, tagResponse] = await Promise.all([
                    API.get(`/custom-country-report?report_id=${props.id}`, { headers: { Authorization: `Bearer ${token}` } }),
                    API.get(`/sio-pillar`, { headers: { Authorization: `Bearer ${token}` } }),
                    API.get(`/tag-detail/`, { headers: { Authorization: `Bearer ${token}` } })
                ]);

                // Setting report data
                const reportData = reportResponse.data?.[0] || {};
                setReport(reportData);

                // Setting pillars data with selected state based on report
                const selectedPillars = reportData.pillars || [];
                setPillars(
                    pillarResponse.data
                        .map(pillar => ({
                            ...pillar,
                            selected: selectedPillars.some(selected => selected.id === pillar.id),
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort pillars alphabetically
                );

                // Ensure tags is always an array before setting state
                const selectedTags = reportData.tags || [];
                setTags(
                    tagResponse.data.results
                        .map(tag => ({
                            ...tag,
                            selected: selectedTags.some(selected => selected.id === tag.id),
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort tags alphabetically
                );

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (props.id) fetchData();
    }, [props.id, token]); // Run this effect whenever `id` or `token` changes

    // Fetch actions based on selected pillars
    useEffect(() => {
        const fetchActions = async () => {
            try {
                const selectedPillarIds = pillars.filter(p => p.selected).map(p => p.id);
                if (selectedPillarIds.length > 0) {
                    const actionResponse = await API.get(`/multi-action-pillar?pillar_id=${JSON.stringify(selectedPillarIds)}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    const selectedActions = report?.actions || [];
                    setActions(
                        actionResponse.data
                            .map(action => ({
                                ...action,
                                selected: selectedActions.some(selected => selected.id === action.id),
                            }))
                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort actions alphabetically
                    );
                } else {
                    setActions([]); // Reset actions if no pillars are selected
                }
            } catch (error) {
                console.error("Error fetching actions:", error);
            }
        };

        fetchActions();
    }, [pillars, report, token]); // Trigger when `pillars` or `report` changes

    // Fetch metrics based on selected pillars, actions, and tags
    useEffect(() => {
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        const selectedActions = actions.filter(a => a.selected).map(a => a.id);
        const selectedTags = tags.filter(t => t.selected).map(t => t.id);

        console.log('Selected selectedGeographies:', props.selectedGeographies)
        const regions = [...new Set(props.selectedGeographies.map((item) => item.region_id).filter(Boolean))];
        const countries = [...new Set(props.selectedGeographies.map((item) => item.country_id).filter(Boolean))];
        const states = [...new Set(props.selectedGeographies.map((item) => item.state_id).filter(Boolean))];
        const cities = [...new Set(props.selectedGeographies.map((item) => item.city_id).filter(Boolean))];


        let queryParams = [];

        // if(selectedPillars.length && selectedActions.length && selectedTags.length){
        //     queryParams.push(`action_id=${selectedActions.join(",")}`);
        //     queryParams.push(`tags_id=${selectedTags.join(",")}`);
        // }else if(selectedPillars.length && selectedTags.length){
        //     queryParams.push(`pillar_id=${selectedPillars.join(",")}`);
        //     queryParams.push(`tags_id=${selectedTags.join(",")}`);
        // }else if(selectedActions.length){
        //     queryParams.push(`action_id=${selectedActions.join(",")}`);
        // }else if(!selectedActions.length && selectedPillars.length){
        //     queryParams.push(`pillar_id=${selectedPillars.join(",")}`);
        // }


        if(selectedPillars.length)queryParams.push(`pillar_id=${selectedPillars.join(",")}`);
        if(selectedActions.length)queryParams.push(`action_id=${selectedActions.join(",")}`);
        if(selectedTags.length)queryParams.push(`tags_id=${selectedTags.join(",")}`);
        if (regions.length) queryParams.push(`region_report=${regions.join(",")}`);
        if (countries.length) queryParams.push(`country_report=${countries.join(",")}`);
        if (states.length) queryParams.push(`state_report=${states.join(",")}`);
        if (cities.length) queryParams.push(`city_report=${cities.join(",")}`);
        
        if (queryParams.length === 0) {
            setMetrics([]);
            return;
        }
        const url = `/metric-list-report?${queryParams.join("&")}`;
        API.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            const fetchedMetrics = response.data;
            const selectedMetrics = report?.metrics || [];
            setMetrics(
                fetchedMetrics.map(m => ({
                    ...m,
                    selected: selectedMetrics.some(selected => selected.id === m.id)
                }))
            );
        })
        .catch(error => console.error("Error fetching metrics:", error));
    }, [pillars, actions, tags, report, token, props.selectedGeographies]);

    const toggleSelection = (id, setState) => {
        setState(prev => {
            const updated = prev.map(item => (item.id === id ? { ...item, selected: !item.selected } : item));
            return updated;
        });
    };

    useEffect(() => {
        const selectedTags = tags.filter(t => t.selected).map(t => t.id);
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        const selectedActions = actions.filter(a => a.selected).map(a => a.id);
        const selectedMetrics = metrics.filter(m => m.selected).map(m => m.id);

        props.getTags(selectedTags)
        props.getPillars(selectedPillars)
        props.getActions(selectedActions)
        props.getMetrics(selectedMetrics);
    }, [pillars, actions, tags, report, token, metrics]);

    return (
        <>
            {/* Tags Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Tags</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform="capitalize">
                    {tags.map(tag => (
                        <div key={tag.id} onClick={() => { toggleSelection(tag.id, setTags);}}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {tag.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{tag.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Pillars Section */}
            <FormControl mt="15px" mb="15px">
                <FormLabel fontWeight="600">Pillars</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform="capitalize">
                    {pillars.map(pillar => (
                        <div key={pillar.id} onClick={() => { toggleSelection(pillar.id, setPillars);}}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {pillar.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{pillar.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Action Teams Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Action Teams</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform="capitalize">
                    {actions.map(action => (
                        <div key={action.id} onClick={() => { toggleSelection(action.id, setActions);}}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {action.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{action.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Metrics Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Metrics</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px" textTransform="capitalize">
                    {metrics.map(metric => (
                        <div key={metric.id} onClick={() => { toggleSelection(metric.id, setMetrics);}}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {metric.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{metric.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>
        </>
    );
};

export default Pillars;
