import React, { useEffect, useState } from "react";
import { Box, Button, Text, VStack, Icon, Flex,} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaRegFileAlt } from "react-icons/fa";
import RegionMap from "../RegionMap/RegionMap";
import API from "../../../Services/API";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FormControl,FormLabel,Input,Switch,FormControlLabel,} from "@chakra-ui/react";
import { SimpleGrid } from '@chakra-ui/react'
import { format } from "date-fns";

const EirInnerComponent = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const [selectedGeographies, setSelectedGeographies] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [includeImpactStories, setIncludeImpactStories] = useState(false);
  const [geographies, setGeographies] = useState([]);
  const removeGeography = (id) => {
    setGeographies(geographies.filter((geo) => geo.id !== id));
  };
  
  useEffect(()=>{
    API.get(`/get-geographical-data`, {
      headers: { 'Authorization': 'Bearer ' + token },
    })
  },[])

  const getSelectedGeographies = (event) =>{
    const newLocation = {
      id: Date.now(),
      region: event.region,
      regionId: event.regionId,
      country: event.country,
      countryId: event.countryId,
      state: event.state,
      stateId: event.stateId,
      city: event.city,
      cityId: event.cityId
    };
    setGeographies([...geographies, newLocation])
    props.getSelectedGeographies([...geographies, newLocation])
  }

  return (
    <Box>
      <RegionMap type="eir" getSelectedGeographies={getSelectedGeographies} />
      <FormControl mt={'15px'} mb="0px">
        <FormLabel fontWeight="600">Selected Geographies</FormLabel>
        <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px">
          {
            geographies.length === 0 ? (
              <VStack mt={4} spacing={2} textAlign="center">
                <Icon as={FaRegFileAlt} boxSize={8} color="gray.400" />
                <Text color="gray.500">No geographies selected</Text>
              </VStack>
            ) : (
              geographies.map((geo) => (
                <Flex
                  key={geo.id}
                  bg="#cbcbcb"
                  p={'5px'}
                  borderRadius="md"
                  align="center"
                  justify="space-between"
                  mb={2}
                  color="#3e4b5b"
                >
                  <Text flex="1" mb={0}>
                    <Text as={'span'} fontWeight={'bold'}>
                      {geo?.region}
                    </Text>
                    {[
                      geo?.country,
                      geo?.state,
                      geo?.city
                    ].filter(Boolean).length > 0 && ` / ${[geo?.country, geo?.state, geo?.city].filter(Boolean).join(' / ')}`}
                  </Text>
                  <Button size="xs" onClick={() => removeGeography(geo.id)} bg="transparent" _hover={{ bg: "gray.600" }}>
                    <CloseIcon boxSize={2} color="white" />
                  </Button>
                </Flex>
              ))
            )
          }
        </Box>
      </FormControl>
      <FormControl mt={'10px'} mb="0px">
        <FormLabel fontWeight="600">Select report start and end date</FormLabel>
        <SimpleGrid columns={[2, null, 3]} spacing={2} mb="0px">
          <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" padding="8px">
            <FormControl>
              <FormLabel fontWeight="600">Report Start Date</FormLabel>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  const formattedDate = date ? format(date, "MM-dd-yyyy") : "";
                  props.getStartDate(formattedDate);
                }}
                dateFormat="MM-dd-yyyy"
                placeholderText="MM-DD-YYYY"
                customInput={<Input />}
              />
            </FormControl>
          </Box>
          <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" padding="8px">
            <FormControl>
              <FormLabel fontWeight="600">Report End Date</FormLabel>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  const formattedDate = date ? format(date, "MM-dd-yyyy") : ""; // Format to MM/dd/yyyy
                  props.getEndDate(formattedDate);
                }}
                dateFormat="MM-dd-yyyy"
                placeholderText="MM-DD-YYYY"
                customInput={<Input />}
              />
            </FormControl>
          </Box>
          <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px">
            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0" fontWeight="600">Include Impact Stories:</FormLabel>
              <Switch
                isChecked={includeImpactStories}
                //onChange={() => setIncludeImpactStories(!includeImpactStories)}
                onChange={(date) => {
                  setIncludeImpactStories(!includeImpactStories);
                  props.includeImpactStories(!includeImpactStories);
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>
      </FormControl>
    </Box>
  );
};

export default EirInnerComponent;
