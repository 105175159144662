import React, { useEffect, useState } from 'react'
import './sidebar.css'
import API from '../../../../Services/API'
import $ from 'jquery'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import Face1 from '../../../../../assets/images/userPlaceholder.svg'
import SideBarMenu from './SideBarMenu/SideBarMenu';
import PillarNavigation from './PillarNavigation/PillarNavigation';
import OrganisationNavigation from './OrganisationNavigation/OrganisationNavigation';
import RegionNavigation from './RegionNavigation/RegionNavigation';
import PortfolioNavigation from './PortfolioNavigation/PortfolioNavigation';
import CreateModule from './CreateModule/CreateModule';
import HelpNavigation from './HelpNavigation/HelpNavigation';
import Logo from '../../../../Pages/Login/images/logo.png'
import {Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Button, Text,} from '@chakra-ui/react'
import { ChevronRightIcon } from "@chakra-ui/icons";

const SideBar = () => {
  const navigate = useNavigate()
  const user = JSON.parse(window.localStorage.getItem("user"));
  const token = window.localStorage.getItem("accessToken");
  const [profile, setProfile] = useState("");
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [basicUiMenuOpen, setBasicUiMenuOpen] = useState(false);
  const [pillars, setPillars] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [help, setHelp] = useState(false);
  const [region, setRegion] = useState(false);
  const [isLoading, setIsLoading] = useState()
  const [menuList, setMenuList] = useState()
  const [menuOpenSelected, setMenuOpenSelected] = useState(null)

  useEffect(() => {

    API.get(`/user-management`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setProfile(response.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })

    API.get(`/get-organisations-data-nav`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      console.log("OrganisationNavigation", response.data)
      setMenuList(response.data)
      // window.localStorage.setItem('menuList', JSON.stringify(response.data))
      // $('.subMenuNew:empty').remove()
      // $('.subMenuNew').closest('li').addClass('acordian')
    }).catch(error => {
      
    });


    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  },[]);

  const openMenu = (menuId) => {
    setMenuOpenSelected(menuOpenSelected === menuId ? null : menuId); // Toggle open state
  };

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={(evt) => evt.preventDefault()}>
              <div className="nav-profile-image">
                <img src={profile && profile.profile_picture !== null ? profile && profile.profile_picture : Face1 } alt={profile && profile.first_name+' '+profile && profile.last_name} />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text profileNew">
                <span className="font-weight-bold mb-2">
                  <>
                    {profile && profile.first_name} {profile && profile.last_name}
                  </>
                </span>
                <span className="text-small" style={{ color: "rgb(109, 109, 110)", backgroundColor: "rgb(231, 251, 255)" }}>
                  {profile && profile.role}
                </span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>

          <li className={'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title"><>Home</></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/mytasks">
              <span className="menu-title"><>My Tasks</></span>
              <i className="mdi mdi-format-list-bulleted  menu-icon"></i>
            </Link>
          </li>
          <li >

         
          <Accordion allowToggle>
            <AccordionItem bg="transparent" border={'none'}>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize={'0.875rem'} lineHeight={'1'} verticalAlign={'middle'} color={'#3e4b5b'}>
                  Social Impact & Inclusion Office
                </Box>
                <AccordionIcon color={'rgba(187, 168, 191, 0.9607843137)'} mr={'8px'} />
                <i className="mdi mdi-domain menu-icon" title={'Pillars'} 
                  style={{
                    color: 'rgba(187, 168, 191, 0.9607843137)',
                    fontSize: '1.125rem'
                  }}
                ></i>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <PillarNavigation />
              </AccordionPanel>
            </AccordionItem>
            {
              menuList && menuList.map(menu=>
                <AccordionItem bg="transparent" border={'none'}>
                  <AccordionButton textTransform={'capitalize'}>
                    <Box as='span' flex='1' textAlign='left' fontSize={'0.875rem'} lineHeight={'1'} verticalAlign={'middle'} color={'#3e4b5b'}>
                      {menu.name}
                    </Box>
                    <AccordionIcon color={'rgba(187, 168, 191, 0.9607843137)'} mr={'8px'} />
                    <i className="mdi mdi-domain menu-icon" title={'Pillars'} 
                      style={{
                        color: 'rgba(187, 168, 191, 0.9607843137)',
                        fontSize: '1.125rem'
                      }}
                    ></i>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <OrganisationNavigation themeList={menu.themes_list} />
                  </AccordionPanel>
                </AccordionItem>
              )
            }
            <AccordionItem bg="transparent" border={'none'}>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize={'0.875rem'} lineHeight={'1'} verticalAlign={'middle'} color={'#3e4b5b'}>
                  Region
                </Box>
                <AccordionIcon color={'rgba(187, 168, 191, 0.9607843137)'} mr={'8px'} />
                <i class="mdi mdi-earth menu-icon" title="Region" 
                  style={{
                    color: 'rgba(187, 168, 191, 0.9607843137)',
                    fontSize: '1.125rem'
                  }}
                ></i>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <RegionNavigation />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem bg="transparent" border={'none'}>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize={'0.875rem'} lineHeight={'1'} verticalAlign={'middle'} color={'#3e4b5b'}>
                  Portfolio
                </Box>
                <AccordionIcon color={'rgba(187, 168, 191, 0.9607843137)'} mr={'8px'} />
                <i class="mdi mdi-routes menu-icon" title="Portfolio" 
                  style={{
                    color: 'rgba(187, 168, 191, 0.9607843137)',
                    fontSize: '1.125rem'
                  }}
                ></i>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <PortfolioNavigation />
              </AccordionPanel>
            </AccordionItem>
            <ul className="nav-item">
            <li  style={{ listStyle: "none" }}>
              <Link className="nav-link" to="/customreports">
                <span className="menu-title">Custom Reports</span>
                <i className="mdi mdi-format-list-bulleted  menu-icon"></i>
              </Link>
            </li>
            </ul>
            <AccordionItem bg="transparent" border={'none'}>
              <AccordionButton onClick={()=>navigate("/help")}>
                <Box as='span' flex='1' textAlign='left' fontSize={'0.875rem'} lineHeight={'1'} verticalAlign={'middle'} color={'#3e4b5b'}>
                  Help
                </Box>
                {/* <AccordionIcon color={'rgba(187, 168, 191, 0.9607843137)'} mr={'8px'} /> */}
                <i class="mdi mdi-help-circle-outline menu-icon" title="Help" 
                  style={{
                    color: 'rgba(187, 168, 191, 0.9607843137)',
                    fontSize: '1.125rem'
                  }}
                ></i>
              </AccordionButton>
              <AccordionPanel pb={4}>
                {/* <HelpNavigation /> */}
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
          </li>
          <li >

          <div className={'poweredby'}>
            <p>Powered by </p>
             <img src={Logo} alt={''} />
          </div>
          </li>
        </ul>
      </nav>
      
    </>
  )
}

export default SideBar
