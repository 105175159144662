import React, { useEffect, useState } from 'react';
import API from '../../../../../../Services/API';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';

const Pillars = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [pillars, setPillars] = useState([]);
    const [actions, setActions] = useState([]);
    const [metrics, setMetrics] = useState([]);

    // Fetch Pillars
    useEffect(() => {
        API.get(`/sio-pillar`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => setPillars(response.data))
        .catch(error => console.error("Error fetching pillars:", error));
    }, []);

    // Toggle pillar selection
    const toggleSelection = (id) => {
        setPillars((prev) =>
            prev.map(pillar =>
                pillar.id === id ? { ...pillar, selected: !pillar.selected } : pillar
            )
        );
    };

    // Fetch actions when pillars change
    useEffect(() => {
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        props.getPillarList(selectedPillars);

        if (selectedPillars.length === 0) {
            setActions([]);
            setMetrics([]);
            return;
        }

        API.get(`/multi-action-pillar?pillar_id=${JSON.stringify(selectedPillars)}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => setActions(response.data))
        .catch(error => console.error("Error fetching actions:", error));

        API.get(`/get-pillar-action-metric?pillar_id=${JSON.stringify(selectedPillars)}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => setMetrics(response.data))
          .catch(error => console.error("Error fetching metrics:", error));
          
    }, [pillars]);

    // Toggle action selection
    const toggleActionSelection = (id) => {
        setActions((prev) =>
            prev.map(action =>
                action.id === id ? { ...action, selected: !action.selected } : action
            )
        );
    };

    // Fetch metrics when actions change
    useEffect(() => {
        const selectedActions = actions.filter(a => a.selected).map(a => a.id);
        props.getActionsList(selectedActions);

        if (selectedActions.length === 0) {
            setMetrics([]);
            return;
        }

        API.get(`/get-pillar-action-metric?action_id=${JSON.stringify(selectedActions)}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => setMetrics(response.data))
          .catch(error => console.error("Error fetching metrics:", error));
    }, [actions]);

    // Toggle metric selection
    const toggleMetricSelection = (id) => {
        setMetrics((prev) =>
            prev.map(metric =>
                metric.id === id ? { ...metric, selected: !metric.selected } : metric
            )
        );
    };

    useEffect(() => {
        const selectedMetrics = metrics.filter(m => m.selected).map(m => m.id);
        props.getMetricsList(selectedMetrics);
    }, [metrics]);

    return (
        <>
            {/* Pillars Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Pillars</FormLabel>
                {/* <CreateModule type={'ManageOrganizations'} /> */}
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px">
                    {pillars.map((pillar) => (
                        <div key={pillar.id} onClick={() => toggleSelection(pillar.id)} 
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {pillar.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{pillar.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Actions Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Action Teams</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px">
                    {actions.map((action) => (
                        <div key={action.id} onClick={() => toggleActionSelection(action.id)} 
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {action.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{action.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Metrics Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Metrics</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" padding="8px">
                    {metrics.map((metric) => (
                        <div key={metric.id} onClick={() => toggleMetricSelection(metric.id)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {metric.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{metric.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>
        </>
    );
};

export default Pillars;
