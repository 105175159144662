import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Styles from '../../ManageThemes/ManageThemes.module.css';
import StylesCustom from '../../ManageThemes/CreateTheme/createtheme.module.css';
import { Box, Button, Text, Input, FormControl, FormLabel, useToast, Tooltip, IconButton, } from '@chakra-ui/react';
import API from '../../../../../Services/API'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget'
import { EditIcon } from '@chakra-ui/icons';
import CreateModule from '../../../../../Components/Shared/Panel/SideBar/CreateModule/CreateModule';

const CreatePillar = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    return (
        <>
            <CreateModule componentType='organization' getUpdate={props.getUpdate} />

            {/* {
                props.componentValue === "create" ? (
                    <Box float={'right'} className={Styles.headingParentRight}>
                        <Button
                            mb={'5px'}
                            backgroundColor={'#2c2c2c'}
                            color={'#FFFFFF'}
                            onClick={() => setIsPaneOpen(true)}
                            _hover={{ backgroundColor: '#2c2c2c', color: '#FFFFF' }}
                        >
                            Create Pillar +
                        </Button>
                    </Box>
                ): props.componentValue === "edit" ? (
                    <Tooltip id={props.id} label="Edit" aria-label="Edit">
                        <IconButton
                            variant="solid"
                            aria-label="Edit"
                            fontSize="15px"
                            icon={<EditIcon />}
                            className={StylesCustom.edit}
                            id={props.id}
                            onClick={() => setIsPaneOpen(true)}
                        />
                    </Tooltip>
                ) : null
            } */}
        </>
    )
}

export default CreatePillar