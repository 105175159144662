import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Box,
    Text,
    VStack,
    List,
    ListItem,
    HStack,
    Button,
    useToast,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../../Services/API';
import LoaderSpinner from '../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'

const Search = () => {
    const token = window.localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const toast = useToast();

    const [query, setQuery] = useState('');
    const [results, setResults] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const [groupedOptions, setGroupedOptions] = useState({});
    const searchRef = useRef(null);

    const [isloading, setIsLoading] = useState(false)

    // Fetch data function
    const fetchData = async () => {
        try {
            const [metricsRes, pillarsRes, actionsRes, blogsRes, articlesRes, tagsRes] = await Promise.all([
                API.get('/all-metric-dashboard', { headers: { Authorization: `Bearer ${token}` } }),
                API.get('/sio-pillar', { headers: { Authorization: `Bearer ${token}` } }),
                API.get('/get-pillar-action', { headers: { Authorization: `Bearer ${token}` } }),
                API.get('/blog', { headers: { Authorization: `Bearer ${token}` } }),
                API.get('/about-article', { headers: { Authorization: `Bearer ${token}` } }),
                API.get('/tag-detail', { headers: { Authorization: `Bearer ${token}` } }),
            ]);
            setGroupedOptions({
                Metrics: metricsRes.data,
                'SIIO Organization': pillarsRes.data,
                Actions: actionsRes.data,
                'Impact Gallery': blogsRes.data,
                Articles: articlesRes.data.results,
                Tags: tagsRes.data.results,
            });
        } catch (error) {

        }
    };

    // Handle search icon click
    const handleSearchIconClick = async () => {
        setIsLoading(true)
        setIsExpanded(true);
        await fetchData();
        setIsLoading(false)
    };

    // Debounced search function
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const performSearch = useCallback(
        debounce((searchQuery) => {
            const filteredResults = {};
            Object.keys(groupedOptions).forEach((group) => {
                const items = groupedOptions[group]?.filter((item) => {
                    const itemName =
                        group === 'Impact Gallery' ? item.title
                        : group === 'Articles' ? item.article_name || ''
                        : item.name || '';
                    return itemName.toLowerCase().includes(searchQuery.toLowerCase());
                });
                if (items.length > 0) {
                    filteredResults[group] = items;
                }
            });
            setResults(filteredResults);
        }, 300),
        [groupedOptions]
    );

    const handleInputChange = (e) => {
        const searchQuery = e.target.value;
        setQuery(searchQuery);
        if (searchQuery.length > 0) {
            performSearch(searchQuery);
        } else {
            setResults({});
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && query.trim()) {
            navigate(`/searchresults?q=${encodeURIComponent(query)}`);
        }
    };

    const clearSearch = () => {
        setQuery('');
        setResults({});
        setIsExpanded(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                clearSearch();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getItemLink = (group, item) => {
        switch (group) {
            case 'Actions':
                return `/action/${item.module}/${encodeURIComponent(item.name)}`;
            case 'Impact Gallery':
                return `/viewpost/${item.id}/${encodeURIComponent(item.title)}`;
            case 'SIIO Organization':
                return `/pillar/${item.module}/${encodeURIComponent(item.name)}`;
            case 'Articles':
                return `/article/${item.id}/${encodeURIComponent(item.article_name)}`;
            case 'Tags':
                return `/portfolio/tg/251/Tags/${item.id}/${encodeURIComponent(item.name)}`;
            default:
                return item.metrictype === 'impact'
                    ? `/dashboard/impactmetrics/${item.id}/${encodeURIComponent(item.name)}`
                    : `/metric/${item.id}/${encodeURIComponent(item.name)}`;
        }
    };

    return (
        <Box w="100%" maxW="600px" mx="auto" mt={0} position="relative" ref={searchRef}>
            {
                isloading ? <LoaderSpinner /> : null
            }
            {isExpanded ? (
                <InputGroup>
                    <Input
                        placeholder="Search items"
                        aria-label="Search items"
                        aria-expanded={isExpanded}
                        aria-controls="search-results-dropdown"
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        variant="filled"
                        focusBorderColor="teal.400"
                        autoFocus
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label="Clear search"
                            icon={<CloseIcon />}
                            size="sm"
                            onClick={clearSearch}
                            color="gray.500"
                            variant="ghost"
                        />
                    </InputRightElement>
                </InputGroup>
            ) : (
                <HStack spacing={2}>
                    <IconButton
                        icon={<SearchIcon />}
                        aria-label="Search"
                        onClick={handleSearchIconClick}
                        colorScheme="teal"
                    />
                </HStack>
            )}

            {Object.keys(results).length > 0 && (
                <VStack
                    align="start"
                    spacing={4}
                    bg="white"
                    p={4}
                    borderRadius="md"
                    boxShadow="md"
                    position="absolute"
                    top="100%"
                    w="100%"
                    zIndex="1"
                    id="search-results-dropdown" 
                >
                    <Box
                        height="300px"
                        overflowY="auto"
                        w="100%"
                        css={{
                            '&::-webkit-scrollbar': { width: '8px' },
                            '&::-webkit-scrollbar-thumb': { background: '#4FD1C5', borderRadius: '24px' },
                            '&::-webkit-scrollbar-track': { background: '#EDF2F7' },
                        }}
                    >
                        {Object.entries(results).map(([group, items]) => (
                            <Box key={group} w="100%">
                                <Text fontWeight="bold" fontSize="lg" color="teal.600">
                                    {group}
                                </Text>
                                <List spacing={2}>
                                    {items.slice(0, 5).map((item, index) => (
                                        <ListItem
                                            key={index}
                                            p={2}
                                            borderRadius="md"
                                            _hover={{ bg: 'teal.50', cursor: 'pointer' }}
                                            tabIndex={0}
                                            onKeyDown={(e) => e.key === "Enter" && navigate(getItemLink(group, item))} 
                                        >
                                            <Link to={getItemLink(group, item)}>
                                                {item.name || item.title || item.article_name}
                                            </Link>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        ))}
                    </Box>
                    <Button
                        size="sm"
                        variant="link"
                        colorScheme="teal"
                        onClick={() => navigate(`/searchresults?q=${encodeURIComponent(query)}`)}
                    >
                        Show all results
                    </Button>
                </VStack>
            )}
        </Box>
    );
};

export default Search;
