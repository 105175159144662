import React, { useState, useRef } from 'react';
import {
    Tooltip,
    IconButton,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import API from '../../../../../Services/API';
import StylesCustom from '../CreateOrganization/createOrganization.module.css';
import SpinnerLoader from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';


const Delete = ({ id, onDeleteSuccess, getUpdate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const cancelRef = useRef();
    const toast = useToast();
    const token = window.localStorage.getItem("accessToken");
    const [message, setMessage] = useState(null);

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);

    const deleteO = async () => {
        setIsLoading(true)
        try {
            const response = await API.delete('/organisations', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: { id: id }, // Send the ID in request body
            });
            getUpdate()
            setIsLoading(false)
            setMessage("Organization entry removed successfully.")
        } catch (error) {
            setIsLoading(false)
            setMessage("Failed to delete the organization")
        }
    };

    return (
        <>
            { isLoading && <SpinnerLoader /> }
            { message && <ErrorWidget message={message} /> }
            {/* Delete Button */}
            <Tooltip label="Delete" aria-label="Delete">
                <IconButton
                    variant="solid"
                    aria-label="Delete"
                    fontSize="15px"
                    icon={<DeleteIcon />}
                    className={`${StylesCustom.edit} ${StylesCustom.leftMinus10}`}
                    onClick={onOpen} // Open modal on click
                />
            </Tooltip>

            {/* Confirmation Modal */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Deletion
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this organization? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter backgroundColor={'transparent'}>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={deleteO} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default Delete;
