import React, { useState, useEffect, useContext } from 'react';
import Styles from '../Organization/organization.module.css'
import { Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Button, Image, Stack, CardFooter } from '@chakra-ui/react'
import Region from './Region/Region';
import Country from './Country/Country';
import State from './State/State';
import City from './City/City';

const Geography = () => {
  const [activeTab, setActiveTab] = useState('region');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'region':
        return <Region />;
      case 'country':
        return <Country />;
      case 'state':
        return <State />;
      case 'city':
        return <City />;
      default:
        return null;
    }
  };

  return (
    <>
      <Card mt={'0px'}>
        <CardHeader pb={'0px'}>
          <Heading size='md' fontSize={"18px"} fontWeight={"500"} mb={'10px'}>Geography</Heading>
        </CardHeader>
        <CardBody pt={'0px'}>
          <Card direction={{ base: 'column', sm: 'row' }} overflow='hidden' variant='outline'>
            <Box className={Styles.tabGroup} maxW={{ base: '100%', sm: '200px' }}>
              <Button onClick={() => setActiveTab('region')} className={activeTab === 'region' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Region</Button>
              <Button onClick={() => setActiveTab('country')} className={activeTab === 'country' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Country</Button>
              <Button onClick={() => setActiveTab('state')} className={activeTab === 'state' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}> State</Button>
              <Button onClick={() => setActiveTab('city')} className={activeTab === 'city' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>City</Button>
            </Box>
            <Stack width={'100%'}>
              <CardBody p={'0px'}>
                <Box className={Styles.tabContent}>
                  <>
                    {renderTabContent()}
                  </>
                </Box>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
      </Card>
    </>
  )
}

export default Geography