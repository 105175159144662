import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box, Button, SimpleGrid, Card, CardBody, Text, Stack,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Input, useDisclosure,
  IconButton, FormControl, FormErrorMessage, Image, useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';
import EmptyImg from "../../../../../assets/images/No_image_available.svg.png";
import API from '../../../../Services/API';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Select from "react-select";
import JoditEditor from 'jodit-react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import $ from 'jquery'
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
function State() {
  const toast = useToast();
  const editor = useRef(null);
  const token = window.localStorage.getItem("accessToken");

  const [message, setMessage] = useState(null)
  const { edit, hasAllPermission } = useContext(EditContext);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [name, setName] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState(null);
  const [bannerPreview, setBannerPreview] = useState("");
  const [editingItem, setEditingItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [deletingId, setDeletingId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose
  } = useDisclosure();
  // ✅ Get All Countries and States
  const getAllData = async () => {
    try {
      setLoading(true);
      const countryResponse = await API.get('/country/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setCountries(countryResponse.data.map(country => ({
        value: country.id,
        label: country.country_name
      })));
      const stateResponse = await API.get('/state/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setStates(stateResponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const customSelectStyles = (errors) => ({
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px', // Match Chakra input border radius
      padding: '2px 10px', // Match Chakra input padding
      borderColor: errors
        ? 'red'
        : state.isFocused
          ? '#3182CE' // Chakra focus color
          : '#E2E8F0', // Chakra border color
      borderWidth: '1px',
      backgroundColor: 'white',
      boxShadow: state.isFocused
        ? '0 0 0 1px #3182CE' // Focus ring like Chakra
        : errors
          ? '0 0 0 1px red'
          : 'none',
      '&:hover': {
        borderColor: errors ? 'red' : '#CBD5E0', // Chakra hover color
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10, // Ensure dropdown appears above other components
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', // Soft shadow like Chakra popover
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0', // Chakra placeholder color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1A202C', // Chakra text color
    }),
    input: (provided) => ({
      ...provided,
      color: '#1A202C', // Input text color
    }),
  });
  useEffect(() => {
    getAllData();
  }, []);

  // ✅ Form Validation
  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = "State name is required";
    if (!countryId) newErrors.countryId = "Country is required";
    if (!stateCode) newErrors.stateCode = "State code is required";
    if (stateCode && stateCode.length !== 2) newErrors.stateCode = "State code must be 2 characters";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // ✅ Create or Update State
  const saveItem = async () => {
    setMessage(null)
    if (!validate()) return;

    const formData = new FormData();
    formData.append("state_name", name);
    formData.append("state_cd", stateCode);
    formData.append("country", countryId);
    formData.append("visible", true);
    formData.append('description', $("#description").val())

    if (banner && banner?.type?.startsWith('image/')) {
      formData.append("banner", banner);
    }
    if (editingItem) {
      formData.append("id", editingItem.id);
    }

    try {
      if (editingItem) {
        await API.put('/state/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        setMessage("State updated successfully.")

      } else {
        await API.post('/state/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        setMessage("State created successfully.")
      }
      // resetForm();
      onClose();
      getAllData();
    } catch (error) {
      console.error(`Error ${editingItem ? "updating" : "creating"} state:`, error);
      if (error?.response?.data && error?.response?.data?.state_name != undefined) {
        setMessage(`${error?.response?.data?.state_name[0]}`)
      } else if (error?.response?.data && error?.response?.data?.state_cd != undefined) {
        setMessage(`${error?.response?.data?.state_cd[0]}`)
      } else {
        setMessage(`Failed to ${editingItem ? "update" : "create"} the state.`)
      }
      setLoading(false);
    }
  };

  // ✅ Handle Delete
  const handleDelete = async (id) => {
    setMessage(null)
    setIsDeleting(true);
    setLoading(true);
    try {
      await API.delete('/state/', {
        data: { id: deletingId },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setMessage("State deleted successfully.")

      getAllData();
      setIsDeleting(false);
    } catch (error) {
      setMessage("Failed to delete state.")
    }
    setLoading(false);
    setIsDeleting(false);
    onDeleteClose();
  };
  // ✅ Handle Editing
  const handleEdit = (item) => {
    setMessage(null)
    setName(item?.state_name);
    setStateCode(item?.state_cd);
    setCountryId(item?.country);
    setDescription(item?.description);
    setBanner(item?.banner);
    setBannerPreview(item?.banner);
    setErrors({});
    setEditingItem(item);
    onOpen();
  };

  // ✅ Reset Form
  const resetForm = () => {
    setMessage(null)
    setName("");
    setStateCode("");
    setCountryId("");
    setDescription("");
    setBanner(null);
    setBannerPreview("");
    setErrors({});
    setEditingItem(null);
  };

  return (
    <>
      {message && <ErrorWidget message={message} />}
      <Box position="relative" p={4}>
        <Button
          position="absolute"
          top={0}
          right={5}
          color="white"
          bg="blue.500"
          onClick={() => {
            resetForm();
            onOpen();
          }}
        >
          Add State
        </Button>
        {loading ? (
          <LoaderSpinner />
        ) : (
          <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5} mt={10}>
            {states.map((country) => (
              <Card key={`country-${country.id}`} size="sm">
                <CardBody>
                  <Stack direction="row" justify="space-between" align="flex-start">
                    <Box display={'flex'} flexDirection={"row"} gap={3}>
                      <Image src={country.banner || EmptyImg} boxSize="60px" objectFit="cover" />
                      <Box>
                        <Text fontSize="medium" color="gray.600" mb={1} >
                          {countries.find(region => region.value === country.country)?.label}
                        </Text>
                        <Text fontSize="small" fontWeight="">
                          {country.state_name}
                        </Text>
                      </Box>
                    </Box>
                    {(edit && hasAllPermission) && <Box gap={4} flexDirection={'column'} display={'flex'}>

                      <IconButton
                        variant="solid"
                        size={"small"}
                        aria-label="Edit"
                        fontSize="14px"
                        icon={<EditIcon />}
                        onClick={() => handleEdit(country)}
                      />
                      <IconButton
                        variant="solid"
                        size={"small"}
                        aria-label="Edit"
                        fontSize="14px"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          setDeletingId(country.id);
                          onDeleteOpen();
                        }}
                      />
                    </Box>}
                  </Stack>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        )}
        {/* Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={"medium"}>{editingItem ? "Edit State" : "Create State"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Country Dropdown */}
              <FormControl isInvalid={errors.countryId} mb={3}>
                <Select
                  placeholder="Select Country"
                  options={countries}
                  value={countries.find((c) => c.value === countryId)}
                  onChange={(option) => setCountryId(option.value)}
                  styles={customSelectStyles(errors.countryId)}
                />
                <FormErrorMessage>{errors.countryId}</FormErrorMessage>
              </FormControl>

              {/* State Name */}
              <FormControl isInvalid={errors.name} mb={3}>

                <Input
                  placeholder="State Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  mb={3}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>

              {/* State Code */}
              <FormControl isInvalid={errors.stateCode} mb={3}>
                <Input
                  placeholder="State Code"
                  value={stateCode}
                  onChange={(e) => setStateCode(e.target.value)}
                  maxLength={2}
                  mb={3}
                />
                <FormErrorMessage>{errors.stateCode}</FormErrorMessage>
              </FormControl>

              {/* Banner Upload */}
              <Input
                type="file"
                onChange={(e) => {
                  setBanner(e.target.files[0]);
                  setBannerPreview(URL.createObjectURL(e.target.files[0]));
                }}
                mb={3}
              />
              {bannerPreview && (
                <Image src={bannerPreview} boxSize="100px" objectFit="cover" mb={3} />
              )}

              {/* Description */}
              <JoditEditor
                id={"description"}
                ref={editor}
                config={{
                  readonly: false,
                  tabIndex: "0"
                }}
                value={description}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => { }}
              />
            </ModalBody>

            <ModalFooter background={"transparent"}>
              <Button onClick={onClose} mr={3}>Cancel</Button>
              <Button colorScheme="blue" onClick={saveItem}>
                {editingItem ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={isDeleteOpen}
          leastDestructiveRef={undefined}
          onClose={onDeleteClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Deletion
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete this state?
              </AlertDialogBody>
              <AlertDialogFooter background={"transparent"}>
                <Button onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </>
  );
}

export default State;
