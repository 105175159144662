import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box, Button, SimpleGrid, Card, CardBody, Text, Stack,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Input, useDisclosure,
  IconButton, Textarea,
  FormControl,
  FormErrorMessage,
  Image,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';
import EmptyImg from "../../../../../assets/images/No_image_available.svg.png";
import API from '../../../../Services/API';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Select from "react-select";
import JoditEditor from 'jodit-react';
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import $ from 'jquery'
function City() {

  const editor = useRef(null);
  const token = window.localStorage.getItem("accessToken");
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [regionId, setRegionId] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [bannerPreview, setBannerPreview] = useState("");
  const [message, setMessage] = useState(null)
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose
  } = useDisclosure();
  const { edit, hasAllPermission } = useContext(EditContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deletingId, setDeletingId] = useState(null);
  // ✅ Get All Regions and Countries
  const getAllData = useCallback(async () => {
    try {
      setLoading(true);

      // ✅ Get States
      const stateResponse = await API.get('/state/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setRegions(stateResponse.data.map(state => ({
        value: state.id,
        label: state.state_name
      })));

      // ✅ Get Cities
      const cityResponse = await API.get('/city/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setCountries(cityResponse.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  }, [token]); // ✅ Add `token` as a dependency


  useEffect(() => {
    getAllData();
  }, [getAllData]);

  // ✅ Filter Countries Based on Region Selection
  useEffect(() => {
    if (regionId) {
      const filtered = countries.filter(country => country.region === regionId);
      setFilteredCountries(filtered.map(country => ({
        value: country.id,
        label: country.state_name
      })));
    } else {
      setFilteredCountries([]);
    }
  }, [regionId, countries]);


  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = "City name is required";
    if (!regionId) newErrors.regionId = "State name is required";
    if (!latitude) newErrors.latitude = "City latitude is required";
    if (!longitude) newErrors.longitude = "City Longitude is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log("banner", message)
  // ✅ Create or Update Item
  const saveItem = async () => {
    setMessage(null)
    if (!validate()) return;
    const formData = new FormData();
    formData.append("city_name", name);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("state", regionId);
    formData.append("visible", true);
    formData.append('description', $("#description").val())
    if (banner && banner?.type?.startsWith('image/')) {
      formData.append("banner", banner);
    }

    try {
      if (editingItem) {
        if (editingItem) {
          formData.append("id", editingItem.id);
        }
        // Update
        await API.put(`/city/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        setMessage("City updated sucessfully.")
      } else {
        // Create
        await API.post('/city/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        setMessage("City added sucessfully.")
      }
      // resetForm();
      onClose();
      getAllData();
    } catch (error) {
      console.error(`Error ${editingItem ? "updating" : "creating"} item:`, error);
      if (error?.response?.data && error?.response?.data?.city_name != undefined) {
        setMessage(`${error?.response?.data?.city_name[0]}`)
      } else if (error?.response?.data && error?.response?.data?.city_name != undefined) {
        setMessage(`${error?.response?.data?.city_name[0]}`)
      } else {

        setMessage(`Falied to   ${editingItem ? "updating" : "adding"} city.`)
      }
      setLoading(false);
    }
  };

  // ✅ Handle Editing
  const handleEdit = (item) => {
    setMessage(null)
    setName(item?.city_name);
    setLatitude(item?.latitude);
    setlongitude(item?.longitude)
    setRegionId(item?.state);
    setDescription(item?.description || "");
    setBanner(item?.banner);
    setBannerPreview(item?.banner);
    setEditingItem(item);
    onOpen();
  };

  // ✅ Reset Form
  const resetForm = () => {
    setMessage(null)
    setLatitude("");
    setlongitude("");
    setBannerPreview("")
    setName("");
    setErrors({});
    setRegionId("");
    setDescription("");
    setBanner(null);
    setEditingItem(null);
  };

  // ✅ Handle Delete
  const handleDelete = async (id) => {
    setMessage(null)
    setIsDeleting(true);
    setLoading(true);
    try {
      await API.delete('/city/', {
        data: { id: deletingId },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setMessage("City deleted successfully.")
      getAllData();
      setIsDeleting(false);
      onDeleteClose();
    } catch (error) {
      setMessage("Failed to delete city.")
    }
    setLoading(false);
    setIsDeleting(false);
  };


  const customSelectStyles = (errors) => ({
    control: (provided, state) => ({
      ...provided,
      borderRadius: '5px', // Match Chakra input border radius
      padding: '2px 10px', // Match Chakra input padding
      borderColor: errors
        ? 'red'
        : state.isFocused
          ? '#3182CE' // Chakra focus color
          : '#E2E8F0', // Chakra border color
      borderWidth: '1px',
      backgroundColor: 'white',
      boxShadow: state.isFocused
        ? '0 0 0 1px #3182CE' // Focus ring like Chakra
        : errors
          ? '0 0 0 1px red'
          : 'none',
      '&:hover': {
        borderColor: errors ? 'red' : '#CBD5E0', // Chakra hover color
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10, // Ensure dropdown appears above other components
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', // Soft shadow like Chakra popover
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0', // Chakra placeholder color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1A202C', // Chakra text color
    }),
    input: (provided) => ({
      ...provided,
      color: '#1A202C', // Input text color
    }),
  });

  return (
    <>
      {message && <ErrorWidget message={message} />}
      <Box position="relative" p={4}>
        <Button
          position="absolute"
          top={0}
          right={5}
          color="white"
          bg="blue.500"
          onClick={() => {
            resetForm();
            onOpen();
          }}
        >
          Add City
        </Button>
        {loading ? (
          <LoaderSpinner />
        ) : (

          <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5} mt={10}>
            {countries.map((country) => (
              <Card key={`country-${country.id}`} size="sm">
                <CardBody>
                  <Stack direction="row" justify="space-between" align="flex-start">
                    <Box display={'flex'} flexDirection={"row"} gap={3}>
                      <Image src={country.banner || EmptyImg} boxSize="60px" objectFit="cover" />
                      <Box>
                        <Text fontSize="medium" color="gray.600" mb={1} >
                          {console.log("ddddddd", regions.find(region => region.value), country)}

                          {regions.find(region => region.value === country.state)?.label}
                        </Text>
                        <Text fontSize="small" fontWeight="">
                          {country.city_name}
                        </Text>
                      </Box>
                    </Box>
                    {(edit && hasAllPermission) && <Box gap={4} flexDirection={'column'} display={'flex'}>

                      <IconButton
                        variant="solid"
                        aria-label="Edit"
                        size={"small"}
                        fontSize="14px"
                        icon={<EditIcon />}
                        onClick={() => handleEdit(country)}
                      />
                      <IconButton
                        variant="solid"
                        aria-label="Edit"
                        size={"small"}
                        fontSize="14px"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          setDeletingId(country.id);
                          onDeleteOpen();
                        }}
                      />
                    </Box>}
                  </Stack>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>)}

        {/* Create/Edit Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={"medium"}>
              {editingItem ? `Edit City` : "Create City"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Select Region */}
              <FormControl mb={3} isInvalid={errors.regionId}>
                <Select
                  placeholder="Select state"
                  options={regions}
                  value={regions.find(region => region.value === regionId)}
                  onChange={(e) => setRegionId(e.value)}
                  mb={3}
                  styles={customSelectStyles(errors.regionId)}
                />
                <FormErrorMessage>{errors.regionId}</FormErrorMessage>
              </FormControl>

              {/* Country Name */}
              <FormControl mb={3} isInvalid={errors.name}>
                <Input
                  placeholder="City Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  mb={3}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>

              </FormControl>

              {/* Country Name */}
              <FormControl mb={3} isInvalid={errors.latitude}>

                <Input
                  placeholder="City latitude"
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                  mb={3}
                />
                <FormErrorMessage>{errors.latitude}</FormErrorMessage>
              </FormControl>
              {/* Country Name */}
              <FormControl mb={3} isInvalid={errors.longitude}>

                <Input
                  placeholder="City longitude"
                  value={longitude}
                  onChange={(e) => setlongitude(e.target.value)}
                  mb={3}
                />
                <FormErrorMessage>{errors.longitude}</FormErrorMessage>
              </FormControl>

              {/* Banner Upload */}
              <Input
                type="file"
                onChange={(e) => {
                  setBanner(e.target.files[0])
                  setBannerPreview(URL.createObjectURL(e.target.files[0]));
                }}
                mb={3}
              />
              {bannerPreview && (
                <Image src={bannerPreview} boxSize="100px" objectFit="cover" mb={3} />
              )}

              {/* Description */}
              <JoditEditor
                id={"description"}
                ref={editor}
                config={{
                  readonly: false,
                  tabIndex: "0"
                }}
                value={description}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => { }}
              />
            </ModalBody>
            <ModalFooter background={"transparent"}>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={saveItem}>
                {editingItem ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={isDeleteOpen}
          leastDestructiveRef={undefined}
          onClose={onDeleteClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Deletion
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete this city?
              </AlertDialogBody>
              <AlertDialogFooter background={"transparent"}>
                <Button onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </>
  );
}

export default City;
