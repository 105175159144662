import React, { useState, useEffect, useContext } from 'react';
import Styles from './ManageOrganizations.module.css'
import API from '../../../../Services/API';
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Button, Image, HStack} from '@chakra-ui/react'
import emptyIcon from '../../../../../assets/images/emptyIcon.jpg'
import CreateOrganization from './CreateOrganization/CreateOrganization';
import Delete from './Delete/Delete';
import AddTheme from './AddTheme/AddTheme';
import EditTheme from './EditTheme/EditTheme';
import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton,} from '@chakra-ui/react'
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import DeleteMetric from './DeleteMetric/DeleteMetric';

const ManageOrganizations = () => {
    const token = window.localStorage.getItem("accessToken")
    const [message, setMessage] = useState(null);
    const [organizations, setOrganizations] = useState([])
    const [organizationsLength, setOrganizationsLength] = useState(1)
    const [organizationsData, setOrganizationsData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        getUpdate()
    },[])

    const getUpdate = () =>{
        API.get('/organisations',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(response=>{
            API.get('/get-organisations-data',{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then(organizationsDataResponse=>{
                setOrganizationsData(organizationsDataResponse.data)
                console.log("metrics,,,P", organizationsDataResponse.data)
            }).catch(error=>{
            
            })
            console.log(response.data)
            setOrganizations(response.data)
            setOrganizationsLength(response.data.length)

        }).catch(error=>{

        })
    }

    const removeMetric = async(id) =>{
        setIsLoading(true)
        try {
            const response = await API.delete('/organisation-theme-metric', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: { id: id }, // Send the ID in request body
            });
            setIsLoading(false)
            getUpdate()
            setMessage("Successfully removed metric from the theme")
        }catch (error) {
            setIsLoading(false)
            setMessage("Failed to delete the organization")
        }
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Box width={'100%'} borderBottom={'1px solid #dadada'} height={'45px'} position={'relative'}>
                <Box float={'left'} className={Styles.headingParent}>
                    <Heading as={'h3'} className={Styles.heading}> Organizations </Heading>
                </Box>
                <CreateOrganization getUpdate={getUpdate} componentValue={"create"} />
                <Box className='clearfix'></Box>
            </Box>
            <Box mt={'5px'}>
                {
                    organizationsLength === 0 && <Box className={Styles.emptyCOntainer}>
                        <Image src={emptyIcon} m={'0 auto'} maxW={'50px'} />
                        <Text textAlign={'center'} fontSize={'12px'} mb={'0px'}> No Organizations currently </Text>
                        <Text textAlign={'center'} fontSize={'12px'}> <a href='#n' style={{color:'#1890ff'}} > Click here</a> to create a organization </Text>
                    </Box>
                }
                {
                    organizationsData.map(organization =>
                        <Box className={Styles.entryContainer}>
                            <Heading as={'h3'}>
                                {organization.name}
                                <CreateOrganization id={organization.organisation_id} getUpdate={getUpdate} componentValue={"edit"} />
                                <Delete id={organization.organisation_id} getUpdate={getUpdate} />
                                <AddTheme id={organization.organisation_id} getUpdate={getUpdate} name={organization.name} />
                            </Heading>
                            <Box className={Styles.entryInnerContainer}>
                                {
                                    organization.themes_list.length < 1 ? <Box className={Styles.emptyCOntainer}>
                                        <Image src={emptyIcon} m={'0 auto'} maxW={'50px'} />
                                        <Text textAlign={'center'} fontSize={'12px'} mb={'0px'}> No themes added currently </Text>
                                        <Text textAlign={'center'} fontSize={'12px'}> Please click theme icon to assign theme to organization </Text>
                                    </Box> : null
                                }
                                {
                                    organization.themes_list.map(theme => (
                                        <Box ml={'10px'} mr={'10px'} mb={'5px'} key={theme.organisationtheme_id}>
                                            <Heading as={'h3'}>
                                                {theme.name}
                                                <EditTheme componentValue={theme.name} id={theme.organisationtheme_id} getUpdate={getUpdate} />
                                            </Heading>

                                            <Box className={Styles.entryInnerContainer}>
                                                {/* Proper Empty State Check */}
                                                {theme.pillars.every(pillar => pillar.metrics.length === 0 && pillar.actions.every(action => action.metrics.length === 0)) && (
                                                    <Box className={Styles.emptyCOntainer}>
                                                        <Image src={emptyIcon} m={'0 auto'} maxW={'50px'} />
                                                        <Text textAlign={'center'} fontSize={'12px'} mb={'0px'}> No Metrics added currently </Text>
                                                        <Text textAlign={'center'} fontSize={'12px'}> Please click edit icon to assign theme to organization </Text>
                                                    </Box>
                                                )}

                                                {theme.pillars.map(pillar => (
                                                    <React.Fragment key={pillar.id}>
                                                        {/* Metrics Under Pillars */}
                                                        {pillar.metrics.length > 0 && (
                                                            <>
                                                                <Box m={'5px'} display="flex" alignItems="center" fontSize="sm" fontWeight="bold">
                                                                    <Text fontWeight="bold">{pillar.name}</Text>
                                                                </Box>
                                                                <HStack spacing={4} m={'10px'}>
                                                                    {pillar.metrics.map(metric => (
                                                                        <Tag key={metric.organisation_metric_id} variant="solid" colorScheme="gray">
                                                                            <TagLabel>{metric.metric}</TagLabel>
                                                                            <DeleteMetric id={metric.organisation_metric_id} getUpdate={getUpdate} />
                                                                        </Tag>
                                                                    ))}
                                                                </HStack>
                                                            </>
                                                        )}

                                                        {/* Actions Under Pillars */}
                                                        {pillar.actions.map(action => (
                                                            <React.Fragment key={action.id}>
                                                                {action.metrics.length > 0 && (
                                                                    <>
                                                                        <Box m={'5px'} display="flex" alignItems="center" fontSize="sm" fontWeight="bold">
                                                                            <Text fontWeight="bold">{pillar.name}</Text>
                                                                            <Text mx={2}>|</Text>
                                                                            <Text fontWeight="normal">{action.name}</Text>
                                                                        </Box>
                                                                        <HStack spacing={4} m={'10px'}>
                                                                            {action.metrics.map(metric => (
                                                                                <Tag key={metric.organisation_metric_id} variant="solid" colorScheme="gray">
                                                                                    <TagLabel>{metric.metric}</TagLabel>
                                                                                    <DeleteMetric id={metric.organisation_metric_id} getUpdate={getUpdate} />
                                                                                </Tag>
                                                                            ))}
                                                                        </HStack>
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    )
                }
            </Box>
        </>
    )
}

export default ManageOrganizations