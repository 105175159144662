import React, { useState, useEffect, useRef } from 'react';
import Styles from '../metricdata.module.css';
import API from '../../../../Services/API';
import Select from 'react-select';
import { 
    useDisclosure, 
    Button, 
    Tooltip, 
    IconButton, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    ModalCloseButton, 
    FormControl, 
    FormLabel, 
    Input, 
    SimpleGrid 
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const EditMetricData = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState({})
    const [defaultValues, setDefaultValues] = useState({});
    const [selectedMetric, setSelectedMetric] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedPeriodName, setSelectedPeriodName] = useState('');
    const [selectedFyq, setSelectedFyq] = useState('');
    const [selectedFyqName, setSelectedFyqName] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(props?.status);
    const [selectedApproval, setSelectedApproval] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [qtrListUp, setQtrListUp] = useState([]);
    const [strategy, setStrategy] = useState('');
    const [strategyName, setStrategyName] = useState('');
    const [message, setMessage] = useState(null); 
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setcities] = useState([]);
    const regionCode = props.region
    const cityCode = props.city
    const countryCode = props.country
    const stateCode = props.state
    const [selectedRegion, setSelectedRegion] = useState(regionCode);
    const [selectedState, setSelectedState] = useState(stateCode);
    const [selectedCity, setSelectedCity] = useState(cityCode);
    const [selectedCountry, setSelectedCountry] = useState(countryCode);
       

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = [
                    defaultValues?.region || regionCode ? API.get(`/get-geographical-data?region=${defaultValues?.region || regionCode}`, { headers: { Authorization: `Bearer ${token}` } }) : null,
                    defaultValues?.country || countryCode ? API.get(`/get-geographical-data?country=${defaultValues?.country || countryCode}`, { headers: { Authorization: `Bearer ${token}` } }) : null,
                    defaultValues?.state || stateCode ? API.get(`/get-geographical-data?state=${defaultValues?.state || stateCode}`, { headers: { Authorization: `Bearer ${token}` } }) : null
                ].filter(Boolean); // Remove null values
    
                const [countries, states, cities] = await Promise.all(requests);
                
                if (countries) setCountries(countries.data);
                if (states) setStates(states.data);
                if (cities) setcities(cities.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [token, isOpen]);
    
    const fiscalYearDetail = props.fiscalYearDetail?.map(item => ({
        value: item.id,
        label: item.name
    })).sort((a, b) => parseInt(b.label.replace('FY', '')) - parseInt(a.label.replace('FY', '')));

    const fiscalYearQtrDetail = props.fiscalYearQtrDetail?.map(item => ({
        value: item.id,
        label: item.name
    }));
    const regionsList = props?.regions && props?.regions?.map(region => ({
        value: region?.id,
        label: region?.region_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));
    const countriesList = countries && countries?.map(region => ({
        value: region?.id,
        label: region?.country_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));
    const statesList = states && states?.map(region => ({
        value: region?.id,
        label: region?.state_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));
    const citiesList = cities && cities?.map(region => ({
        value: region?.id,
        label: region?.city_name
    })).sort((a, b) => a?.label?.localeCompare(b?.label));

      const strategiesList = props.strategies?.map(item => ({
        value: item.id,
        label: item.name
    }));

    const updateQuarterList = (periodName) => {
        const selectedOptionNew = `FY${periodName.slice(-2)}`;
        const relatedQuarters = fiscalYearQtrDetail?.filter(item => item.label.includes(selectedOptionNew))
            .sort((a, b) => {
                const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
                const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
                return quarterB - quarterA;
            });
        setQtrListUp(relatedQuarters || []);
    };

    useEffect(() => {
        setDefaultValues({
            periodName: props.periodName,
            metricName: props.metricName,
            period: props.period,
            fyq: props.fyq,
            status: props.status,
            approvalStatus: props.approvalStatus,
            geography: props.geography,
            geographyName: props.geographyName,
            strategy: props.strategy,
            strategyName: props.strategyName
        });

        setSelectedMetric(props.metricName);
        setSelectedPeriod(props.period);
        setSelectedPeriodName(props.periodName);
        setSelectedFyq(props.fyq);
        setSelectedFyqName(props.fyqName);
        setSelectedStatus(props.status);
        setSelectedApproval(props.approvalStatus);
        setSelectedCountry(props.country);
        setSelectedCountryName(props.geographyName);
        setStrategy(props.strategy);
        setStrategyName(props.strategyName);
    }, [props]);

    const handleOpen = () => {
        setSelectedPeriodName(defaultValues.periodName);
        setSelectedFyqName(defaultValues.fyqName);
        setSelectedMetric(defaultValues.metricName);
        setSelectedPeriod(defaultValues.period);
        setSelectedFyq(defaultValues.fyq);
        setSelectedStatus(defaultValues.status);
        setSelectedApproval(defaultValues.approvalStatus);
        // setSelectedCountry(defaultValues.country);
        setSelectedCountryName(defaultValues.geographyName);
        setStrategy(defaultValues.strategy);
        setStrategyName(defaultValues.strategyName);

        updateQuarterList(defaultValues.periodName);

         API.get(`/get-specific-modules?metric_id=${props?.metricID}`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                })
                    .then((response) => {
                        setUser(response.data); // Set the response data into state
                    })
                    .catch(error => {
                        setIsLoading(false); // Handle error, maybe set a message
                    });

        onOpen();
    };

    const handleSave = async () => {
        setMessage(null);
        const updatedValue = document.getElementById("updateValue")?.value || props.value;

        const hasChanges = 
            parseInt(updatedValue) !== parseInt(props.value) ||
            selectedPeriod !== props.period ||
            selectedFyq !== props.fyq ||
            selectedStatus !== props.status ||
            selectedCountry !== props.country ||
            selectedState !== props.state ||
            selectedCity !== props.city ||
            selectedRegion !== props.region ||
            strategy !== props.strategy;

        if (!hasChanges) {
            setMessage("No changes are made");
            return;
        }

        try {
            setIsLoading(true);

            const body = {
                id: parseInt(props.id),
                name: selectedMetric,
                value: parseInt(updatedValue),
                ...(selectedPeriod && { fiscal_year: parseInt(selectedPeriod), fiscal_year_name: selectedPeriodName }),
                ...(selectedFyq && { fiscal_quarter: parseInt(selectedFyq), fiscal_quarter_name: selectedFyqName }),
                ...(selectedStatus && { metric_status: selectedStatus }),
                ...(strategy && { strategy }),
                ...(strategyName && { strategy_name: strategyName }),
                ...(selectedCountry && { geography:selectedCountry }),
                ...(selectedCountryName && { geography_name: selectedCountryName }),
                ...(selectedRegion && { region: selectedRegion }),
                ...(selectedCountry && { country: selectedCountry }),
                ...(selectedState && { state: selectedState }),
                ...(selectedCity && { city: selectedCity }),
                approval_status: user?.superuser || user?.action_office_user || user?.position === 'pillar_owner' || user?.position === 'action_owner' ? "approved" : "pending",
                position: user.superuser ? 'superuser'
                    : user.action_office_user ? 'action_office_user'
                        : user.comms_user ? 'comms_user'
                            : user.elt_user ? 'elt_user'
                                : user.position === 'pillar_owner' ? 'pillar_owner'
                                    : user.position === 'pillar_contributor' ? 'pillar_contributor'
                                        : user.position === 'pillar_member' ? 'pillar_member'
                                            : user.position === 'action_owner' ? 'action_owner'
                                                : user.position === 'action_contributor' ? 'action_contributor'
                                                    : user.position === 'action_member' ? 'action_member' : '',
                position_of_user: (user.position === 'pillar_owner' || user.position === 'action_owner') ? 'owner'
                    : (user.position === 'action_contributor' || user.position === 'pillar_contributor') ? 'contributor' : ''
           
            };

            await API.put(`/metric-data`, body, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage("Metric updated successfully");
            const response = await API.get(`/my-task`, { headers: { Authorization: `Bearer ${token}` } });
            const sortedMetrics = response?.data?.results.sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at));
            props.getUpdatedPending(sortedMetrics);
            props.getMessage(true);
            onClose();
        } catch (error) {
            console.error("Error updating metric:", error);
            setMessage(error.response?.data?.message || "An error occurred during the update");
        } finally {
            setIsLoading(false);
        }
    };

    const changePeriod = (event) => {
        setSelectedPeriod(event.value);
        setSelectedPeriodName(event.label);
        setSelectedFyq(null);
        updateQuarterList(event.label);
    };

    const changeFyq = (event) => {
        setSelectedFyq(event.value);
        setSelectedFyqName(event.label);
    };

    const changeStatus = (event) => {
        setSelectedStatus(event.value);
    };

    const countryRef = useRef(null)
    const stateRef = useRef(null)
    const cityRef = useRef(null)
  const changeCountry = (event) => {
          if (event === null || event === undefined) {
              if (stateRef.current) stateRef.current.clearValue();
              if (cityRef.current) cityRef.current.clearValue();
              setStates([])
              setcities([])
              setSelectedCountry(null)
              setSelectedState(null)
              setSelectedCity(null)
              return
          }
          if (event?.value) {
              if (stateRef.current) stateRef.current.clearValue();
              if (cityRef.current) cityRef.current.clearValue();
              setStates([])
              setcities([])
              setSelectedState(null)
              setSelectedCity(null)
              setIsLoading(true)
              setSelectedCountry(event?.value);
              API.get(`/get-geographical-data?country=${event?.value}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                  setStates(res.data)
                  setIsLoading(false)
              })
          }
      };
      const changeRegion = (event) => {
          if (event === null || event === undefined) {
              if (countryRef.current) countryRef.current.clearValue();
              if (stateRef.current) stateRef.current.clearValue();
              if (cityRef.current) cityRef.current.clearValue();
              setCountries([])
              setStates([])
              setcities([])
              setSelectedRegion(null)
              setSelectedCountry(null)
              setSelectedState(null)
              setSelectedCity(null)
              return
          }
          if (event?.value) {
              if (countryRef.current) countryRef.current.clearValue();
              if (stateRef.current) stateRef.current.clearValue();
              if (cityRef.current) cityRef.current.clearValue();
              setCountries([])
              setStates([])
              setcities([])
              setSelectedCountry(null)
              setSelectedState(null)
              setSelectedCity(null)
            
              console.log("event", event)
              setIsLoading(true)
              setSelectedRegion(event?.value);
              API.get(`/get-geographical-data?region=${event?.value}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
              setCountries(res.data)
              setIsLoading(false)
          })
      }
      };
      const changeState = (event) => {
          if (event === null || event === undefined) {
              if (cityRef.current) cityRef.current.clearValue();
              setSelectedState(null)
              setSelectedCity(null)
              setcities([])
              return
          }
          if (event?.value) {
              if (cityRef.current) cityRef.current.clearValue();
              setSelectedCity(null)
              setcities([])
              setIsLoading(true)
              setSelectedState(event?.value);
              API.get(`/get-geographical-data?state=${event?.value}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                  setcities(res.data)
                  setIsLoading(false)
              })
          };
      }
      const changeCity = (event) => {
          if (event === null || event === undefined) {
              setSelectedCity(null);
              return;
          }
          setSelectedCity(event?.value);
      };

    const changeStrategy = (event) => {
        setStrategy(event?.value);
        setStrategyName(event?.label);
    };

    const statusOptions = [
        { value: 'on-track', label: 'On Track' },
        { value: 'delayed', label: 'Delayed' },
        { value: 'acceleration', label: 'Acceleration' }
    ];
console.log("states",selectedRegion,selectedCountry,selectedState,selectedCity,props)
    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Tooltip id={props.id} label="Edit metric data" aria-label="Edit metric data">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    fontSize="15px"
                    icon={<EditIcon />}
                    className={Styles.edit}
                    id={props.id}
                    onClick={handleOpen}
                />
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Edit Metric Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={[1, 2]} spacing={4}>
                            <FormControl id="metric-name">
                                <FormLabel fontWeight="bold">Metric Name</FormLabel>
                                <Input value={selectedMetric || ''} isDisabled aria-label="Metric Name" />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Value</FormLabel>
                                <Input id="updateValue" defaultValue={props.value} />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Period (FY)</FormLabel>
                                <Select
                                    options={fiscalYearDetail}
                                    value={fiscalYearDetail?.find(option => option.label === selectedPeriodName) || null}
                                    onChange={changePeriod}
                                    aria-label="Period Fiscal Year"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Period (FQ)</FormLabel>
                                <Select
                                    options={qtrListUp}
                                    value={qtrListUp?.find(option => option.value === selectedFyq) || null}
                                    onChange={changeFyq}
                                    aria-label="Period Fiscal Quarter"
                                />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Status</FormLabel>
                                <Select
                                    options={statusOptions}
                                    onChange={changeStatus}
                                    value={statusOptions?.find(option => option.value === selectedStatus) || null}
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Region</FormLabel>
                                <Select
                                    options={regionsList}
                                    onChange={changeRegion}
                                    placeholder="Select region.."
                                    defaultValue={
                                        regionsList && regionsList?.find(option => option.value === props.region)
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Country</FormLabel>
                                <Select
                                    ref={countryRef}
                                    options={countriesList}
                                    placeholder="Select Country.."
                                    onChange={changeCountry}
                                    defaultValue={
                                        countriesList && countriesList?.find(option => option.value === props.country)
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>State</FormLabel>
                                <Select
                                    ref={stateRef}
                                    options={statesList}
                                    placeholder="Select State.."

                                    onChange={changeState}
                                    defaultValue={
                                        statesList && statesList?.find(option => option.value === props.state )
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>City</FormLabel>
                                <Select
                                    ref={cityRef}
                                    placeholder="Select City.."
                                    options={citiesList}
                                    onChange={changeCity}
                                    defaultValue={
                                        citiesList&&   citiesList?.find(option => option.value === props.city )
                                    }
                                    isClearable
                                />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Strategy</FormLabel>
                                <Select
                                    options={strategiesList}
                                    onChange={changeStrategy}
                                    value={strategiesList?.find(option => option.label === strategyName) || null}
                                    isDisabled
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter backgroundColor="transparent">
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={handleSave} isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditMetricData;
