import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Box, Button, SimpleGrid, Card, CardBody, Text, Stack,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
    ModalBody, ModalFooter, Input, useDisclosure, IconButton, Image,
    useToast, Spinner, AlertDialog, AlertDialogBody, AlertDialogFooter,
    AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Switch,
    FormControl,
    FormErrorMessage
} from '@chakra-ui/react';
import API from '../../../../Services/API';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import EmptyImg from "../../../../../assets/images/No_image_available.svg.png";
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import JoditEditor from 'jodit-react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import $, { data } from 'jquery'
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
function Region() {
    const token = window.localStorage.getItem("accessToken");

    // ✅ State Management
    const [regions, setRegions] = useState([]);
    const [regionName, setRegionName] = useState("");
    const [regionCd, setRegionCd] = useState("");
    const [description, setDescription] = useState("");
    const [visible, setVisible] = useState(true);
    const [banner, setBanner] = useState(null);
    const [editingRegion, setEditingRegion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deletingId, setDeletingId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const [message, setMessage] = useState(null)
    const [bannerPreview, setBannerPreview] = useState("");
    const editor = useRef(null);

    const { edit, hasAllPermission } = useContext(EditContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure();
    const [errors, setErrors] = useState({});
    const toast = useToast();

    // ✅ Fetch All Regions
    const getAllRegions = async () => {
        setLoading(true);
        try {
            const response = await API.get('/region/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setRegions(response.data);
        } catch (error) {
            console.error("Error fetching regions:", error);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllRegions();
    }, []);

    // ✅ Create or Update Region
    const handleSave = async () => {
        setMessage(null)
        if (!validate()) return;
        const formData = new FormData();
        formData.append("region_name", regionName);
        formData.append("region_cd", regionCd);

        formData.append('description', $("#description").val())
        formData.append("visible", true);
        if (banner && banner?.type?.startsWith('image/')) {
            formData.append("banner", banner);
        }
        try {
            setLoading(true);

            if (editingRegion) {
                // Update Region
                formData.append("id", editingRegion.id);
                const response = await API.put(`/region/`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                console.log("message", response)
                if (response.status == 200) {

                    setMessage("Region has been updated successfully")
                }

            } else {
                // Create Region
                const response = await API.post(`/region/`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log("message", response)
                if (response.status == 201) {
                    setMessage("Region has been created successfully")
                }
            }

            // clearForm();
            getAllRegions();
            onClose();
            setLoading(false);
        } catch (error) {
            console.error("Error saving region:", error?.response?.data?.region_name);
            if (error?.response?.data && error?.response?.data?.region_name != undefined) {
                setMessage(`${error?.response?.data?.region_name[0]}`)
            } if (error?.response?.data && error?.response?.data?.region_cd != undefined) {
                setMessage(`${error?.response?.data?.region_cd[0]}`)
            } else {
                setMessage("Failed to create/update region.")
            }
            setLoading(false);

        }
    };

    // ✅ Delete Region with Confirmation
    const handleDelete = async () => {
        setMessage(null)
        setLoading(true)
        setIsDeleting(true);
        try {
            await API.delete(`/region/`, {
                data: { id: deletingId },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setMessage("Region has been deleted successfully")

            setIsDeleting(false);
            getAllRegions();
        } catch (error) {
            console.error("Error deleting region:", error);
            setMessage("Failed to delete region.")
            setLoading(false)
        }
        setLoading(false);
        setIsDeleting(false);
        onDeleteClose();
    };

    // ✅ Open Modal for Editing
    const handleEdit = (region) => {
        setMessage(null)
        setErrors({})
        setRegionName(region.region_name);
        setRegionCd(region.region_cd);
        setDescription(region.description);
        setVisible(region.visible);
        setEditingRegion(region);
        setBannerPreview(region?.banner)
        onOpen();
    };

    const clearForm = () => {
        setMessage(null)
        setRegionName("");
        setRegionCd("");
        setDescription("");
        setVisible(true);
        setBanner(null);
        setBannerPreview("")
        setEditingRegion(null);
        setErrors({});
    };

    const validate = () => {
        const newErrors = {};
        if (!regionName) newErrors.regionName = "Region name is required";
        if (!regionCd) newErrors.regionCd = "Region code is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    return (
        <>
            {message && <ErrorWidget message={message} />}
            <Box position="relative" p={4}>
                <Button
                    position="absolute"
                    top={0}
                    right={5}
                    color="white"
                    bg="blue.500"
                    onClick={() => {
                        clearForm();
                        onOpen();
                    }}
                >
                    Add Region
                </Button>

                {/* ✅ Loader */}
                {loading ? (
                    <LoaderSpinner />
                ) : (
                    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5} mt={10}>
                        {regions.map((region) => (
                            <Card key={region.id} size={'sm'}>
                                <CardBody>
                                    <Stack direction={"row"} justify="space-between" align="flex-start">
                                        <Box display={'flex'} flexDirection={"row"} gap={3}>
                                            <Image src={region.banner || EmptyImg} boxSize="60px" objectFit="cover" />
                                            <Box>
                                                <Text fontSize="medium" fontWeight="" color="gray.600">
                                                    {region.region_name}
                                                </Text>

                                            </Box>
                                        </Box>
                                        {(edit && hasAllPermission) && (
                                            <Box gap={4} flexDirection={'column'} display={'flex'}>
                                                <IconButton icon={<EditIcon />}
                                                    variant="solid"
                                                    aria-label="Edit"
                                                    size={"small"}
                                                    fontSize="14px" onClick={() => handleEdit(region)} />
                                                <IconButton icon={<DeleteIcon />}
                                                    variant="solid"
                                                    aria-label="Edit"
                                                    size={"small"}
                                                    fontSize="14px" onClick={() => {
                                                        setDeletingId(region.id);
                                                        onDeleteOpen();
                                                    }} />
                                            </Box>
                                        )}
                                    </Stack>
                                </CardBody>
                            </Card>
                        ))}
                    </SimpleGrid>
                )}

                {/* ✅ Modal */}
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontSize={"medium"}>{editingRegion ? "Edit Region" : "Create Region"}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl mb={3} isInvalid={errors.regionName}>
                                <Input value={regionName} onChange={(e) => setRegionName(e.target.value)} placeholder="Region Name" />
                                <FormErrorMessage>{errors.regionName}</FormErrorMessage>
                            </FormControl>
                            <FormControl mb={3} isInvalid={errors.regionCd}>
                                <Input value={regionCd} onChange={(e) => setRegionCd(e.target.value)} placeholder="Region Code" />
                                <FormErrorMessage>{errors.regionCd}</FormErrorMessage>
                            </FormControl>
                            <FormControl mb={3}>
                                <Input type="file" onChange={(e) => {
                                    setBanner(e.target.files[0])
                                    setBannerPreview(URL.createObjectURL(e.target.files[0]));
                                }} />
                                {bannerPreview && (
                                    <Image src={bannerPreview} boxSize="100px" objectFit="cover" mb={3} />
                                )}
                            </FormControl>
                            {/* Description */}
                            <JoditEditor
                                id={"description"}
                                ref={editor}
                                config={{
                                    readonly: false,
                                    tabIndex: "0",
                                }}
                                value={description}
                                tabIndex={1} // tabIndex of textarea
                                onChange={newContent => { }}
                            />
                        </ModalBody>
                        <ModalFooter background={"transparent"} gap={5}>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button colorScheme="blue" onClick={handleSave}>
                                {editingRegion ? "Update" : "Create"}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* ✅ Delete Confirmation Dialog */}
                <AlertDialog
                    isOpen={isDeleteOpen}
                    leastDestructiveRef={undefined}
                    onClose={onDeleteClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Confirm Deletion
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                Are you sure you want to delete this region?
                            </AlertDialogBody>
                            <AlertDialogFooter background={"transparent"}>
                                <Button onClick={onDeleteClose}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme="red"
                                    onClick={handleDelete}
                                    isLoading={isDeleting}
                                    ml={3}
                                >
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Box>
        </>
    );
}

export default Region;
